import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import GraphqlError from 'components/Errors/GraphqlError/GraphqlError';
import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import { useGetWebhookCallQuery, useResendWebhookCallMutation } from 'lib/graphql/graphql';
import { BorderedGrid } from 'modules/Events/Developers/Webhooks/styles';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactJson from 'react-json-view';
import { ContainedButton } from 'styles/styles';

import { ActionTitle, HTTPStatus, HTTPTitle } from './styles';

export interface CallDetailsProps {
  webhookCallId?: string;
  eventId?: string;
  webhookId?: string;
  onActionResent?: () => void | Promise<void>;
}
export default function CallDetails({
  eventId,
  webhookId,
  webhookCallId,
  onActionResent,
}: CallDetailsProps) {
  const { data, error, loading } = useGetWebhookCallQuery({
    variables: {
      id: webhookCallId!,
      eventId: eventId!,
      webhookId: webhookId!,
    },
    skip: !webhookCallId || !eventId || !webhookId,
  });

  const feedback = useFeedback();

  const [resendWebhookCall] = useResendWebhookCallMutation();

  const webhookCall = useMemo(() => data?.getWebhookCall, [data]);

  const handleResend = useCallback(async () => {
    try {
      await resendWebhookCall({
        variables: {
          id: webhookCallId!,
          eventId: eventId!,
          webhookId: webhookId!,
        },
      });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="webhook_resend_success" />),
        severity: 'success',
      });
      if (onActionResent) {
        await onActionResent();
      }
    } catch (e) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="webhook_resend_error" />),
        severity: 'error',
      });
    }
  }, [feedback, eventId, resendWebhookCall, webhookCallId, webhookId, onActionResent]);

  if (!webhookCallId || !eventId || !webhookId) {
    return null;
  }

  if (error) {
    return <GraphqlError error={error} />;
  }

  return (
    <Loader isLoading={loading}>
      <Grid container flexDirection="column" paddingTop={8} paddingLeft={4}>
        <Grid item flexGrow={1}>
          <Grid container flexGrow={1} flexDirection="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <ActionTitle>{webhookCall?.action}</ActionTitle>
            </Grid>
            <Grid item>
              <ContainedButton color="secondary" onClick={handleResend}>
                <Grid container alignItems="center" gap={1}>
                  <FontAwesomeIcon icon={faRotateRight} size="xl" />
                  <FormattedMessage id="webhook_resend" />
                </Grid>
              </ContainedButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item flexGrow={1}>
          <BorderedGrid
            container
            flexDirection="column"
            gap={2}
            paddingBottom={3}
            $borderBottom={{ enableBorder: true }}
          >
            {webhookCall?.statusCode && webhookCall?.response && (
              <>
                <Grid item>
                  <HTTPTitle>
                    <FormattedMessage id="webhook_response" />
                  </HTTPTitle>
                </Grid>
                <Grid item xs={6}>
                  <Grid container flexGrow={1} flexDirection="row" justifyContent="space-between" paddingLeft={2}>
                    <Grid item>
                      <HTTPStatus>
                        <FormattedMessage id="webhook_status_code" />
                      </HTTPStatus>
                    </Grid>
                    <Grid item>
                      {webhookCall?.statusCode}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <ReactJson
                    src={JSON.parse(webhookCall?.response || '{}')}
                    displayDataTypes={false}
                    name={null}
                  />
                </Grid>
              </>
            )}
          </BorderedGrid>
          <Grid item flexGrow={1}>
            <Grid container flexDirection="column" gap={2} paddingBottom={3}>
              <Grid item>
                <HTTPTitle>
                  <FormattedMessage id="webhook_request" />
                </HTTPTitle>
              </Grid>
              <Grid item>
                <ReactJson
                  src={JSON.parse(webhookCall?.payload || '{}')}
                  displayDataTypes={false}
                  name={null}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
}
