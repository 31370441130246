import {
  Autocomplete,
  Chip,
  Grid,
  TextField,
} from '@mui/material';
import { Languages } from 'lib/i18n/i18n';
import { useController } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import FormField, { FormFieldProps } from '../FormField';
import { AutocompleteContainer } from './styles';

function FormLanguagesField(props: FormFieldProps) {
  const {
    name,
    labelId,
    required,
    fieldDescription,
  } = props;

  const { field } = useController({ name, rules: { required } });
  const intl = useIntl();

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <Grid container alignItems="center">
        <AutocompleteContainer>
          <Autocomplete
            multiple
            fullWidth
            disableClearable
            options={Object.values(Languages)}
            getOptionDisabled={(option) => field.value.includes(option)}
            getOptionLabel={(option) => intl.formatMessage({ id: `language_${option}` })}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && field.value.length === 1) {
                    event.stopPropagation();
                  }
                }}
              />
            )}
            onChange={(_, newValue) => field.onChange(newValue)}
            value={field.value}
            renderTags={(value, getTagProps) => value.map((option, index) => (
              <Chip
                label={(
                  <>
                    <FormattedMessage id={`language_${option}`} />
                    {index === 0 ? <FormattedMessage id="language_default" /> : ''}
                  </>
                )}
                {...getTagProps({ index })}
                onDelete={value.length > 1 ? getTagProps({ index }).onDelete : undefined}
              />
            ))}
          />
        </AutocompleteContainer>
      </Grid>
    </FormField>
  );
}

export default FormLanguagesField;
