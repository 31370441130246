import { NullableText, Text } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext } from 'react';

interface LocalizedTextProps {
  text: NullableText | Text
  fallbackLanguage?: Languages
  fallback?: string
}

function LocalizedText(props: LocalizedTextProps) {
  const { fallback, fallbackLanguage, text } = props;

  const { language } = useContext(LanguageContext);

  return text?.[language] || (fallbackLanguage && text?.[fallbackLanguage]) || fallback || null;
}

export default LocalizedText;
