import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, useTheme } from '@mui/material';
import Loader from 'components/Loading/Loader';
import { useGetOrganizationQuery } from 'lib/graphql/graphql';
import { FormattedMessage } from 'react-intl';
import { Centered, NeutralOutlinedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import PageLayout from '../PageLayout';
import { OrganizationName, QuitPage } from './styles';

function StripeLandingPage() {
  const theme = useTheme();
  const [, setLocation] = useLocation();

  const { data, loading } = useGetOrganizationQuery();

  const organization = data?.getOrganization;

  const color = organization?.stripeAccountLinked
    ? theme.palette.success.main
    : theme.palette.error.main;
  const message = organization?.stripeAccountLinked ? 'stripe_landing_linked' : 'stripe_landing_linked_error';

  return (
    <PageLayout>
      <Centered>
        <Loader isLoading={loading}>
          <Grid container justifyContent="center" textAlign="center" rowGap="16px">
            <Grid item xs={12}>
              <FontAwesomeIcon icon={faCircleCheck} size="4x" color={color} />
            </Grid>
            <Grid item xs={12}>
              <Title>
                <FormattedMessage id={organization?.stripeAccountLinked ? 'stripe_congratulations' : 'stripe_error'} />
              </Title>
            </Grid>
            <Grid item xs={12}>
              <FormattedMessage
                id={message}
                values={{ organization: <OrganizationName>{organization?.name}</OrganizationName> }}
              />
            </Grid>
            <Grid item xs={12} justifyItems="center">
              {organization?.stripeAccountLinked ? (
                <QuitPage>
                  <FormattedMessage id="stripe_close_window" />
                </QuitPage>
              ) : (
                <NeutralOutlinedButton
                  onClick={() => setLocation('~/')}
                  startIcon={<FontAwesomeIcon size="1x" icon={faChevronLeft} />}
                >
                  <FormattedMessage id="return_to_dashboard" />
                </NeutralOutlinedButton>
              )}
            </Grid>
          </Grid>
        </Loader>

      </Centered>
    </PageLayout>
  );
}

export default StripeLandingPage;
