import PageLayout from 'components/PageLayout';
import Search from 'components/Search';
import styled, { css } from 'styled-components';

interface PageHeaderProps {
  $isMobile: boolean
}

export const PageHeader = styled.div<PageHeaderProps>`
  display: flex;
  gap: 16px;

  ${({ $isMobile }) => !$isMobile && css`
    flex-wrap: wrap;
    justify-content: space-between;
  `}
  ${({ $isMobile }) => $isMobile && css`
    flex-direction: column;
    align-items: center;
  `}
`;

export const FullHeightPageLayout = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
`;

export const StyledSearch = styled(Search)`
  width: 100%;
  max-width: 30rem;
  min-width: 220px;
`;
