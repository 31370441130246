import { NullableText, Text } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import EventContext from 'providers/event/EventContext';
import { useContext } from 'react';

import LocalizedText from './LocalizedText';

interface LocalizedTextProps {
  text: NullableText | Text
  fallback?: string
}

function EventLocalizedText(props: LocalizedTextProps) {
  const event = useContext(EventContext);

  const eventLanguage = event.languages[0] as Languages;

  return <LocalizedText {...props} fallbackLanguage={eventLanguage} />;
}

export default EventLocalizedText;
