import { WaitingLine, WaitingLineInput, WaitingLineType } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import useEvent from 'providers/event/useEvent';
import { useMemo } from 'react';

function useDefaultWaitingLine(data: Partial<WaitingLine> = {}) {
  const event = useEvent();

  return useMemo<WaitingLineInput>(() => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __typename,
      activatedTickets,
      status,
      ...waitingLineData
    } = data;

    return {
      name: {},
      description: {},
      productDescription: {},
      price: 2,
      quantity: 100,
      isFreemium: false,
      callSize: 5,
      limitedQuantity: false,
      maxGroupSize: 1,
      noShowTimeout: 0,
      autoCall: false,
      waitingLineType: WaitingLineType.Gold,
      statementDescriptor: '',
      hasTimeSlots: false,
      timeSlotStart: DateTime.now().startOf('day').toISO(),
      timeSlotEnd: event.endTime.toISO(),
      timeSlotDuration: 30,
      ticketsPerTimeSlot: 100,
      ...waitingLineData,
      currency: data?.currency?.toUpperCase() || 'CAD',
    };
  }, [data, event.endTime]);
}

export default useDefaultWaitingLine;
