import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { AseProvider } from '@novominteractive/ase-react-apollo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Authenticator from 'components/Authenticator/Authenticator';
import FeedbackProvider from 'components/Feedback/FeedbackProvider';
import aseClient from 'lib/ase/client';
import apolloClient from 'lib/graphql/apolloClient';
import LanguageProvider from 'providers/i18n/LanguageProvider';
import OrganizationProvider from 'providers/organization/OrganizationProvider';
import TitleProvider from 'providers/title/TitleProvider';
import InviteTokenProvider from 'providers/token/InviteTokenProvider';
import Routes from 'routes/Routes';

import AppThemeProvider from '../styles/AppThemeProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AseProvider client={aseClient}>
        <StyledEngineProvider injectFirst>
          <LanguageProvider>
            <AppThemeProvider>
              <CssBaseline enableColorScheme />
              <QueryClientProvider client={queryClient}>
                <TitleProvider>
                  <Authenticator>
                    <OrganizationProvider>
                      <InviteTokenProvider>
                        <FeedbackProvider>
                          <Routes />
                        </FeedbackProvider>
                      </InviteTokenProvider>
                    </OrganizationProvider>
                  </Authenticator>
                </TitleProvider>
              </QueryClientProvider>
            </AppThemeProvider>
          </LanguageProvider>
        </StyledEngineProvider>
      </AseProvider>
    </ApolloProvider>
  );
}

export default App;
