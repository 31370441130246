import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from 'styled-components';

const LightTooltip = styled((props: TooltipProps) => (
  // eslint-disable-next-line react/jsx-no-undef
  <Tooltip classes={{ popper: props.className }} {...props} />
))`

  & .${tooltipClasses.tooltip} {
    padding: 0;
    background-color: #fff;
    color: ${({ theme }) => theme.palette.text.primary};
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }

  & .${tooltipClasses.arrow} {
    color: #fff;
    
    &::before {
      border: 1px solid ${({ theme }) => theme.palette.divider};
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    }
  }
`;

export default LightTooltip;
