import { GetAllEventWebhooksQuery } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { GraphDiv, GraphLine } from '../../styles';

export interface CallsGraphProps {
  data: GetAllEventWebhooksQuery['getAllEventWebhooks']['webhooks'][0]['lastDaysMetadata'];
}

interface GraphData {
  successData: number[];
  errorData: number[];
  maxValue: number;
}

export default function CallsGraph({ data }: CallsGraphProps) {
  const date = DateTime.utc();

  const { successData, errorData, maxValue }: GraphData = useMemo<GraphData>((): GraphData => {
    const success: number[] = [];
    const errors: number[] = [];
    let max = 0;
    [...Array(7)].forEach((_, index) => {
      const day = date.minus({ days: 6 }).plus({ days: index }).toISODate();
      const dayData = data?.find((d) => d?.day === day) ?? { success: 0, errors: 0 };
      success.push(dayData.success);
      errors.push(dayData.errors);
      if (dayData.success > max) {
        max = dayData.success;
      }
      if (dayData.errors > max) {
        max = dayData.errors;
      }
    });

    return { errorData: errors, successData: success, maxValue: max };
  }, [data, date]);

  return (
    <GraphDiv>
      <GraphLine
        plotType="line"
        data={successData}
        yAxis={{
          min: 0,
          max: Math.max(maxValue, 10),
        }}
      />
      <GraphLine
        plotType="line"
        data={errorData}
        colors={['#f00']}
        yAxis={{
          min: 0,
          max: Math.max(maxValue, 10),
        }}
      />
    </GraphDiv>
  );
}
