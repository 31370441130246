import useFeedback from 'components/Feedback/useFeedback';
import { useCustomForm } from 'lib/form/useCustomForm';
import { useCreateWebhookMutation, WebhookInput } from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../../../useEventDocumentTitle';
import WebhookForm from './WebhookForm';

export default function CreateWebhook() {
  useEventDocumentTitle('webhook_create');
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const event = useEvent();

  const defaultValues = {
    endpointUrl: '',
    description: '',
    actions: [],
  };

  const methods = useCustomForm<WebhookInput>({
    defaultValues,
    trim: true,
  });

  const [createWebhook] = useCreateWebhookMutation();

  const onSubmit: SubmitHandler<WebhookInput> = async (formData) => {
    try {
      await createWebhook({
        variables: {
          eventId: event.id || '',
          fields: {
            ...formData,
          },
        },
      });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="create_webhook_success" />),
        severity: 'success',
      });

      setLocation('/');
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="create_webhook_error" />),
        severity: 'error',
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <WebhookForm
        title="create"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}
