import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import GraphqlError from 'components/Errors/GraphqlError/GraphqlError';
import Loader from 'components/Loading/Loader';
import { WebhookCallPaginatedListItem } from 'lib/graphql/entities/webhhook/types';
import { useGetAllWebhookCallsLazyQuery } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BorderedGrid,
  BorderedListItem,
  EmptyList,
  FullWidthList,
} from '../../styles';
import CallDetails from './components/CallDetails/CallDetails';
import WebhookCallListItem from './components/CallListItem';
import { LoadMoreBorderedListItem } from './styles';

export interface WebhookCallsProps {
  webhookId: string;
  eventId: string;
}

export default function WebhookCalls(props: WebhookCallsProps) {
  const {
    eventId,
    webhookId,
  } = props;
  const [getAllWebhookCallsQuery] = useGetAllWebhookCallsLazyQuery();
  const [selectedWebhookCallId, setSelectedWebhookCallId] = useState<string | undefined>(undefined);

  const {
    data,
    error,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    getNextPageParam: (lastPage) => lastPage?.lastEvaluated,
    initialPageParam: undefined,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllWebhookCallsQuery({
        fetchPolicy: 'network-only',
        variables: {
          webhookId,
          eventId,
          start: pageParam,
        },
      });
      return result.data?.getAllWebhookCalls;
    },
    queryKey: ['webhookCalls', webhookId, eventId],
  });

  const calls = useMemo(() => (
    ((data?.pages.flatMap((page) => page?.webhookCalls) || [])
      .filter(Boolean) as WebhookCallPaginatedListItem[])
      .reduce<Record<string, WebhookCallPaginatedListItem[]>>((days, webhookCall) => {
      const date = DateTime.fromISO(webhookCall.createdAt).toFormat('yyyy-MM-dd');
      return {
        ...days,
        [date]: [...(days[date] || []), webhookCall],
      };
    }, {})
  ), [data]);

  useEffect(() => {
    setSelectedWebhookCallId((selectedId) => (
      selectedId || calls[Object.keys(calls)[0]]?.[0]?.id || undefined
    ));
  }, [calls]);

  const handleActionResent = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleLoadMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  if (error) {
    return <GraphqlError error={error} />;
  }

  if (!isLoading && Object.keys(calls).length === 0) {
    return (
      <EmptyList>
        <FormattedMessage id="webhook_no_calls" />
      </EmptyList>
    );
  }

  return (
    <Loader isLoading={isLoading}>
      <Grid container flexDirection="row">
        <BorderedGrid
          item
          xs={6}
          $borderRight={{ enableBorder: true }}
          $borderTop={{ enableBorder: true }}
        >
          <FullWidthList>
            {Object.keys(calls).map((date) => (
              <ListItem disablePadding>
                <FullWidthList>
                  <BorderedListItem $borderBottom={{ enableBorder: true }}>
                    <ListItemText primary={date} />
                  </BorderedListItem>
                  {calls[date].map((call) => (
                    <WebhookCallListItem
                      webhookCall={call}
                      onClick={() => setSelectedWebhookCallId(call.id)}
                      selected={selectedWebhookCallId === call.id}
                    />
                  ))}
                </FullWidthList>
              </ListItem>
            ))}
            {hasNextPage && (
              <LoadMoreBorderedListItem $borderBottom={{ enableBorder: true }} alignItems="center" disablePadding disableGutters>
                <ListItemButton onClick={handleLoadMore} alignItems="center">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Grid container justifyContent="space-around" gap="0.5rem">
                        <Grid item>
                          <FontAwesomeIcon icon={faArrowDown} />
                        </Grid>
                        <Grid item>
                          <FormattedMessage id="webhook_load_more" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItemButton>
              </LoadMoreBorderedListItem>
            )}
          </FullWidthList>
        </BorderedGrid>
        <BorderedGrid item xs={6} $borderTop={{ enableBorder: true }}>
          <CallDetails
            eventId={eventId}
            webhookId={webhookId}
            webhookCallId={selectedWebhookCallId}
            onActionResent={handleActionResent}
          />
        </BorderedGrid>
      </Grid>
    </Loader>
  );
}
