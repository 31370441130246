import Loader from 'components/Loading/Loader';
import useNoShowTicketSubscription from 'lib/graphql/entities/tickets/useNoShowTicketSubscription';
import usePagination, { mergePaginatedList } from 'lib/usePagination';
import { useMemo } from 'react';

import { TicketCard, TicketCardNumber, TicketsList } from '../styles';
import ValidateButton from './ValidateButton';

interface NoShowTicketsProps {
  waitingLineId: string
}

function NoShowTickets(props: NoShowTicketsProps) {
  const { waitingLineId } = props;

  const { data, loading } = usePagination(
    useNoShowTicketSubscription({ variables: { waitingLineId } }),
    (d) => d.getAllNoShowWaitingLineTickets.lastEvaluated,
    (existing, incoming) => ({
      getAllNoShowWaitingLineTickets: mergePaginatedList(
        existing.getAllNoShowWaitingLineTickets,
        incoming.getAllNoShowWaitingLineTickets,
        'tickets',
      ),
    }),
  );
  const tickets = data?.getAllNoShowWaitingLineTickets.tickets;

  const sortedTickets = useMemo(() => (
    [...tickets || []].sort((a, b) => a.displayId - b.displayId)
  ), [tickets]);

  return (
    <Loader isLoading={loading || !waitingLineId}>
      <TicketsList>
        {sortedTickets.map((ticket) => (
          <TicketCard key={ticket.id}>
            <TicketCardNumber>
              {ticket.displayId}
            </TicketCardNumber>
            <ValidateButton
              ticket={ticket}
              waitingLineId={waitingLineId!}
            />
          </TicketCard>
        ))}
      </TicketsList>
    </Loader>
  );
}

export default NoShowTickets;
