import { QueryResult } from '@apollo/client';
import { useEffect, useRef } from 'react';

interface PaginatedVariables {
  start?: string
}

export function mergePaginatedList<
TData extends Record<string, any>,
TKey extends keyof TData,
>(existing: TData, incoming: TData, key: TKey) {
  return {
    ...incoming,
    [key]: [...existing[key], ...incoming[key]],
  };
}

function usePagination<
TData extends Record<string, any>,
TVariables extends PaginatedVariables = PaginatedVariables,
>(
  query: QueryResult<TData, TVariables>,
  getLastEvaluated: (data: TData) => string | undefined,
  merge: (existing: TData, incoming: TData) => TData,
): Omit<QueryResult<TData, TVariables>, 'fetchMore'> {
  const { data, fetchMore } = query;

  const stableMerge = useRef(merge);
  stableMerge.current = merge;

  const lastEvaluated = data ? getLastEvaluated(data) : undefined;

  useEffect(() => {
    if (lastEvaluated) {
      fetchMore({
        variables: { start: lastEvaluated },
        updateQuery: (existing, { fetchMoreResult }) => (
          stableMerge.current(existing, fetchMoreResult)
        ),
      });
    }
  }, [fetchMore, lastEvaluated]);

  return query;
}

export default usePagination;
