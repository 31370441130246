import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faWarning, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { GetAllWaitingLineTicketsItem } from 'lib/graphql/entities/tickets/types';
import { QueueType, WaitingLineType } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import {
  CalledContainer,
  ContainerCheck,
  DisplayId,
  DisplayIdHeader,
  NoShowContainer,
  NotCalledContainer,
  StatusCheck,
} from 'modules/Events/EventWaitingLines/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import WaitingLineTicketMore from '../WaitingLineTicketMore/WaitingLineTicketMore';

type TicketGridColDef = GridColDef<GetAllWaitingLineTicketsItem>;

export function getDisplayIdColumn(intl: ReturnType<typeof useIntl>): TicketGridColDef {
  return {
    field: 'displayId',
    headerName: intl.formatMessage({ id: 'ticket_operation_display_id' }),
    renderHeader: () => (
      <DisplayIdHeader>
        <FormattedMessage id="ticket_operation_display_id" />
      </DisplayIdHeader>
    ),
    flex: 0.2,
    renderCell: (params) => {
      const { displayId } = params.row;
      return (
        <DisplayId>
          {displayId}
        </DisplayId>
      );
    },
    resizable: false,
  };
}

export function getTransactionIdColumn(intl: ReturnType<typeof useIntl>): TicketGridColDef {
  return {
    field: 'transactionId',
    headerName: intl.formatMessage({ id: 'ticket_operation_transaction_id' }),
    flex: 0.5,
    resizable: false,
  };
}

export function getReservationTimeColumn(intl: ReturnType<typeof useIntl>): TicketGridColDef {
  return {
    field: 'createdAt',
    headerName: intl.formatMessage({ id: 'ticket_operation_created_at' }),
    flex: 0.2,
    resizable: false,
    valueGetter: (value: string) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT),
  };
}

export function getStatusColumn(intl: ReturnType<typeof useIntl>): TicketGridColDef {
  return {
    field: 'status',
    headerName: intl.formatMessage({ id: 'ticket_operation_status' }),
    flex: 0.2,
    resizable: false,
    renderCell: (params) => {
      const { row } = params;
      const { status } = row;

      return <FormattedMessage id={`ticket_status_${status}`} />;
    },
  };
}

export function getActionsColumn(
  waitingLineType: WaitingLineType,
  handleRefetch: () => void,
  isFreemium: boolean,
): TicketGridColDef {
  return {
    field: 'actions',
    renderHeader: () => (
      <IconButton onClick={handleRefetch} color="primary">
        <FontAwesomeIcon icon={faSync} size="sm" />
      </IconButton>
    ),
    flex: 0.1,
    type: 'actions',
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const { row } = params;
      return (
        <WaitingLineTicketMore
          waitingLineType={waitingLineType}
          isFreemium={isFreemium}
          ticket={row}
          refetch={handleRefetch}
        />
      );
    },
    resizable: false,
  };
}

export function getCalledColumn(intl: ReturnType<typeof useIntl>): TicketGridColDef {
  return {
    field: 'queueType',
    headerName: intl.formatMessage({ id: 'ticket_operation_called' }),
    flex: 0.1,
    resizable: false,
    renderCell: (params) => {
      const { queueType } = params.row;

      return (
        <CalledContainer>
          {queueType === QueueType.Called && (
            <Tooltip title={<FormattedMessage id="ticket_operation_called" />}>
              <ContainerCheck>
                <StatusCheck
                  icon={faCheck}
                  size="sm"
                />
              </ContainerCheck>
            </Tooltip>
          )}
          {queueType === QueueType.NoShow && (
            <Tooltip title={<FormattedMessage id="ticket_operation_no_show" />}>
              <NoShowContainer>
                <StatusCheck
                  icon={faWarning}
                  size="sm"
                />
              </NoShowContainer>
            </Tooltip>
          )}
          {queueType && queueType !== QueueType.Called && queueType !== QueueType.NoShow && (
            <NotCalledContainer>
              <StatusCheck
                icon={faXmark}
                size="xl"
              />
            </NotCalledContainer>
          )}
        </CalledContainer>
      );
    },
  };
}
