import { LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import styled from 'styled-components';

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 12px;
  flex-wrap: wrap;
  max-width: 30rem;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const ProgressText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: black;
  opacity: 0.5;
  justify-content: flex-start;
  text-align: center;
`;

export const ProgressTicketBar = styled(LinearProgress)`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.text.disabled};

  & .${linearProgressClasses.bar1Determinate} {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const TicketsLeft = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: black;
`;

export const TicketSold = styled.span`
  font-size: 32px;
  font-weight: bold;
`;
