import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breadcrumbs,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import CauseCard from 'components/Causes/CauseCard';
import CauseObnlDialog from 'components/Causes/CauseObnlDialog';
import CauseOrganizationCard from 'components/Causes/CauseOrganizationCard';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import PageLayout from 'components/PageLayout';
import { GetAllCausesItem } from 'lib/graphql/entities/cause/types';
import { GetAllObnlsByCauseItem } from 'lib/graphql/entities/obnl/types';
import {
  useAddObnlToEventMutation,
  useGetAllCausesLazyQuery,
  useGetAllObnlsByCauseLazyQuery,
} from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Breadcrumb,
  BreadcrumbsContainer,
  ContainedButton,
  NeutralOutlinedButton,
  RoundedSelect,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import { NoObnlsFound } from './styles';

function EventCausesSelection() {
  const event = useEvent();
  const { refetchObnls } = event;
  const [selectedCause, setSelectedCause] = useState<GetAllCausesItem | null>(null);
  const [obnls, setObnls] = useState<GetAllObnlsByCauseItem[]>([]);
  const [selectedObnl, setSelectedObnl] = useState<GetAllObnlsByCauseItem | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const { language } = useContext(LanguageContext);
  const [, setLocation] = useLocation();

  useEventDocumentTitle(selectedCause ? 'choose_obnl_to_support' : 'choose_one_goal_from_sdg_title');

  const [getAllCausesQuery] = useGetAllCausesLazyQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    getNextPageParam: (lastPage) => lastPage?.lastEvaluated,
    initialPageParam: undefined,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllCausesQuery({
        fetchPolicy: 'network-only',
        variables: {
          start: pageParam,
        },
      });
      return result.data?.getAllCauses;
    },
    queryKey: ['causes'],
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const causes: GetAllCausesItem[] = useMemo(
    () => data?.pages.flatMap((page) => page?.causes.filter((cause) => cause.hasObnls) || []) || [],
    [data?.pages],
  );

  // TODO: Pagination
  const [getAllObnlsbyCause] = useGetAllObnlsByCauseLazyQuery();

  const fetchObnls = useCallback(async (cause: GetAllCausesItem) => {
    refetchObnls();
    if (cause) {
      const { data: obnlsData } = await getAllObnlsbyCause({
        variables: {
          cause: cause.name,
        },
      });

      const results = obnlsData?.getAllObnlsByCause.obnls ?? [];

      setObnls(results);
    }
  }, [getAllObnlsbyCause, refetchObnls]);

  const handleSelectCause = async (cause: GetAllCausesItem) => {
    setSelectedCause(cause);
    await fetchObnls(cause);
  };

  const handleChangeCause = async (causeId: string) => {
    const newCause = causes?.find((cause) => cause.id === causeId) as GetAllCausesItem;
    setSelectedCause(newCause);
    await fetchObnls(newCause);
  };

  const [addObnlToEvent, { loading: addLoading }] = useAddObnlToEventMutation();

  const handleSelectObnl = async (obnl: GetAllObnlsByCauseItem) => {
    setSelectedObnl(obnl);

    if (obnl) {
      await addObnlToEvent({
        variables: {
          eventId: event.id,
          obnlId: obnl.id,
        },
      });
    }
    refetchObnls();
    setDialogOpen(true);
  };

  const eventObnls : Set<string> = useMemo<Set<string>>(() => (
    new Set(event.obnls ?? [])
  ), [event.obnls]);

  const [selectedGoal, setSelectedGoal] = useState<GetAllCausesItem | null>(null);
  const handleGoalClick = (cause: GetAllCausesItem) => {
    setSelectedGoal((prevGoal) => (prevGoal === cause ? null : cause));
  };

  const eventLanguage = event.languages?.[0] as Languages;

  if (isLoading) return <CenteredSpinner />;
  if (error) return <div>TODO</div>;

  // TODO: Add organizations count for each cause (?)

  return (
    <PageLayout>
      <BreadcrumbsContainer>
        <Breadcrumbs separator="—">
          <Breadcrumb
            $isClickable
            color="textSecondary"
            onClick={() => setLocation('/causes')}
          >
            <FormattedMessage id="causes" />
          </Breadcrumb>
          <Breadcrumb
            $isClickable
            color={selectedCause ? 'textSecondary' : 'secondary'}
            onClick={() => setSelectedCause(null)}
          >
            <FormattedMessage id="choose_one_goal_from_sdg_title" />
          </Breadcrumb>
          <Breadcrumb
            $isClickable={!!selectedGoal}
            color={selectedCause ? 'secondary' : 'textSecondary'}
            onClick={() => handleSelectCause(selectedGoal as GetAllCausesItem)}
          >
            <FormattedMessage id="choose_obnl_to_support" />
          </Breadcrumb>
        </Breadcrumbs>
      </BreadcrumbsContainer>
      {!selectedCause && (
      <>
        <Title>
          <FormattedMessage id="choose_one_goal_from_sdg_title" />
        </Title>
        <Grid container direction="row" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="choose_one_goal_from_sdg_description" />
          </Typography>
        </Grid>
        <Grid container direction="column">
          <Grid container xs={12} direction="row" gap="0.5rem" justifyContent="space-between">
            {causes?.map((cause) => (
              <CauseCard
                key={cause.id}
                title={cause.name}
                isActive={selectedGoal === cause}
                onClick={() => handleGoalClick(cause)}
              />
            ))}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end" mt={1} gap={1}>
          <NeutralOutlinedButton
            onClick={() => setLocation('/causes')}
            startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
          >
            <FormattedMessage id="previous" />
          </NeutralOutlinedButton>
          <ContainedButton
            color="secondary"
            disabled={!selectedGoal}
            onClick={() => handleSelectCause(selectedGoal as GetAllCausesItem)}
            endIcon={<FontAwesomeIcon icon={faChevronRight} />}
          >
            <FormattedMessage id="next" />
          </ContainedButton>
        </Grid>
      </>
      )}
      {selectedCause && (
        <>
          <Title>
            <FormattedMessage id="choose_obnl_to_support" />
          </Title>
          <RoundedSelect
            defaultValue={selectedCause.id}
            onChange={(e) => handleChangeCause(e.target.value as string)}
          >
            {causes?.map((cause) => (
              <MenuItem key={cause.id} value={cause.id}>
                <FormattedMessage id={`obnl_${cause.name}`} />
              </MenuItem>
            ))}
          </RoundedSelect>
          <Grid container direction="row" justifyContent="center" mt={1} gap={2}>
            {obnls && obnls.map((obnl) => (
              <CauseOrganizationCard
                key={obnl.id}
                logo={obnl.logo ?? undefined}
                name={obnl.name}
                description={obnl.description?.[language] || obnl.description?.[eventLanguage] || ''}
                onClick={() => handleSelectObnl(obnl)}
                disabled={eventObnls.has(obnl.id)}
                isLoading={addLoading}
              />
            ))}
            {(!obnls || obnls.length === 0) && (
              <NoObnlsFound>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage id="no_supported_organizations_found" />
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage id="please_try_with_new_cause" />
                </Typography>
              </NoObnlsFound>
            )}
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" mt={1} gap={1}>
            <NeutralOutlinedButton
              onClick={() => setSelectedCause(null)}
              startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            >
              <FormattedMessage id="previous" />
            </NeutralOutlinedButton>
          </Grid>
          {selectedObnl && (
            <CauseObnlDialog
              obnl={selectedObnl}
              dialogOpen={dialogOpen}
              onAddMore={() => {
                setDialogOpen(false);
                setSelectedCause(null);
              }}
              onClose={() => {
                setDialogOpen(false);
                setLocation('/causes');
              }}
            />
          )}
        </>
      )}
    </PageLayout>
  );
}

export default EventCausesSelection;
