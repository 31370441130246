import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from 'styled-components';

export interface ColorSwatchIconProps {
  $color: string;
}

export const ColorSwatchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ColorSwatchIcon = styled(FontAwesomeIcon)<ColorSwatchIconProps>`
  color: ${({ $color }) => $color};
`;

export const ColorSwatchSecondaryIcon = styled(FontAwesomeIcon)<ColorSwatchIconProps>`
  color: ${({ $color }) => $color};
  position: absolute;
  rotate: 180deg;
`;

export const ColorSwatchCheckIcon = styled(FontAwesomeIcon)<ColorSwatchIconProps>`
  color: ${({ $color }) => $color};
  position: absolute;
  font-size: 1rem;
`;

export const ColorSwatchOutlineIcon = styled(FontAwesomeIcon)<ColorSwatchIconProps>`
  color: ${({ $color }) => $color};
  font-size: 1.5rem;
  position: absolute;
  filter: brightness(85%);
`;

export const ColorSwatchSelectedIcon = styled(ColorSwatchOutlineIcon)<ColorSwatchIconProps>`
  font-size: 2rem;
`;
