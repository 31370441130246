import { faDisplay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import Loader from 'components/Loading/Loader';
import { GetWaitingLineQuery, useGetAllWaitingLineTicketsQuery } from 'lib/graphql/graphql';
import settings from 'lib/settings';
import usePagination, { mergePaginatedList } from 'lib/usePagination';
import WaitingLineStatusSelector from 'modules/Events/EventWaitingLines/components/WaitingLineStatusSelector';
import { FormattedMessage } from 'react-intl';

import TicketProgress from '../TicketProgress/TicketProgress';
import WaitingLineTicketList from '../WaitingLineTicketList';
import { AlignRightContainer, NoBorderButton, Spacer } from './styles';

interface WaitingLineOperationsProps {
  refetchWaitingLine: () => void
  searchValue: string
  waitingLine: NonNullable<GetWaitingLineQuery['getWaitingLine']>
}

function WaitingLineOperations(props: WaitingLineOperationsProps) {
  const { refetchWaitingLine, searchValue, waitingLine } = props;

  const { data, loading, refetch } = usePagination(
    useGetAllWaitingLineTicketsQuery({ variables: { waitingLineId: waitingLine.id } }),
    (d) => d.getAllWaitingLineTickets.lastEvaluated,
    (existing, incoming) => ({
      getAllWaitingLineTickets: mergePaginatedList(
        existing.getAllWaitingLineTickets,
        incoming.getAllWaitingLineTickets,
        'tickets',
      ),
    }),
  );
  const tickets = data?.getAllWaitingLineTickets.tickets || [];

  return (
    <>
      <AlignRightContainer>
        <TicketProgress tickets={tickets} />
        <Spacer />
        {waitingLine.isFreemium && (
          <Tooltip placement="top" arrow title={<FormattedMessage id="event_view_display" />}>
            <NoBorderButton
              onClick={() => window.open(`${settings.displayUrl}/${waitingLine.id}`)}
              color="secondary"
              size="medium"
            >
              <FontAwesomeIcon icon={faDisplay} />
            </NoBorderButton>
          </Tooltip>
        )}
        <WaitingLineStatusSelector
          waitingLine={waitingLine}
          handleRefetch={refetchWaitingLine}
        />
      </AlignRightContainer>
      <Loader isLoading={loading}>
        <WaitingLineTicketList
          searchValue={searchValue}
          tickets={tickets}
          handleRefetch={() => refetch()}
          waitingLineType={waitingLine.waitingLineType}
          isFreemium={waitingLine.isFreemium}
        />
      </Loader>
    </>
  );
}

export default WaitingLineOperations;
