import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import PageLayout from 'components/PageLayout';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../../useEventDocumentTitle';
import WebhookList from './WebhookList/WebhookList';

export default function Webhooks() {
  useEventDocumentTitle('webhooks');
  const [, setLocation] = useLocation();

  return (
    <PageLayout>
      <Grid container justifyContent="space-between" alignItems="center" pb="1rem">
        <Grid item>
          <Title>
            <FormattedMessage id="webhooks" />
          </Title>
          <Grid container direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="webhooks_subtitle" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <ContainedButton
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setLocation('/create')}
            >
              <FormattedMessage id="add_webhook" />
            </ContainedButton>
          </Grid>
        </Grid>
      </Grid>
      <WebhookList />
    </PageLayout>
  );
}
