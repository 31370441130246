import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'wouter';

function useOperationWaitingLine(): [string | null, (id: string) => void] {
  const [location, setLocation] = useLocation();
  const [waitingLineId, setWaitingLineId] = useState<string | null>(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('activeWaitingLine');
  });

  const setWaitingLineInUrl = useCallback((id: string) => {
    setWaitingLineId(id);
    setLocation(`${location}?activeWaitingLine=${id}`);
  }, [location, setLocation]);

  return useMemo(() => [waitingLineId, setWaitingLineInUrl], [setWaitingLineInUrl, waitingLineId]);
}

export default useOperationWaitingLine;
