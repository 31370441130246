import { useAse } from '@novominteractive/ase-react-apollo';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import { useAseLoginMutation } from 'lib/graphql/graphql';
import { PropsWithChildren, useEffect } from 'react';

function AseAuthenticator(props: PropsWithChildren) {
  const { children } = props;

  const [aseLogin] = useAseLoginMutation();
  const { login: aseClientLogin, loggedIn } = useAse();

  useEffect(() => {
    const auth = async () => {
      if (!loggedIn) {
        const log = await aseLogin();
        if (log) {
          await aseClientLogin(log.data?.aseLogin!);
        }
      }
    };

    auth();
  }, [loggedIn, aseClientLogin, aseLogin]);

  if (!loggedIn) return <CenteredSpinner />;
  return children;
}

export default AseAuthenticator;
