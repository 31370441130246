import { SwitchProps, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormField, { FormFieldProps } from '../FormField';
import { GroupedButton, RoundedButtonGroup } from './styles';

type CustomSwitchProps = Omit<SwitchProps, 'onChange' | 'onBlur' | 'inputRef' | 'checked' | 'required' | 'name' | 'inputProps' | 'type' | 'defaultChecked'>;

interface FormToggleFieldProps extends FormFieldProps, CustomSwitchProps {
  leftLabel: string
  rightLabel: string
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'min' | 'max'>
  sideBySide?: boolean
  disabledReason?: React.ReactNode | string
}

function FormToggleFields(props: FormToggleFieldProps) {
  const {
    disabled,
    labelId,
    leftLabel,
    name,
    rules,
    sideBySide = false,
    fieldDescription,
    rightLabel,
    required,
    disabledReason,
  } = props;

  const { field } = useController({ name, rules });
  const { onChange, value, ...otherProps } = field;

  const changeValue = useCallback((newValue: boolean) => {
    onChange({ target: { name, value: newValue } });
  }, [name, onChange]);

  return (
    <FormField
      name={name}
      labelId={labelId}
      sideBySide={sideBySide}
      required={required}
      fieldDescription={fieldDescription}
    >
      <Tooltip title={disabled ? disabledReason : ''}>
        <RoundedButtonGroup {...otherProps}>
          <GroupedButton
            color={value ? 'inherit' : 'secondary'}
            disabled={disabled && value}
            onClick={() => changeValue(false)}
          >
            <FormattedMessage id={leftLabel} />
          </GroupedButton>
          <GroupedButton
            color={!value ? 'inherit' : 'secondary'}
            disabled={disabled && !value}
            onClick={() => changeValue(true)}
          >
            <FormattedMessage id={rightLabel} />
          </GroupedButton>
        </RoundedButtonGroup>
      </Tooltip>
    </FormField>
  );
}

export default FormToggleFields;
