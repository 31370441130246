import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const EventListHeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
  gap: 1rem;
`;
