import { Menu } from '@mui/material';
import useIsMobile from 'lib/useIsMobile';
import { PropsWithChildren } from 'react';

import { RoundedDrawer } from './styles';

interface OptionsMenuProps extends PropsWithChildren {
  anchorElement: HTMLElement | null
  onClose: () => void
}

function OptionsMenu(props: OptionsMenuProps) {
  const { anchorElement, children, onClose } = props;

  const isMobile = useIsMobile();

  const open = Boolean(anchorElement);

  if (isMobile) {
    return (
      <RoundedDrawer
        anchor="bottom"
        open={open}
        onClose={onClose}
      >
        {children}
      </RoundedDrawer>
    );
  }

  return (
    <Menu
      anchorEl={anchorElement}
      open={open}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
}

export default OptionsMenu;
