import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Stripe from 'stripe';
import { RoundedSelect } from 'styles/styles';

interface BankTransfersPickerProps {
  payouts: Stripe.Payout[];
  onPayoutsChange: (payouts: Stripe.Payout[]) => void;
}

function BankTransfersPicker(props: BankTransfersPickerProps) {
  const {
    payouts,
    onPayoutsChange,
  } = props;

  const intl = useIntl();

  const [selectedTimeRange, setSelectedTimeRange] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all_status');

  const handleTimeRangeChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedTimeRange(e.target.value as string);
  };

  const handleStatusChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedStatus(e.target.value as string);
  };

  const uniqueMonths = useMemo(() => {
    const currentMonthYear = DateTime.now().toFormat('yyyy-MM');
    const months = payouts
      .map((payout) => DateTime.fromSeconds(payout.created).toFormat('yyyy-MM'))
      .filter((month) => month !== currentMonthYear);

    const uniqueMonthsSet = new Set(months);
    const sortedMonths = Array.from(uniqueMonthsSet).sort((a, b) => DateTime.fromFormat(a, 'yyyy-MM').toMillis() - DateTime.fromFormat(b, 'yyyy-MM').toMillis());

    return sortedMonths;
  }, [payouts]);

  const filteredPayouts = useMemo(() => {
    let filtered = payouts;

    if (selectedTimeRange !== 'all') {
      const now = DateTime.now();
      filtered = filtered.filter((payout) => {
        const payoutDate = DateTime.fromSeconds(payout.created);
        switch (selectedTimeRange) {
          case 'today':
            return payoutDate.hasSame(now, 'day');
          case 'yesterday':
            return payoutDate.hasSame(now.minus({ days: 1 }), 'day');
          case 'last_week':
            return payoutDate >= now.minus({ weeks: 1 });
          case 'this_month':
            return payoutDate >= now.startOf('month');
          default:
            return payoutDate.toFormat('yyyy-MM') === selectedTimeRange;
        }
      });
    }

    if (selectedStatus !== 'all_status') {
      filtered = filtered.filter(
        (payout) => payout.status === selectedStatus,
      );
    }

    return filtered;
  }, [payouts, selectedStatus, selectedTimeRange]);

  useEffect(() => {
    onPayoutsChange(filteredPayouts);
  }, [filteredPayouts, onPayoutsChange]);

  const getMonthYear = (month: string) => {
    const date = DateTime.fromFormat(month, 'yyyy-MM').toLocaleString({ month: 'long', year: 'numeric' }, { locale: intl.locale });
    return date.charAt(0).toUpperCase() + date.slice(1);
  };

  return (
    <Grid container direction="row" gap={2} alignItems="center" mb={1}>
      <Grid item>
        <RoundedSelect value={selectedTimeRange} onChange={handleTimeRangeChange}>
          <MenuItem value="all">
            <FormattedMessage id="filter_all" />
          </MenuItem>
          <MenuItem value="today">
            <FormattedMessage id="filter_today" />
          </MenuItem>
          <MenuItem value="yesterday">
            <FormattedMessage id="filter_yesterday" />
          </MenuItem>
          <MenuItem value="last_week">
            <FormattedMessage id="filter_last_week" />
          </MenuItem>
          <MenuItem value="this_month">
            <FormattedMessage id="filter_this_month" />
          </MenuItem>
          {uniqueMonths.map((month) => (
            <MenuItem key={month} value={month}>
              {getMonthYear(month)}
            </MenuItem>
          ))}
        </RoundedSelect>
      </Grid>
      <Grid item>
        <RoundedSelect value={selectedStatus} onChange={handleStatusChange}>
          <MenuItem value="all_status">
            <FormattedMessage id="filter_all_status" />
          </MenuItem>
          <MenuItem value="canceled">
            <FormattedMessage id="payout_status_canceled" />
          </MenuItem>
          <MenuItem value="failed">
            <FormattedMessage id="payout_status_failed" />
          </MenuItem>
          <MenuItem value="in_transit">
            <FormattedMessage id="payout_status_in_transit" />
          </MenuItem>
          <MenuItem value="paid">
            <FormattedMessage id="payout_status_paid" />
          </MenuItem>
          <MenuItem value="pending">
            <FormattedMessage id="payout_status_pending" />
          </MenuItem>
        </RoundedSelect>
      </Grid>
    </Grid>
  );
}

export default BankTransfersPicker;
