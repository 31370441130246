import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import {
  faCalendar,
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faClose,
  faCode,
  faFileChartPie,
  faGear,
  faListCheck,
  faLocationDot,
  faMoneySimpleFromBracket,
  faPaintBrush,
  faQrcode,
  faUsers,
  faWebhook,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import EnsureRole from 'lib/access/EnsureRole';
import { EventStatus } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NeutralOutlinedButton } from 'styles/styles';
import { useLocation } from 'wouter';

import EventSideButton from './components/EventSideButton';
import EventSideCollapse from './components/EventSideCollapse';
import {
  Divider,
  DrawerHeader,
  EventList,
  EventListItem,
  ExitButtonContainer,
  HeaderLayout,
  SideDrawerSubheader,
  SideDrawerTitle,
} from './styles';

const BACK_TO = '~/';

interface EventSideViewProps {
  setDrawerOpen: (open: boolean) => void;
  isMobile?: boolean;
}

function EventSideView(props: EventSideViewProps) {
  const { setDrawerOpen, isMobile = false } = props;
  const [, setLocation] = useLocation();
  const event = useEvent();
  const { locale } = useIntl();
  const { language } = useContext(LanguageContext);

  const [startDate, endDate] = useMemo(() => {
    const start = event.startTime.setZone(event.timezone).setLocale(locale).toFormat('DDD');
    const end = event.endTime.setZone(event.timezone).setLocale(locale).toFormat('DDD');
    return [start, end];
  }, [event, locale]);

  const eventLanguage = event.languages[0] as Languages;

  return (
    <EventList $isMobile={isMobile}>
      <DrawerHeader>
        <EventListItem $centered={!isMobile}>
          <NeutralOutlinedButton
            onClick={() => setLocation(BACK_TO)}
            startIcon={<FontAwesomeIcon size="1x" icon={faChevronLeft} />}
          >
            <FormattedMessage id="return_to_events" />
          </NeutralOutlinedButton>
        </EventListItem>
        {isMobile && (
          <ExitButtonContainer>
            <NeutralOutlinedButton
              onClick={() => setDrawerOpen(false)}
            >
              <FontAwesomeIcon icon={faClose} size="xl" />
            </NeutralOutlinedButton>
          </ExitButtonContainer>
        )}
      </DrawerHeader>
      <EventListItem>
        <SideDrawerTitle>
          {event.name[language] || event.name[eventLanguage] || ''}
        </SideDrawerTitle>
      </EventListItem>
      <EventListItem>
        <HeaderLayout>
          <SideDrawerSubheader>
            <FontAwesomeIcon key={locale} icon={faCalendar} />
            {`${startDate} - ${endDate}`}
          </SideDrawerSubheader>
          <SideDrawerSubheader>
            <FontAwesomeIcon icon={faLocationDot} />
            {event.location || ''}
          </SideDrawerSubheader>
        </HeaderLayout>
      </EventListItem>
      <Grid item mt={1} mb={1}>
        <Divider />
      </Grid>
      <EventSideCollapse
        text="event_side_configuration"
        icon={faGear}
        isOpen
      >
        <EventSideButton
          indent={2}
          text="information"
          icon={faCircle1}
          url="/edit"
          isCompleted
          setDrawerOpen={setDrawerOpen}
        />
        <EventSideButton
          indent={2}
          text="waiting_lines"
          icon={faCircle2}
          url="/waitingLines"
          urlKeys={['waitingLine', 'waitingLines']}
          isCompleted={event.hasWaitingLines}
          setDrawerOpen={setDrawerOpen}
        />
        <EventSideButton
          indent={2}
          text="causes"
          icon={faCircle3}
          url="/causes"
          urlKeys={['causes']}
          isCompleted={Boolean(event.obnls && event.obnls.length > 0)}
          setDrawerOpen={setDrawerOpen}
        />
        <EventSideButton
          indent={2}
          text="publish"
          icon={faCircle4}
          url="/publish"
          isCompleted={event.status === EventStatus.Published}
          setDrawerOpen={setDrawerOpen}
        />
      </EventSideCollapse>
      <Grid item mt={1} mb={1}>
        <Divider />
      </Grid>
      <EventSideCollapse
        text="event_side_management"
        icon={faListCheck}
        isOpen
      >
        <EventSideButton
          indent={2}
          text="qr_reader"
          icon={faQrcode}
          url="/qrReader"
          isDisabled={event.status === EventStatus.Draft}
          setDrawerOpen={setDrawerOpen}
        />
        <EventSideButton
          indent={2}
          text="operations"
          icon={faMoneySimpleFromBracket}
          url="/operations"
          isDisabled={event.status === EventStatus.Draft}
          setDrawerOpen={setDrawerOpen}
        />
        <EnsureRole requiredRole="ADMIN">
          <EventSideButton
            indent={2}
            text="financial_report"
            icon={faFileChartPie}
            url="/financials"
            setDrawerOpen={setDrawerOpen}
            isDisabled={event.status === EventStatus.Draft}
          />
          <EventSideButton
            indent={2}
            text="users"
            icon={faUsers}
            url="/users"
            urlKeys={['users']}
            setDrawerOpen={setDrawerOpen}
          />
          <EventSideButton
            indent={2}
            text="theme"
            icon={faPaintBrush}
            url="/theme"
            urlKeys={['theme']}
            setDrawerOpen={setDrawerOpen}
            isDisabled={event.status !== EventStatus.Published}
            tooltipId={event.status !== EventStatus.Published ? 'event_publish_disabled' : undefined}
          />
        </EnsureRole>
      </EventSideCollapse>
      <EnsureRole requiredRole="ADMIN">
        <Grid item mt={1} mb={1}>
          <Divider />
        </Grid>
        <EventSideCollapse
          text="event_side_integrations"
          icon={faCode}
          isDisabled={event.status === EventStatus.Draft}
          urlKeys={['developers']}
        >
          <EventSideButton
            indent={2}
            text="webhooks"
            icon={faWebhook}
            url="/developers/webhooks"
            urlKeys={['webhooks']}
            setDrawerOpen={setDrawerOpen}
          />
        </EventSideCollapse>
      </EnsureRole>
    </EventList>
  );
}

export default EventSideView;
