import { Tab } from '@mui/material';
import Loader from 'components/Loading/Loader';
import PageLayout from 'components/PageLayout';
import { QrcodeSuccessCallback } from 'html5-qrcode';
import { TicketStatus, useGetTicketLazyQuery, useValidateTicketMutation } from 'lib/graphql/graphql';
import useIsMobile from 'lib/useIsMobile';
import { useCallback, useState } from 'react';

import useEventDocumentTitle from '../../useEventDocumentTitle';
import OperationsWaitingLineSelector from '../components/OperationsWaitingLineSelector';
import useOperationWaitingLine from '../useOperationsWaitingLine';
import CallButton from './components/CallButton';
import CalledTickets from './components/CalledTickets';
import NoShowTickets from './components/NoShowTickets';
import ScanAlert, { Alert } from './components/ScanAlert/ScanAlert';
import {
  HeaderContent,
  ResponsiveScanner,
  SmallTabs,
  StickyHeader,
} from './styles';

function QRReader() {
  useEventDocumentTitle('qr_reader');
  const isMobile = useIsMobile();

  const [waitingLineId, setWaitingLineId] = useOperationWaitingLine();
  const [alert, setAlert] = useState<Alert | undefined>();
  const [scanning, setScanning] = useState(false);
  const [ticketTab, setTicketTab] = useState<'Called' | 'NoShow'>('Called');

  const [validateTicket] = useValidateTicketMutation();
  const [getTicket] = useGetTicketLazyQuery({ fetchPolicy: 'network-only' });

  const checkTickets = useCallback(async (tickets: string[], ticketWaitingLineId: string) => {
    try {
      const fetchedTickets = await Promise.all(
        tickets.map((id: string) => getTicket({
          variables: { waitingLineId: ticketWaitingLineId, ticketId: id },
        }).then((res) => res.data?.getTicket)),
      );

      if (fetchedTickets.some((ticket) => !ticket)) {
        setAlert({ status: 'ERR_NOT_FOUND' });
        return false;
      }

      const validatedTickets = fetchedTickets.filter((ticket) => (
        ticket?.status === TicketStatus.Validated
      ));
      const invalidTickets = fetchedTickets.filter((ticket) => (
        ticket?.status === TicketStatus.Activated
      ));
      if (validatedTickets.length) {
        if (validatedTickets.length === fetchedTickets.length) {
          setAlert({ status: 'ERR_ALREADY_USED' });
          return false;
        }
        setAlert({
          remainingTickets: invalidTickets.length,
          status: 'ERR_VALIDATION_MIXED',
        });
        return false;
      }

      return true;
    } catch (err) {
      console.error(err);
      setAlert({ status: 'ERR_UNKNOWN' });
      return false;
    }
  }, [getTicket]);

  const validateTickets = useCallback(async (tickets: string[], ticketWaitingLineId: string) => {
    const success = await checkTickets(tickets, ticketWaitingLineId);
    if (!success) return;

    const results = await Promise.allSettled(tickets.map(async (ticketId: string) => {
      await validateTicket({
        variables: {
          waitingLineId: ticketWaitingLineId,
          ticketId,
        },
      });
    }));
    const error = results.find((r) => r.status === 'rejected');
    if (error && error.status === 'rejected') {
      console.error(error.reason);
      switch (error.reason?.message) {
        case 'Ticket already used':
          setAlert({ status: 'ERR_ALREADY_USED' });
          break;
        case 'Invalid ticket':
          setAlert({ status: 'ERR_UNKNOWN' });
          break;
        case 'Ticket not found':
          setAlert({ status: 'ERR_NOT_FOUND' });
          break;
        case 'Ticket has not been called yet':
          setAlert({ status: 'WARN_NOT_CALLED' });
          break;
        case 'Premium ticket has not been called yet':
          setAlert({ status: 'WARN_NOT_CALLED_PREMIUM' });
          break;
        default:
          setAlert({ status: 'ERR_UNKNOWN' });
      }
    }
    setAlert({
      status: 'SUCCESS',
      ticketIds: tickets,
    });
  }, [checkTickets, validateTicket]);

  const onScan = useCallback<QrcodeSuccessCallback>(async (decodedText) => {
    setScanning(true);
    try {
      const { waitingLineId: ticketWaitingLineId, ids } = JSON.parse(decodedText);
      if (ticketWaitingLineId === waitingLineId) {
        await validateTickets(ids, ticketWaitingLineId);
      } else {
        setAlert({
          status: 'WARN_INVALID_WAITING_LINE',
          ticketIds: ids,
          waitingLineId: ticketWaitingLineId,
        });
      }
    } catch (err) {
      console.error(err);
      setAlert({ status: 'ERR_UNKNOWN' });
    }
    setScanning(false);
  }, [validateTickets, waitingLineId]);

  return (
    <PageLayout>
      <StickyHeader $isMobile={isMobile}>
        <ResponsiveScanner
          $isMobile={isMobile}
          onScan={onScan}
          paused={scanning || Boolean(alert)}
        />
        <HeaderContent>
          <OperationsWaitingLineSelector
            waitingLineId={waitingLineId}
            setWaitingLineId={setWaitingLineId}
          />
          <CallButton waitingLineId={waitingLineId} />
        </HeaderContent>
        <SmallTabs
          value={ticketTab}
          onChange={(_, value) => setTicketTab(value)}
          variant="fullWidth"
        >
          <Tab value="Called" label="Called" />
          <Tab value="NoShow" label="NoShow" />
        </SmallTabs>
      </StickyHeader>
      <Loader isLoading={!waitingLineId}>
        {ticketTab === 'Called' && (
          <CalledTickets waitingLineId={waitingLineId!} />
        )}
        {ticketTab === 'NoShow' && (
          <NoShowTickets waitingLineId={waitingLineId!} />
        )}
      </Loader>
      <ScanAlert
        alert={alert}
        onClose={() => setAlert(undefined)}
        validateTickets={validateTickets}
      />
    </PageLayout>
  );
}

export default QRReader;
