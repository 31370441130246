import { Pagination } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import styled from 'styled-components';

export const BorderlessFooterDataGrid = styled(DataGrid)`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};

  & .${gridClasses['container--top']} {
    position: sticky;
    top: 0;
  }

  & .${gridClasses.row}, .${gridClasses.columnHeaders}, .${gridClasses.overlay} {
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
  }

  & .${gridClasses.footerContainer} {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
  }

  & .${gridClasses.cell}, .${gridClasses.columnHeader} {
    &:focus, &:focus-within {
      outline: none;
    }
  }

  @media (width <= 600px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    & .${gridClasses.footerContainer} {
      border: none;
      border-top: 1px solid ${({ theme }) => theme.palette.divider};
      justify-content: center;
    }
  }
`;

export const WaitingLineListPagination = styled(Pagination)`
  margin-top: 2.5rem;
  padding-right: 1rem;

  @media (width <= 600px) {
    margin-top: 1rem;
    padding-right: 0;
  }
`;
