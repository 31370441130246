import { Typography } from '@mui/material';
import { WaitingLineStatus } from 'lib/graphql/graphql';
import { FormattedMessage } from 'react-intl';

import { StatusContainer, StatusLabel } from '../styles';
import statusColor from './WaitingLineStatusSelector/statusColor';

interface WaitingLineStatusChipProps {
  status: WaitingLineStatus
  hideTitle?: boolean
}

function WaitingLineStatusChip(props: WaitingLineStatusChipProps) {
  const { status, hideTitle } = props;

  return (
    <StatusContainer>
      {!hideTitle && (
        <Typography color="textSecondary">
          <FormattedMessage id="status" />
        </Typography>
      )}
      <StatusLabel color={statusColor[status]}>
        <FormattedMessage id={`waiting_line_status_${status}`} />
      </StatusLabel>
    </StatusContainer>
  );
}

export default WaitingLineStatusChip;
