import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { styled } from 'styled-components';

export const FullWidthList = styled(List)`
  width: 100%;
`;

interface BorderProps {
  enableBorder?: boolean;
  $borderColor?: string;
  $borderSize?: string;
  $borderStyle?: string;
}

export interface BorderedProps {
  $borderTop?: BorderProps;
  $borderBottom?: BorderProps;
  $borderLeft?: BorderProps;
  $borderRight?: BorderProps;
}

export interface ColoredProps {
  $color?: string;
}

export const BorderedListItem = styled(ListItem)<BorderedProps>`
  border-top: ${({ $borderTop, theme }) => $borderTop && $borderTop.enableBorder && `${$borderTop.$borderSize || '1px'} ${$borderTop.$borderStyle || 'solid'} ${$borderTop.$borderColor || theme.palette.divider}`};
  border-bottom: ${({ $borderBottom, theme }) => $borderBottom && $borderBottom.enableBorder && `${$borderBottom.$borderSize || '1px'} ${$borderBottom.$borderStyle || 'solid'} ${$borderBottom.$borderColor || theme.palette.divider}`};
  border-left: ${({ $borderLeft, theme }) => $borderLeft && $borderLeft.enableBorder && `${$borderLeft.$borderSize || '1px'} ${$borderLeft.$borderStyle || 'solid'} ${$borderLeft.$borderColor || theme.palette.divider}`};
  border-right: ${({ $borderRight, theme }) => $borderRight && $borderRight.enableBorder && `${$borderRight.$borderSize || '1px'} ${$borderRight.$borderStyle || 'solid'} ${$borderRight.$borderColor || theme.palette.divider}`};
`;

export const BorderedGrid = styled(Grid)<BorderedProps>`
  border-top: ${({ $borderTop, theme }) => $borderTop && $borderTop.enableBorder && `${$borderTop.$borderSize || '1px'} ${$borderTop.$borderStyle || 'solid'} ${$borderTop.$borderColor || theme.palette.divider}`};
  border-bottom: ${({ $borderBottom, theme }) => $borderBottom && $borderBottom.enableBorder && `${$borderBottom.$borderSize || '1px'} ${$borderBottom.$borderStyle || 'solid'} ${$borderBottom.$borderColor || theme.palette.divider}`};
  border-left: ${({ $borderLeft, theme }) => $borderLeft && $borderLeft.enableBorder && `${$borderLeft.$borderSize || '1px'} ${$borderLeft.$borderStyle || 'solid'} ${$borderLeft.$borderColor || theme.palette.divider}`};
  border-right: ${({ $borderRight, theme }) => $borderRight && $borderRight.enableBorder && `${$borderRight.$borderSize || '1px'} ${$borderRight.$borderStyle || 'solid'} ${$borderRight.$borderColor || theme.palette.divider}`};
`;

export const ActionList = styled(List)`
  padding: 0.5rem 0;
  margin: 0;
  min-width: 10rem;
  font-size: 0.1rem;
`;

export const ActionListItem = styled(ListItem)`
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
`;

export const ActionListItemButton = styled(ListItemButton)`
  padding: 0.2rem 0;
  width: 100%;
`;

export const ActionListItemText = styled(ListItemText)<ColoredProps>`
  font-size: 0.9rem;
  margin: 0 0.5rem;
  color: ${({ $color, theme }) => $color || theme.palette.text.primary};
`;

export const EmptyList = styled.div`
  width: 100%;
  display: flex;
  height: 20svh;
  border: 3px dashed ${({ theme }) => theme.palette.grey[300]};
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
`;
