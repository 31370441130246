import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faDoNotEnter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import PageLayout from 'components/PageLayout';
import { FormattedMessage } from 'react-intl';
import { Centered, NeutralOutlinedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

function RouteForbidden() {
  const [, setLocation] = useLocation();

  return (
    <PageLayout>
      <Centered>
        <Grid container justifyContent="center" textAlign="center" rowGap="16px">
          <Grid item xs={12}>
            <FontAwesomeIcon icon={faDoNotEnter} size="4x" />
          </Grid>
          <Grid item xs={12}>
            <Title>
              <FormattedMessage id="page_forbidden_title" />
            </Title>
          </Grid>
          <Grid item xs={12}>
            <FormattedMessage id="page_forbidden_description" />
          </Grid>
          <Grid item xs={12}>
            <NeutralOutlinedButton
              onClick={() => setLocation('~/')}
              startIcon={<FontAwesomeIcon size="1x" icon={faChevronLeft} />}
            >
              <FormattedMessage id="return_to_dashboard" />
            </NeutralOutlinedButton>
          </Grid>
        </Grid>
      </Centered>
    </PageLayout>
  );
}

export default RouteForbidden;
