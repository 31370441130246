import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const AlignRightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
`;

export const BackgroundlessIconButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

export const NoBorderButton = styled(BackgroundlessIconButton)`
  border: none;

  @media only screen and (width < 1000px) {
    margin-right: 0.5rem;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
