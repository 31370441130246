import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, IconButton, Menu } from '@mui/material';
import React, { useMemo } from 'react';

export interface Filter {
  label: React.ReactNode;
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export interface WaitingLinesFiltersProps {
  children: React.ReactNode;
}

export default function WaitingLinesFilters(props: WaitingLinesFiltersProps) {
  const { children } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const numberOfActiveFilters = useMemo(() => (
    React.Children.toArray(children).filter((filter) => (
      React.isValidElement(filter) && filter.props?.value
    )).length
  ), [children]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {children}
      </Menu>
      <IconButton onClick={handleMenu}>
        <Badge badgeContent={numberOfActiveFilters} color="primary">
          <FontAwesomeIcon icon={faBarsFilter} />
        </Badge>
      </IconButton>
    </>
  );
}
