import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider as MUIDivider,
  List,
  ListItem,
  ListItemProps,
  Typography,
} from '@mui/material';
import styled, { css } from 'styled-components';
import { NeutralOutlinedButton } from 'styles/styles';

const SideDrawerWidth = 335;

interface EventListProps {
  $isMobile: boolean;
}

interface ContentLayoutProps {
  $isMobile: boolean;
  $isDisabled?: boolean;
}

export const EventList = styled(List)<EventListProps>`
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.paper};
  position: fixed;
  overflow-y: auto;
  padding: 0 0.5rem;
  z-index: 5;
  width: ${SideDrawerWidth}px;
  height: ${({ theme }) => `calc(100% - ${theme.mixins.toolbar.minHeight}px)`};

  ${({ $isMobile }) => $isMobile && css`
    width: 100vw;
    height: 100%;
    margin-top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
  `}
`;

export const ContentLayout = styled.div<ContentLayoutProps>`
  ${({ $isMobile }) => !$isMobile && css`
    margin-left: ${SideDrawerWidth}px;
  `}
  width: 100%;

  ${({ $isDisabled }) => $isDisabled && `
    opacity: 0.6;
    pointer-events: none;
  `}
`;

interface EventListItemProps extends ListItemProps {
  $centered?: boolean;
}

export const EventListItem = styled(ListItem)<EventListItemProps>`
  justify-content: ${({ $centered }) => ($centered ? 'center' : 'flex-start')};
`;

export const HeaderLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const SideDrawerTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  text-wrap: wrap;
  font-weight: bold;
  word-break: break-all;
`;

export const SideDrawerSubheader = styled(Typography).attrs({
  variant: 'body2',
  color: 'textSecondary',
})`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Divider = styled(MUIDivider)`
  margin: 0.5rem;
`;

export const ExitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DrawerButton = styled(NeutralOutlinedButton)`
  min-width: unset;
  border-radius: 0 5px 5px 0;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #fff;
  padding: 0.2rem 0.75rem;
  position: absolute;
  top: 68px;
  left: -8px;
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

export const ChildrenContainer = styled.div`
  padding-top: 5.5rem;
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const DrawerIcon = styled(FontAwesomeIcon)`
  padding: 5px 0;
`;
