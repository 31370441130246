import AseAuthenticator from 'components/Authenticator/AseAuthenticator';
import StripeLandingPage from 'components/Stripe/StripeLandingPage';
import ProtectedRoute from 'lib/access/ProtectedRoute';
import { useHasOrganizationsQuery } from 'lib/graphql/graphql';
import Dashboard from 'modules/Dashboard/Dashboard';
import Webhook from 'modules/Events/Developers/Webhooks/Webhook/Webhook';
import CreateWebhook from 'modules/Events/Developers/Webhooks/WebhookForm/CreateWebhook';
import UpdateWebhook from 'modules/Events/Developers/Webhooks/WebhookForm/UpdateWebhook';
import Webhooks from 'modules/Events/Developers/Webhooks/Webhooks';
import EventCauses from 'modules/Events/EventCauses/EventCauses';
import EventCausesSelection from 'modules/Events/EventCauses/EventCausesSelection';
import EventFinancialReport from 'modules/Events/EventFinancialReport/EventFinancialReport';
import CreateEvent from 'modules/Events/EventForm/CreateEvent';
import UpdateEvent from 'modules/Events/EventForm/UpdateEvent';
import EventPublish from 'modules/Events/EventPublish/EventPublish';
import SideViewLayout from 'modules/Events/EventSideView/SideViewLayout';
import EventTheme from 'modules/Events/EventTheme/EventTheme';
import EventUsers from 'modules/Events/EventUsers/EventUsers';
import InviteUser from 'modules/Events/EventUsers/InvitationForm/InvitationForm';
import EventWaitingLines from 'modules/Events/EventWaitingLines/EventWaitingLines';
import CreateWaitingLine from 'modules/Events/EventWaitingLines/WaitingLineForm/CreateWaitingLine';
import UpdateWaitingLine from 'modules/Events/EventWaitingLines/WaitingLineForm/UpdateWaitingLine';
import AdvancedOperations from 'modules/Events/Operations/AdvancedOperations';
import QRReader from 'modules/Events/Operations/QRReader';
import UpdateOrganization from 'modules/Organizations/OrganizationForm/UpdateOrganization';
import OrganizationSelector from 'modules/OrganizationSelector/OrganizationSelector';
import EventPayouts from 'modules/Payouts/Payouts';
import Profile from 'modules/Profile/Profile';
import EventProvider from 'providers/event/EventProvider';
import useOrganization from 'providers/organization/useOrganization';
import WaitingLineProvider from 'providers/waitingLine/WaitingLineProvider';
import RouteNotFound from 'routes/RouteNotFound';
import { Route, Switch } from 'wouter';

export default function OrganizationSwitch() {
  const { organizationId } = useOrganization();

  const { data } = useHasOrganizationsQuery();
  const hasOrganizations = Boolean(data?.hasOrganizations);

  return (
    <AseAuthenticator>
      <Switch>
        {hasOrganizations && (
          <Route path="/profile" component={Profile} />
        )}
        <ProtectedRoute requiredRole="ADMIN" path="/organization/edit" component={UpdateOrganization} />
        <Route path="/linked" component={StripeLandingPage} />
        {organizationId ? (
          <>
            <ProtectedRoute requiredRole="STAFF" path="/" component={Dashboard} />
            <ProtectedRoute requiredRole="ADMIN" path="/events/create" component={CreateEvent} />
            <ProtectedRoute requiredRole="ADMIN" path="/payouts" component={EventPayouts} />
            <ProtectedRoute requiredRole="STAFF" path="/event/:eventId" nest>
              <EventProvider>
                <SideViewLayout>
                  <Switch>
                    <ProtectedRoute requiredRole="STAFF" path="/edit" component={UpdateEvent} />
                    <ProtectedRoute requiredRole="STAFF" path="/waitingLines" component={EventWaitingLines} />
                    <ProtectedRoute requiredRole="ADMIN" path="/waitingLines/create" component={CreateWaitingLine} />
                    <ProtectedRoute requiredRole="STAFF" path="/waitingLine/:waitingLineId" nest>
                      <WaitingLineProvider>
                        <ProtectedRoute requiredRole="STAFF" path="/edit" component={UpdateWaitingLine} />
                      </WaitingLineProvider>
                    </ProtectedRoute>
                    <ProtectedRoute requiredRole="STAFF" path="/qrReader" component={QRReader} />
                    <ProtectedRoute requiredRole="STAFF" path="/operations" component={AdvancedOperations} />
                    <ProtectedRoute requiredRole="STAFF" path="/causes" component={EventCauses} />
                    <ProtectedRoute requiredRole="ADMIN" path="/causes/selection" component={EventCausesSelection} />
                    <ProtectedRoute requiredRole="ADMIN" path="/users" component={EventUsers} />
                    <ProtectedRoute requiredRole="ADMIN" path="/users/invite" component={InviteUser} />
                    <ProtectedRoute requiredRole="STAFF" path="/publish" component={EventPublish} />
                    <ProtectedRoute requiredRole="ADMIN" path="/financials" component={EventFinancialReport} />
                    <ProtectedRoute requiredRole="STAFF" path="/theme" component={EventTheme} />
                    <ProtectedRoute requiredRole="ADMIN" path="/developers" nest>
                      <ProtectedRoute requiredRole="ADMIN" path="/webhooks" nest>
                        <ProtectedRoute requiredRole="ADMIN" path="/" component={Webhooks} />
                        <ProtectedRoute requiredRole="ADMIN" path="/create" component={CreateWebhook} />
                        <ProtectedRoute requiredRole="ADMIN" path="/:webhookId" component={Webhook} />
                        <ProtectedRoute requiredRole="ADMIN" path="/:webhookId/edit" component={UpdateWebhook} />
                      </ProtectedRoute>
                    </ProtectedRoute>
                    <Route component={RouteNotFound} />
                  </Switch>
                </SideViewLayout>
              </EventProvider>
            </ProtectedRoute>
            <Route component={RouteNotFound} />
          </>
        ) : (
          <Route component={OrganizationSelector} />
        )}
      </Switch>
    </AseAuthenticator>
  );
}
