import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import Search from 'components/Search';
import useValidateRole from 'lib/access/useValidateRole';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { useDeleteWaitingLineMutation, useGetAllEventWaitingLinesLazyQuery, WaitingLineStatus } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import WaitingLineCard from 'modules/Events/EventWaitingLines/WaitingLineCard';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import {
  BaseSyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Content } from 'styles/styles';
import { useLocation } from 'wouter';

function WaitingLinesList() {
  const event = useEvent();
  const feedback = useFeedback();
  const [, setLocation] = useLocation();

  const [deleteWaitingLine, { loading: performingDelete }] = useDeleteWaitingLineMutation();
  const [getAllWaitingLinesQuery] = useGetAllEventWaitingLinesLazyQuery();

  const [searchValue, setSearchValue] = useState('');
  const [onlyShowActive, setOnlyShowActive] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [waitingLineId, setWaitingLineId] = useState<string | undefined>();

  const { language } = useContext(LanguageContext);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['waitingLines'],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllWaitingLinesQuery({
        fetchPolicy: 'network-only',
        variables: { eventId: event.id, start: pageParam },
      });
      return result.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage?.getAllEventWaitingLines?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const waitingLines = useMemo(() => {
    if (data && !isLoading) {
      if (!data.pages[0]?.getAllEventWaitingLines) {
        return [];
      }
      const tempWaitingLines = data?.pages.flatMap(
        (page) => page?.getAllEventWaitingLines.waitingLines || [],
      ) || [];
      return tempWaitingLines;
    }
    return [];
  }, [isLoading, data]);

  const validateRole = useValidateRole();

  useEffect(() => {
    if (data?.pages[0]?.getAllEventWaitingLines && !isLoading) {
      if (waitingLines.length === 0 && validateRole('ADMIN')) {
        setLocation('/waitingLines/create');
      }
    }
  });

  const filters = useMemo(() => (
    (waitingLine: EventWaitingLine) => (
      (waitingLine.name[language] || waitingLine.name[event.languages[0] as Languages] || '').toLowerCase().includes(searchValue.toLowerCase())
      && (!onlyShowActive || waitingLine.status === WaitingLineStatus.Active)
    )
  ), [language, searchValue, onlyShowActive, event.languages]);

  const shownWaitingLines = useMemo(() => {
    if (waitingLines) {
      return waitingLines.filter(filters);
    }
    return [];
  }, [filters, waitingLines]);

  const onSelectDelete = (waitingLine: string) => {
    setModalOpen(true);
    setWaitingLineId(waitingLine);
  };

  const onDelete = async () => {
    if (!waitingLineId) return;
    try {
      await deleteWaitingLine({ variables: { eventId: event.id, id: waitingLineId } });
      refetch();
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_waiting_line_success" />),
        severity: 'success',
      });
    } catch (err) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: <span>{(err as Error).message}</span>,
        severity: 'error',
      });
    }
    setWaitingLineId('');
    setModalOpen(false);
  };

  if (isLoading) return <Content><CenteredSpinner /></Content>;
  if (error) return <div>TODO</div>;

  const eventLanguage = event.languages[0] as Languages;

  return (
    <>
      <Grid container display="flex" justifyContent="flex-end" gap={2}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={onlyShowActive}
              onChange={(e: BaseSyntheticEvent) => setOnlyShowActive(e.target.checked)}
            />
          )}
          label={<FormattedMessage id="waiting_lines_only_active" />}
        />
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </Grid>
      <Grid container justifyContent="flex-end" alignItems="center" gap={1}>
        <Grid container direction="column" gap={2} marginTop="1rem">
          {shownWaitingLines.map((waitingLine) => (
            <WaitingLineCard
              key={waitingLine.id}
              title={waitingLine.name[language] || waitingLine.name[eventLanguage] || ''}
              type={waitingLine.waitingLineType}
              status={waitingLine.status}
              waitingLineId={waitingLine.id}
              isFreemium={waitingLine.isFreemium}
              paidTickets={waitingLine.paidTickets}
              activatedTickets={waitingLine.activatedTickets}
              totalTickets={waitingLine.quantity}
              onDelete={() => onSelectDelete(waitingLine.id)}
            />
          ))}
        </Grid>
      </Grid>
      <ConfirmModal
        title="delete_confirm"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => onDelete()}
        loading={performingDelete}
        closeText="cancel"
        confirmColor="error"
        confirmText="delete"
      >
        <FormattedMessage id="delete_irreversible" />
      </ConfirmModal>
    </>
  );
}

export default WaitingLinesList;
