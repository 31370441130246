import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetOrganizationItem } from 'lib/graphql/entities/organization/types';
import { useGetOrganizationStripeDashboardLinkLazyQuery } from 'lib/graphql/graphql';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedLoadingButton } from 'styles/styles';

interface StripeDashboardButtonProps {
  organization: GetOrganizationItem | null | undefined;
}

export default function StripeDashboardButton(props: StripeDashboardButtonProps) {
  const { organization } = props;
  const [createDashboardLink] = useGetOrganizationStripeDashboardLinkLazyQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const handleClick = useCallback(async () => {
    if (organization && organization.stripeAccountId && organization.stripeAccountLinked) {
      setLoading(true);
      const { data } = await createDashboardLink({
        variables: {
          stripeAccountId: organization.stripeAccountId,
        },
        fetchPolicy: 'network-only',
      });
      if (data && data.getOrganizationStripeDashboardLink) {
        window.open(data.getOrganizationStripeDashboardLink, '_blank');
      }
      setLoading(false);
    }
  }, [organization, createDashboardLink]);

  return (
    <ContainedLoadingButton
      loading={loading}
      onClick={handleClick}
      disabled={loading}
      color="secondary"
      endIcon={<FontAwesomeIcon icon={faExternalLink} />}
    >
      <FormattedMessage id={organization?.stripeAccountLinked ? 'stripe_dashboard' : 'stripe_not_linked'} />
    </ContainedLoadingButton>
  );
}
