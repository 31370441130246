import { faArrowRight, faQuestionSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import PageLayout from 'components/PageLayout';
import * as htmlToImage from 'html-to-image';
import EnsureRole from 'lib/access/EnsureRole';
import useValidateRole from 'lib/access/useValidateRole';
import cloudinaryClient from 'lib/cloudinary/CloudinaryClient';
import { useChangeEventVisibilityMutation } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import settings from 'lib/settings';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContainedButton, OutlinedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import {
  ClickableStep,
  Details,
  DetailsAddress,
  DetailsEventTitle,
  EventDate,
  EventImage,
  EventLink,
  ImageContainer,
  NextContainer,
  NextIcon,
  PublishedDiv,
  QR,
  QRCodeContainer,
  QRContainer,
  Step,
  StepInstructions,
  StepTitle,
  UnpublishText,
  WhatsNext,
  WhatsNextText,
} from './styles';

function EventPublished() {
  const event = useEvent();
  const intl = useIntl();
  const theme = useTheme();
  const qrRef = useRef<HTMLDivElement>(null);
  const { locale } = intl;
  const { language } = useContext(LanguageContext);
  const feedback = useFeedback();
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const [, setLocation] = useLocation();

  const [changeEventVisibility, { loading }] = useChangeEventVisibilityMutation();

  const eventLanguage = event.languages[0] as Languages;

  const [startDate, endDate] = useMemo(() => {
    const start = event.startTime.setLocale(locale).toFormat('DDD');
    const end = event.endTime.setLocale(locale).toFormat('DDD');
    return [start, end];
  }, [event, locale]);

  const eventImage = useMemo(() => cloudinaryClient.image(event.image as string), [event.image]);

  const validateRole = useValidateRole();

  const handleUnpublish = useCallback(async () => {
    if (!validateRole('ADMIN')) return;

    try {
      await changeEventVisibility({ variables: { id: event.id } });
      queryClient.invalidateQueries({ queryKey: ['events'], exact: true });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="change_visibility_success" />),
        severity: 'success',
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="change_visibility_error" />),
        severity: 'error',
      });
    }
    setModalOpen(false);
  }, [changeEventVisibility, event.id, feedback, queryClient, validateRole]);

  const downloadQRCode = useCallback(async () => {
    try {
      const dataUrl = await htmlToImage.toPng(qrRef.current as HTMLElement);

      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qr-code.png';
      link.click();

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="event_qr_downloaded" />),
        severity: 'success',
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="event_qr_download_error" />),
        severity: 'error',
      });
    }
  }, [feedback]);

  const eventLink = useMemo(() => event.shortUrl ?? `${settings.appUrl}/event/${event.id}`, [event.id, event.shortUrl]);

  const onClickLink = useCallback(() => {
    try {
      navigator.clipboard.writeText(eventLink);

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="event_qr_copied" />),
        severity: 'success',
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="event_qr_copy_error" />),
        severity: 'error',
      });
    }
  }, [eventLink, feedback]);

  return (
    <>
      <PageLayout>
        <Grid container direction="column" height="100%">
          <Grid item>
            <Title>
              <FormattedMessage id="event_published" />
            </Title>
          </Grid>
          <Grid container padding="2rem 0">
            <Grid item lg={6} xs={12} direction="column">
              <PublishedDiv>
                <Grid container>
                  <Grid item xs={12}>
                    <ImageContainer>
                      <EventImage cldImg={eventImage} />
                    </ImageContainer>
                  </Grid>
                  <Grid item xs={12}>
                    <Details>
                      <DetailsEventTitle>
                        {event.name[language] || event.name[eventLanguage] || ''}
                      </DetailsEventTitle>
                      <DetailsAddress>
                        {event.location}
                      </DetailsAddress>
                      <EventDate>
                        {`${intl.formatMessage({ id: 'event_starts' })} ${startDate} - ${intl.formatMessage({ id: 'event_ends' })} ${endDate}`}
                      </EventDate>
                    </Details>
                  </Grid>
                  <EnsureRole requiredRole="ADMIN">
                    <Grid item xs={12} padding="1.5rem">
                      <OutlinedButton color="secondary" onClick={() => setModalOpen(true)}>
                        <UnpublishText>
                          <FormattedMessage id="event_unpublish" />
                        </UnpublishText>
                      </OutlinedButton>
                    </Grid>
                  </EnsureRole>
                </Grid>
              </PublishedDiv>
            </Grid>
            <Grid item lg={6} xs={12} direction="column" padding="0 3rem">
              <Grid container>
                <Grid item xs={12} margin="0 0 1rem">
                  <NextContainer>
                    <WhatsNext>
                      <FontAwesomeIcon icon={faQuestionSquare} color={theme.palette.secondary.main} size="2x" />
                      <WhatsNextText>
                        <FormattedMessage id="event_publish_next" />
                      </WhatsNextText>
                    </WhatsNext>
                  </NextContainer>
                </Grid>
                <EnsureRole requiredRole="ADMIN">
                  <Grid item xs={12}>
                    <ClickableStep onClick={() => setLocation('/users')}>
                      <StepInstructions>
                        <FormattedMessage id="event_publish_invite" />
                        <NextIcon icon={faArrowRight} color={theme.palette.secondary.main} size="xl" />
                      </StepInstructions>
                    </ClickableStep>
                  </Grid>
                </EnsureRole>
                <Grid item xs={12}>
                  <ClickableStep onClick={() => setLocation('/operations?tab=qr')}>
                    <StepInstructions>
                      <FormattedMessage id="event_publish_operate" />
                      <NextIcon icon={faArrowRight} color={theme.palette.secondary.main} size="xl" />
                    </StepInstructions>
                  </ClickableStep>
                </Grid>
                <Grid item xs={12}>
                  <ClickableStep onClick={() => setLocation('/operations?tab=operations')}>
                    <StepInstructions>
                      <FormattedMessage id="event_publish_set_display" />
                      <NextIcon icon={faArrowRight} color={theme.palette.secondary.main} size="xl" />
                    </StepInstructions>
                  </ClickableStep>
                </Grid>
                <Grid item xs={12} direction="column">
                  <Step>
                    <StepTitle>
                      <FormattedMessage id="event_share_link" />
                    </StepTitle>
                    <QRContainer>
                      <QRCodeContainer ref={qrRef}>
                        <QR value={eventLink} />
                      </QRCodeContainer>
                      <ContainedButton onClick={downloadQRCode} color="secondary">
                        <FormattedMessage id="event_qr_download" />
                      </ContainedButton>
                    </QRContainer>
                    <EventLink onClick={onClickLink}>{eventLink}</EventLink>
                  </Step>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          { /* <EventMediaKit /> */ }
        </Grid>
      </PageLayout>
      <ConfirmModal
        title="change_visibility_confirm_hidden"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => handleUnpublish()}
        closeText="cancel"
        confirmColor="secondary"
        confirmText="general_yes"
        loading={loading}
      >
        <FormattedMessage id="change_visibility_to_hidden" />
      </ConfirmModal>
    </>
  );
}

export default EventPublished;
