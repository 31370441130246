import { CardActions, CardContent, CardHeader } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import { GetAllObnlsByCauseItem, GetAllObnlsItem } from 'lib/graphql/entities/obnl/types';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, OutlinedButton } from 'styles/styles';

import CauseOrganizationCard from './CauseOrganizationCard';

interface CauseObnlDialogProps {
  obnl: GetAllObnlsItem | GetAllObnlsByCauseItem;
  dialogOpen: boolean;
  onAddMore?: () => void
  onClose: () => void
  canAddMore?: boolean;
}

function CauseObnlDialog(props: CauseObnlDialogProps) {
  const {
    obnl,
    dialogOpen,
    canAddMore = true,
    onAddMore,
    onClose,
  } = props;

  const event = useEvent();
  const { language } = useContext(LanguageContext);

  const eventLanguage = event.languages?.[0] as Languages;
  const description = obnl.description?.[language] || obnl.description?.[eventLanguage] || '';

  return (
    <CustomDialog open={dialogOpen}>
      {obnl && (
      <>
        <CardHeader
          title={<FormattedMessage id="causes_obnl_dialog_title" />}
          subheader={<FormattedMessage id="causes_obnl_dialog_subheader" />}
        />
        <CardContent>
          <CauseOrganizationCard
            key={obnl.id}
            logo={obnl.logo ?? undefined}
            name={obnl.name}
            description={description}
          />
        </CardContent>
        {canAddMore && (
          <>
            <CardContent sx={{ textAlign: 'center' }}>
              <FormattedMessage id="causes_obnl_dialog_add_another" />
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <OutlinedButton
                color="secondary"
                onClick={onClose}
              >
                <FormattedMessage id="general_no" />
              </OutlinedButton>
              <ContainedButton
                color="secondary"
                onClick={onAddMore}
              >
                <FormattedMessage id="general_yes" />
              </ContainedButton>
            </CardActions>
          </>
        )}
        {!canAddMore && (
          <CardActions sx={{ justifyContent: 'center' }}>
            <ContainedButton
              color="secondary"
              onClick={onClose}
            >
              <FormattedMessage id="general_close" />
            </ContainedButton>
          </CardActions>
        )}
      </>
      )}
    </CustomDialog>
  );
}

export default CauseObnlDialog;
