import EventLocalizedText from 'components/EventLocalizedText';
import { useGetWaitingLineQuery } from 'lib/graphql/graphql';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'styled-components';
import { ContainedButton } from 'styles/styles';

import { OperatingStatusCode, operatingStatusCodesMapping } from '../../statusCode';
import {
  AlertButtonsContainer,
  AlertContainer,
  ContentContainer,
  Icon,
  IconContainer,
  ItemPassInfo,
  LeftInWaitingLine,
  LinearCountdown,
  QRAlertTitle,
  QRAlertTitleContainer,
  TickerNumber,
  WaitingLineName,
  ZeroSpaceContainer,
} from './styles';

export interface Alert {
  status: OperatingStatusCode,
  ticketIds?: string[],
  waitingLineId?: string
  remainingTickets?: number | 'unknown',
}

interface ScanAlertProps {
  alert?: Alert
  onClose: () => void
  validateTickets: (tickets: string[], waitingLineId: string) => void
}

function ScanAlert(props: ScanAlertProps) {
  const { alert, onClose, validateTickets } = props;
  const {
    remainingTickets,
    status,
    ticketIds,
    waitingLineId,
  } = alert || {};

  const theme = useTheme();

  const { data, loading, refetch } = useGetWaitingLineQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: waitingLineId! },
    skip: !waitingLineId,
  });
  const waitingLine = data?.getWaitingLine;

  const codeInfo = status ? operatingStatusCodesMapping[status] : undefined;

  useEffect(() => {
    if (alert) refetch();
  }, [alert, refetch]);

  useEffect(() => {
    if (codeInfo) {
      const timerInterval = setTimeout(onClose, codeInfo.duration * 1000);

      return () => clearTimeout(timerInterval);
    }
    return undefined;
  }, [codeInfo, onClose]);

  const revalidate = useCallback(() => {
    if (ticketIds && waitingLineId) {
      validateTickets(ticketIds, waitingLineId);
    }
  }, [ticketIds, validateTickets, waitingLineId]);

  const statusColor: { [key: string]: string } = useMemo(() => ({
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    warning: theme.palette.text.secondary,
  }), [theme]);

  if (loading || !status || !codeInfo) return null;

  const color = statusColor[codeInfo.severity];

  return (
    <AlertContainer open={Boolean(status)}>
      <ZeroSpaceContainer>
        <IconContainer $color={color}>
          <Icon icon={codeInfo.icon} size="xl" />
        </IconContainer>
      </ZeroSpaceContainer>
      <ContentContainer>
        <QRAlertTitleContainer>
          <QRAlertTitle $color={color}>
            <FormattedMessage id={codeInfo.title} />
          </QRAlertTitle>
          {codeInfo.severity === 'success' && waitingLine && (
            <WaitingLineName>
              <EventLocalizedText text={waitingLine.name} />
            </WaitingLineName>
          )}
        </QRAlertTitleContainer>
        {codeInfo.severity === 'success' ? (
          <ItemPassInfo>
            <div>
              <FormattedMessage id="qr_reader_scanned_passes" values={{ nbr: ticketIds?.length || 0 }} />
            </div>
            <LeftInWaitingLine label={<FormattedMessage id="qr_reader_pass_left" values={{ nbr: remainingTickets }} />} />
          </ItemPassInfo>
        ) : (
          <span>
            <FormattedMessage
              id={codeInfo.message}
              values={
                {
                  number: (
                    <TickerNumber>
                      {ticketIds?.length || 0}
                    </TickerNumber>
                  ),
                  count: ticketIds?.length || 0,
                }
              }
            />
          </span>
        )}
        {codeInfo.severity === 'warning' && (
          <>
            {status === 'WARN_INVALID_WAITING_LINE' && (
              <AlertButtonsContainer>
                <ContainedButton color="error" onClick={onClose} fullWidth>
                  <FormattedMessage id="general_no" />
                </ContainedButton>
                <ContainedButton color="secondary" onClick={revalidate} fullWidth>
                  <FormattedMessage id="general_yes" />
                </ContainedButton>
              </AlertButtonsContainer>
            )}
            {status === 'ERR_VALIDATION_MIXED' && (
              <AlertButtonsContainer>
                <ContainedButton color="error" onClick={onClose} fullWidth>
                  <FormattedMessage id="general_no" />
                </ContainedButton>
                <ContainedButton color="secondary" onClick={revalidate} fullWidth>
                  <FormattedMessage id="general_yes" />
                </ContainedButton>
              </AlertButtonsContainer>
            )}
          </>
        )}
      </ContentContainer>
      {codeInfo.duration && (
        <LinearCountdown key={status} $duration={codeInfo.duration} $color={color} />
      )}
    </AlertContainer>
  );
}

export default ScanAlert;
