import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  listItemTextClasses,
} from '@mui/material';
import { SparkLineChart } from '@mui/x-charts';
import { styled } from 'styled-components';

export const ListHeaderText = styled(ListItemText)`
  
  text-transform: uppercase;

  & .${listItemTextClasses.primary} {
    font-size: 0.9rem;
    font-weight: 400;
  }
`;

export const UrlListItemButton = styled(ListItemButton)`
  padding: 0;
`;

export const UrlListItemIcon = styled(ListItemIcon)`
  padding: 0;
  margin-right: 0.5rem;
  min-width: 0;
`;

export const GraphDiv = styled.div`
  height: 1.5rem;
  position: relative;
`;

export const GraphLine = styled(SparkLineChart)`
  position: absolute;
`;
