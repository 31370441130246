import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import useFeedback from 'components/Feedback/useFeedback';
import PageLayout from 'components/PageLayout';
import EnsureRole from 'lib/access/EnsureRole';
import useValidateRole from 'lib/access/useValidateRole';
import {
  EventStatus,
  useGetOrganizationQuery,
  usePublishEventMutation,
} from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import settings from 'lib/settings';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import React, { useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ContainedButton,
  NeutralOutlinedButton,
  Separator,
  StickySteps,
  StickyStepsContent,
  TextButton,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import CheckDot from './components/CheckDot';
import EventPublished from './EventPublished';
import {
  EventAddress,
  EventDate,
  EventTitle,
  InformationContainer,
  PublishingContainer,
  PublishProgress,
  ReviewContainer,
  StepContainer,
} from './styles';

interface Progress {
  percentage: number;
  completedSteps: number;
  incompleteSteps: number;
}

function mailto(chunks: React.ReactNode) {
  return (
    <TextButton color="secondary" href={`mailto:${chunks}`}>{chunks}</TextButton>
  );
}

function EventPublish() {
  useEventDocumentTitle('publish');
  const event = useEvent();
  const intl = useIntl();
  const { locale } = intl;
  const { language } = useContext(LanguageContext);
  const feedback = useFeedback();

  const [, setLocation] = useLocation();

  const [publishEvent] = usePublishEventMutation();

  const { obnls } = event;

  const validateRole = useValidateRole();

  const { data: orgData } = useGetOrganizationQuery();
  const organization = orgData?.getOrganization;
  // TODO use the same name as EventSideView for document titles

  const [startDate, endDate] = useMemo(() => {
    const start = event.startTime.setLocale(locale).toFormat('DDD');
    const end = event.endTime.setLocale(locale).toFormat('DDD');
    return [start, end];
  }, [event, locale]);

  function getCompletion(steps: any[]): Progress {
    if (!steps.length) {
      return {
        percentage: 0,
        completedSteps: 0,
        incompleteSteps: 0,
      };
    }
    const completedSteps = steps.filter(Boolean);
    return {
      percentage: Math.floor((completedSteps.length / steps.length) * 100),
      completedSteps: completedSteps.length,
      incompleteSteps: steps.length - completedSteps.length,
    };
  }

  const progress = useMemo<Progress>(() => {
    // TODO Add support step when done
    const steps = [
      event.name,
      event.hasWaitingLines,
      obnls && obnls.length > 0,
      organization?.stripeAccountLinked,
    ];

    if (settings.requireMovonValidationForPublish) {
      steps.push(organization?.validatedByMovon);
    }

    return getCompletion(steps);
  }, [
    event.name,
    event.hasWaitingLines,
    obnls,
    organization?.stripeAccountLinked,
    organization?.validatedByMovon,
  ]);

  const onPublish = async () => {
    if (!validateRole('ADMIN')) return;

    try {
      await publishEvent({
        variables: {
          id: event.id!,
        },
      });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="publish_event_success" />),
        severity: 'success',
      });
    } catch (error) {
      let errorMessage = 'update_event_error';

      if (error instanceof Error) {
        switch (true) {
          case error.message.includes('You do not have permission'):
            errorMessage = 'general_permission_error';
            break;
          case error.message.includes('You must link a Stripe account'):
            errorMessage = 'stripe_account_not_linked_error';
            break;
          case error.message.includes('You must create at least one waiting line'):
            errorMessage = 'event_publish_no_waiting_lines';
            break;
          case error.message.includes('You must support at least one cause'):
            errorMessage = 'event_publish_no_causes';
            break;
          case error.message.includes('Your organization is not validated by MovOn'):
            errorMessage = 'event_publish_not_validated_by_movon';
            break;
          // TODO Add support step when done
          default:
            errorMessage = 'publish_event_error';
        }
      }

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id={errorMessage} />),
        severity: 'error',
      });
    }
  };

  const eventLanguage = event.languages[0] as Languages;

  const isPublishable = useMemo<boolean>(() => {
    if (progress.percentage === 100) {
      return true;
    }
    return progress.incompleteSteps === 1 && !organization?.stripeAccountLinked;
  }, [organization, progress]);

  if (event.status === EventStatus.Published) {
    return (
      <EventPublished />
    );
  }
  return (
    <PageLayout>
      <Grid container direction="column" height="100%">
        <Grid item flexGrow={1}>
          <Title>
            <FormattedMessage id="publish_event" />
          </Title>
          <InformationContainer>
            <ReviewContainer>
              <FormattedMessage id="publish_review_info" />
            </ReviewContainer>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item lg={6} xs={12} direction="column" padding={5} display="flex" justifyContent="center" margin="2rem 0">
                <EventTitle>
                  {event.name[language] || event.name[eventLanguage] || ''}
                </EventTitle>
                <EventAddress>
                  {event.location}
                </EventAddress>
                <EventDate>
                  {`${intl.formatMessage({ id: 'event_starts' })} ${startDate} - ${intl.formatMessage({ id: 'event_ends' })} ${endDate}`}
                </EventDate>
                <EnsureRole requiredRole="ADMIN">
                  <PublishingContainer>
                    <ContainedButton color="secondary" disabled={!isPublishable} onClick={onPublish}>
                      <FormattedMessage id="publish_event" />
                    </ContainedButton>
                  </PublishingContainer>
                </EnsureRole>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Grid container direction="row" alignItems="center" justifyContent="space-around" gap="3rem" paddingTop="1.5rem">
                  {`${progress.percentage}%`}
                  <PublishProgress variant="determinate" value={progress.percentage} />
                </Grid>
                <Grid container direction="column" alignItems="flex-start" justifyContent="flex-start" margin="1.5rem 0">
                  <StepContainer>
                    <CheckDot isCompleted />
                    <FormattedMessage id="publish_event_step_1" />
                  </StepContainer>
                  <StepContainer>
                    <CheckDot isCompleted={event.hasWaitingLines} />
                    <FormattedMessage id="publish_event_step_2" />
                  </StepContainer>
                  <StepContainer>
                    <CheckDot isCompleted={obnls ? obnls.length > 0 : false} />
                    <FormattedMessage id="publish_event_step_3" />
                  </StepContainer>
                  <StepContainer>
                    <CheckDot isCompleted={organization?.stripeAccountLinked ?? false} />
                    <FormattedMessage id="publish_event_step_4" />
                  </StepContainer>
                  {settings.requireMovonValidationForPublish && (
                    <StepContainer>
                      <CheckDot isCompleted={organization?.validatedByMovon ?? false} />
                      <div>
                        <FormattedMessage id="publish_event_step_5" values={{ mailto }} />
                      </div>
                    </StepContainer>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </InformationContainer>
        </Grid>
        {event.status === EventStatus.Draft && (
          <EnsureRole requiredRole="ADMIN">
            <StickySteps>
              <Separator />
              <StickyStepsContent>
                <NeutralOutlinedButton
                  onClick={() => setLocation('/causes')}
                  startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                  <FormattedMessage id="previous_step" />
                </NeutralOutlinedButton>
              </StickyStepsContent>
            </StickySteps>
          </EnsureRole>
        )}
      </Grid>
    </PageLayout>
  );
}

export default EventPublish;
