import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Loader from 'components/Loading/Loader';
import PageLayout from 'components/PageLayout';
import { useDeleteWebhookMutation, useGetWebhookQuery } from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'styles/styles';
import { useLocation, useParams } from 'wouter';

import LightTooltip from '../LightTooltip';
import {
  ActionList,
  ActionListItem,
  ActionListItemButton,
  ActionListItemText,
  BorderedGrid,
} from '../styles';
import WebhookCalls from './WebhookCalls/WebhookCalls';

export default function Webhook() {
  const { webhookId = '' } = useParams();
  const theme = useTheme();
  const { id: eventId } = useEvent();
  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [, setLocation] = useLocation();
  const { data, loading } = useGetWebhookQuery({
    variables: {
      id: webhookId,
      eventId,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteWebhook] = useDeleteWebhookMutation();

  const handleDelete = useCallback(async () => {
    await deleteWebhook({ variables: { id: webhookId, eventId } });
    setLocation('/');
  }, [deleteWebhook, eventId, setLocation, webhookId]);

  const handleTooltipClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleEdit = useCallback(() => {
    setLocation(`/${webhookId}/edit`);
  }, [setLocation, webhookId]);

  if (!webhookId) {
    return (
      <div>
        <FormattedMessage id="webhook_not_found" />
      </div>
    );
  }

  return (
    <PageLayout>
      <Loader isLoading={loading}>
        <Grid container alignItems="flex-start" pb="1rem" flexDirection="column" gap={1}>
          <Grid item width="100%">
            <Grid container justifyContent="space-between" flexDirection="row" alignItems="center" flexGrow={1}>
              <Grid item>
                <Title>
                  {data?.getWebhook.endpointUrl}
                </Title>
              </Grid>
              <Grid item>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <LightTooltip
                      open={menuOpen}
                      placement="bottom-end"
                      title={(
                        <ActionList>
                          <ActionListItem>
                            <ActionListItemButton onClick={handleEdit}>
                              <ActionListItemText>
                                <FormattedMessage id="webhook_edit" />
                              </ActionListItemText>
                            </ActionListItemButton>
                          </ActionListItem>
                          <ActionListItem>
                            <ActionListItemButton onClick={handleModalOpen}>
                              <ActionListItemText $color={theme.palette.error.main}>
                                <FormattedMessage id="webhook_delete" />
                              </ActionListItemText>
                            </ActionListItemButton>
                          </ActionListItem>
                        </ActionList>
                      )}
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        },
                      }}
                      arrow
                    >
                      <Button variant="outlined" onClick={handleTooltipOpen}>
                        <FontAwesomeIcon icon={faEllipsis} size="lg" />
                      </Button>
                    </LightTooltip>
                  </div>
                </ClickAwayListener>
              </Grid>
            </Grid>
          </Grid>
          {data?.getWebhook.description && (
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {data?.getWebhook.description}
              </Typography>
            </Grid>
          )}
          <BorderedGrid item $borderTop={{ enableBorder: true }} width="100%" pt="0.7rem" pb="1rem">
            <Grid container justifyContent="flex-start" flexDirection="row" alignItems="center">
              <Grid item>
                <Grid container justifyContent="flex-start" flexDirection="column" gap="0.3rem">
                  <Grid item>
                    <FormattedMessage id="webhook_listening_for" />
                  </Grid>
                  <Grid item>
                    <LightTooltip
                      title={(
                        <ActionList>
                          {(data?.getWebhook.actions || []).map((action) => (
                            <ActionListItem key={action}>
                              <ActionListItemText>
                                {action}
                              </ActionListItemText>
                            </ActionListItem>
                          ))}
                        </ActionList>
                      )}
                      arrow
                    >
                      <Chip label={<FormattedMessage id="webhook_nb_actions" values={{ nbr: data?.getWebhook.actions.length || 0, count: data?.getWebhook.actions.length || 0 }} />} />
                    </LightTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BorderedGrid>
        </Grid>
        <WebhookCalls eventId={eventId} webhookId={webhookId} />
        <ConfirmModal
          title="webhook_delete_confirm_title"
          open={modalOpen}
          onClose={handleModalClose}
          onConfirm={handleDelete}
          closeText="general_no"
          confirmColor="error"
          confirmText="general_yes"
          loading={loading}
        >
          <FormattedMessage id="webhook_delete_confirm_text" />
        </ConfirmModal>
      </Loader>
    </PageLayout>
  );
}
