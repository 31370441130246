import { QueryHookOptions } from '@apollo/client';
import { useAseSubscription } from '@novominteractive/ase-react-apollo';
import {
  DisplayTicket,
  GetAllNoShowTicketsDocument,
  GetAllNoShowTicketsQuery,
  GetAllNoShowTicketsQueryVariables,
  TicketStatus,
} from 'lib/graphql/graphql';

type Options = QueryHookOptions<
GetAllNoShowTicketsQuery,
GetAllNoShowTicketsQueryVariables
>;
type UseNoShowTicketSubscriptionOptions = Omit<Options, 'variables'> & { variables: NonNullable<Options['variables']> };

function useNoShowTicketSubscription(options: UseNoShowTicketSubscriptionOptions) {
  const { waitingLineId } = options.variables;

  return useAseSubscription<
  DisplayTicket,
  GetAllNoShowTicketsQuery,
  GetAllNoShowTicketsQueryVariables
  >(
    waitingLineId,
    GetAllNoShowTicketsDocument,
    (previous, newTicket) => {
      if (newTicket.status !== TicketStatus.Activated) {
        return {
          getAllNoShowWaitingLineTickets: {
            ...previous?.getAllNoShowWaitingLineTickets,
            tickets: (previous?.getAllNoShowWaitingLineTickets.tickets || []).filter(
              (ticket) => ticket.displayId !== newTicket.displayId,
            ),
          },
        };
      }
      return {
        getAllNoShowWaitingLineTickets: {
          ...previous?.getAllNoShowWaitingLineTickets,
          tickets: [
            ...(previous?.getAllNoShowWaitingLineTickets?.tickets ?? []).filter(
              (ticket) => ticket.displayId !== newTicket.displayId,
            ),
            newTicket,
          ],
        },
      };
    },
    options,
  );
}

export default useNoShowTicketSubscription;
