import { Drawer, drawerClasses, Typography } from '@mui/material';
import styled from 'styled-components';

export const RoundedDrawer = styled(Drawer)`

  & .${drawerClasses.paper} {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 1.5rem;
  }
`;

export const TicketIcon = styled.img`
  height: 20px;
  width: 30px;
`;

export const TicketMoreMenu = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
`;

export const TicketMoreMenuDisplayId = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const TicketMoreMenuTextSection = styled.div`
  display: flex;
  flex-direction: column;
`;
