import { Autocomplete, AutocompleteProps } from '@mui/material';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';

import FormField, { FormFieldProps } from './FormField';

type CustomMUIAutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = 'div',
> = Omit<AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name'>;

export interface FormAutocompleteFieldProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = 'div',
> extends FormFieldProps, CustomMUIAutocompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
  > {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  innerFieldLabel?: string
}

function FormAutocompleteField<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = 'div',
>(props: FormAutocompleteFieldProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>) {
  const {
    name,
    rules = {},
    labelId,
    required,
    innerFieldLabel,
    fieldDescription,
    ...autocompleteFieldProps
  } = props;

  const { field, formState } = useController({ name, rules: { required, ...rules } });

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <Autocomplete
        size="small"
        defaultValue={field.value || formState.defaultValues?.[name] || ''}
        value={field.value}
        fullWidth
        onChange={(_, data) => { field.onChange(data); }}
        onBlur={field.onBlur}
        ref={field.ref}
        {...autocompleteFieldProps}
      />
    </FormField>
  );
}

export default FormAutocompleteField;
