import { MenuItem, Select } from '@mui/material';
import { LUXON_TIMEZONES } from 'lib/maps/timezones';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';

import FormField, { FormFieldProps } from './FormField';

// TODO: Rework as a generic select field (and add select props)

export interface FormTimezoneProps extends FormFieldProps {
  rules?: RegisterOptions<FieldValues, string>
}

function FormTimezoneField(props: FormTimezoneProps) {
  const {
    rules,
    required,
    labelId,
    name,
  } = props;
  const { field } = useController({ name, rules: { required, ...rules } });

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
    >
      <Select
        value={field.value}
        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        onChange={field.onChange}
        fullWidth
        size="small"
      >
        {LUXON_TIMEZONES.map((tz) => (
          <MenuItem key={tz.value} value={tz.value}>
            {tz.label}
          </MenuItem>
        ))}
      </Select>
    </FormField>
  );
}

export default FormTimezoneField;
