import uploadImage from 'assets/images/event/icon_uploadimage.svg';
import cloudinaryClient from 'lib/cloudinary/CloudinaryClient';
import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormField, { FormFieldProps } from '../FormField';
import {
  BrowseText,
  DragFileText,
  FileError,
  FilePreview,
  FormMediaFieldContainer,
  Icon,
  ImageContainer,
  StyledImage,
  StyledRoundCircleBackground,
  SupportedText,
} from './styles';

export interface FormMediaFieldProps extends FormFieldProps {
  rules?: RegisterOptions<FieldValues, string>
}

function FormMediaField(props: FormMediaFieldProps) {
  const {
    rules,
    required,
    labelId,
    name,
    fieldDescription,
  } = props;
  const { field } = useController({ name, rules: { required, ...rules } });

  const onDrop = useCallback((acceptedFiles: any) => {
    field.onChange(acceptedFiles[0]);
  }, [field]);

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop,
    noClick: true,
    maxFiles: 1,
    noKeyboard: true,
  });

  const uniqueErrors = useMemo(() => Array.from(new Set(fileRejections.flatMap(
    (rejection) => rejection.errors.map(
      (error) => error.code,
    ),
  ))), [fileRejections]);

  const eventImage = cloudinaryClient.image(field.value);

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <FormMediaFieldContainer {...getRootProps({ className: 'dropzone' })} $isDragActive={isDragActive}>
        <input {...getInputProps()} />
        {field.value && field.value.name && (
          <div>
            <FilePreview src={URL.createObjectURL(field.value)} />
          </div>
        )}
        {field.value && !field.value.name && eventImage && (
          <StyledImage cldImg={eventImage} />
        )}
        {!field.value && (
          <ImageContainer>
            <StyledRoundCircleBackground>
              <Icon src={uploadImage} />
            </StyledRoundCircleBackground>
          </ImageContainer>
        )}
        <DragFileText>
          <FormattedMessage id="event_media_drag" />
          <BrowseText as="span" onClick={open}>
            <FormattedMessage id="event_media_browse" />
          </BrowseText>
        </DragFileText>
        <SupportedText>
          <FormattedMessage id="event_media_supported" />
        </SupportedText>
      </FormMediaFieldContainer>
      {uniqueErrors.map((error) => (
        <FileError>
          <FormattedMessage id={`event_media_${error.replace(/-/g, '_')}`} />
        </FileError>
      ))}
    </FormField>
  );
}

export default FormMediaField;
