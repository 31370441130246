import { ButtonProps } from '@mui/material';
import { WaitingLineStatus } from 'lib/graphql/graphql';

const statusColor: { [key in WaitingLineStatus] : ButtonProps['color'] } = {
  [WaitingLineStatus.Inactive]: 'primary',
  [WaitingLineStatus.Active]: 'secondary',
  [WaitingLineStatus.Hidden]: 'primary',
};

export default statusColor;
