import {
  GridColDef,
  gridPageCountSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid';
import { GetAllWaitingLineTicketsItem } from 'lib/graphql/entities/tickets/types';
import {
  WaitingLineType,
} from 'lib/graphql/graphql';
import useIsMobile from 'lib/useIsMobile';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  getActionsColumn,
  getCalledColumn,
  getDisplayIdColumn,
  getReservationTimeColumn,
  getStatusColumn,
  getTransactionIdColumn,
} from './columns';
import {
  BorderlessFooterDataGrid,
  WaitingLineListPagination,
} from './styles';

interface WaitingLineTicketListProps {
  tickets: GetAllWaitingLineTicketsItem[];
  searchValue?: string;
  waitingLineType: WaitingLineType;
  handleRefetch: () => void;
  isFreemium: boolean;
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { setPage } = apiRef.current;
  return (
    <WaitingLineListPagination
      count={pageCount}
      onChange={(_, page) => {
        setPage(page - 1);
      }}
    />
  );
}

function getDesktopColumns(
  intl: ReturnType<typeof useIntl>,
  isFreemium: boolean,
  waitingLineType: WaitingLineType,
  handleRefetch: () => void | Promise<void>,
) {
  if (isFreemium) {
    return [
      getDisplayIdColumn(intl),
      getTransactionIdColumn(intl),
      getReservationTimeColumn(intl),
      getStatusColumn(intl),
      getCalledColumn(intl),
      getActionsColumn(waitingLineType, handleRefetch, true),
    ];
  }
  return [
    getDisplayIdColumn(intl),
    getTransactionIdColumn(intl),
    getReservationTimeColumn(intl),
    getStatusColumn(intl),
    getActionsColumn(waitingLineType, handleRefetch, false),
  ];
}

function WaitingLineTicketList(props: WaitingLineTicketListProps) {
  const {
    searchValue,
    waitingLineType,
    isFreemium,
    tickets,
    handleRefetch,
  } = props;

  const intl = useIntl();
  const apiRef = useGridApiRef();
  const isMobile = useIsMobile();

  useEffect(() => {
    apiRef.current.setQuickFilterValues(searchValue ? searchValue.split(' ') : []);
  }, [searchValue, apiRef]);

  const columns: GridColDef[] = useMemo(() => (
    isMobile ? [
      {
        ...getDisplayIdColumn(intl),
        flex: 0.5,
      },
      {
        ...getStatusColumn(intl),
        flex: 0.4,
      },
      {
        ...getActionsColumn(waitingLineType, handleRefetch, isFreemium),
        flex: 0.1,
      },
    ] : getDesktopColumns(intl, isFreemium, waitingLineType, handleRefetch)
  ), [isMobile, intl, waitingLineType, handleRefetch, isFreemium]);

  return (
    <BorderlessFooterDataGrid
      apiRef={apiRef}
      rows={tickets || []}
      rowHeight={isMobile ? 40 : 52}
      columnHeaderHeight={isMobile ? 40 : 52}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableRowSelectionOnClick
      columns={columns}
      slots={{
        pagination: CustomPagination,
      }}
      slotProps={{
        toolbar: {
          csvOptions: {
            disableToolbarButton: true,
          },
          printOptions: { disableToolbarButton: true },
        },
      }}
      initialState={{
        pagination: {
          paginationModel: { pageSize: isMobile ? 10 : 25 },
        },
      }}
    />
  );
}

export default WaitingLineTicketList;
