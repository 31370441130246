import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from 'styled-components';

export const CallItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
`;

export const CallItemIdentification = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export interface StatusIconProps {
  $status?: number;
}

export const StatusIcon = styled(FontAwesomeIcon)<StatusIconProps>`
  color: ${({ theme, $status }) => ($status === 200 ? theme.palette.success.main : (($status && theme.palette.error.main) || theme.palette.warning.main))};
`;
