import { Checkbox, MenuItem } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useGetAllEventWaitingLinesLazyQuery, WaitingLineStatus } from 'lib/graphql/graphql';
import EventContext from 'providers/event/EventContext';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import WaitingLinesFilters from '../../QRReader/components/WaitingLinesFilters/WaitingLinesFilters';
import { Container, FullWidthSelect } from './styles';
import WaitingLineSelectLabel from './WaitingLineSelectLabel';

interface OperationsWaitingLineSelectorProps {
  waitingLineId: string | null
  setWaitingLineId: (id: string) => void
}

function OperationsWaitingLineSelector(props: OperationsWaitingLineSelectorProps) {
  const { setWaitingLineId, waitingLineId } = props;

  const event = useContext(EventContext);

  const [getAllEventWaitingLines] = useGetAllEventWaitingLinesLazyQuery();

  const [hideInactiveWaitingLines, setHideInactiveWaitingLines] = useState(true);

  const {
    data,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['waitingLines'],
    queryFn: async ({ pageParam }) => {
      const result = await getAllEventWaitingLines({
        fetchPolicy: 'network-only',
        variables: { eventId: event.id, start: pageParam },
      });
      return result.data;
    },
    initialPageParam: undefined as string | undefined,
    getNextPageParam: (lastPage) => lastPage?.getAllEventWaitingLines.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const waitingLines = useMemo(() => (
    data?.pages.flatMap((p) => p?.getAllEventWaitingLines.waitingLines || []) || []
  ), [data?.pages]);

  const shownWaitingLines = useMemo(() => (
    waitingLines.filter((wl) => (
      !hideInactiveWaitingLines
      || wl.status === WaitingLineStatus.Active
      || wl.id === waitingLineId
    ))
  ), [waitingLines, hideInactiveWaitingLines, waitingLineId]);

  useEffect(() => {
    if (!waitingLineId && waitingLines.length > 0) {
      const waitingLine = waitingLines.find((w) => w.status === WaitingLineStatus.Active);
      if (waitingLine) {
        setWaitingLineId(waitingLine.id);
      }
    }
  }, [waitingLineId, waitingLines, setWaitingLineId]);

  return (
    <Container>
      <FullWidthSelect
        value={waitingLineId || ''}
        onChange={(e) => {
          setWaitingLineId(e.target.value as string);
        }}
      >
        {shownWaitingLines.map((waitingLine) => (
          <MenuItem value={waitingLine.id} key={waitingLine.id}>
            <WaitingLineSelectLabel
              name={waitingLine.name}
              status={waitingLine.status}
            />
          </MenuItem>
        ))}
      </FullWidthSelect>
      <WaitingLinesFilters>
        <MenuItem
          onClick={() => setHideInactiveWaitingLines(!hideInactiveWaitingLines)}
          key="waiting_lines_hide_inactive"
        >
          <Checkbox checked={hideInactiveWaitingLines} />
          <FormattedMessage id="waiting_lines_hide_inactive" />
        </MenuItem>
      </WaitingLinesFilters>
    </Container>
  );
}

export default OperationsWaitingLineSelector;
