import Loader from 'components/Loading/Loader';
import { useGetWaitingLineQuery } from 'lib/graphql/graphql';
import useIsMobile from 'lib/useIsMobile';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import { useState } from 'react';

import OperationsWaitingLineSelector from '../components/OperationsWaitingLineSelector';
import useOperationWaitingLine from '../useOperationsWaitingLine';
import WaitingLineOperations from './components/WaitingLineOperations/WaitingLineOperation';
import { FullHeightPageLayout, PageHeader, StyledSearch } from './styles';

function AdvancedOperations() {
  useEventDocumentTitle('operations');
  const isMobile = useIsMobile();

  const [waitingLineId, setWaitingLineId] = useOperationWaitingLine();

  const { data, loading, refetch } = useGetWaitingLineQuery({
    variables: { id: waitingLineId! },
    skip: !waitingLineId,
  });
  const waitingLine = data?.getWaitingLine;

  const [searchValue, setSearchValue] = useState('');

  return (
    <FullHeightPageLayout>
      <PageHeader $isMobile={isMobile}>
        <OperationsWaitingLineSelector
          waitingLineId={waitingLineId}
          setWaitingLineId={setWaitingLineId}
        />
        <StyledSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </PageHeader>
      <Loader isLoading={!waitingLineId || loading}>
        <WaitingLineOperations
          refetchWaitingLine={refetch}
          searchValue={searchValue}
          waitingLine={waitingLine!}
        />
      </Loader>
    </FullHeightPageLayout>
  );
}

export default AdvancedOperations;
