import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import { useValidateTicketMutation } from 'lib/graphql/graphql';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { CalledTicket } from '../../types';
import { SmallOutlinedButton } from './styles';

interface ValidateButtonProps {
  ticket: CalledTicket
  waitingLineId: string
}

function boldText(text: React.ReactNode) {
  return <b>{text}</b>;
}

function ValidateButton(props: ValidateButtonProps) {
  const { ticket, waitingLineId } = props;
  const { displayId, id } = ticket;

  const feedback = useFeedback();

  const [validateTicket] = useValidateTicketMutation();

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleValidateTicket = useCallback(async () => {
    try {
      await validateTicket({
        variables: {
          ticketId: id,
          waitingLineId,
        },
      });
      setConfirmModalOpen(false);
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (
          <FormattedMessage
            id="ticket_validated"
            values={{
              displayId,
              b: boldText,
            }}
          />
        ),
        severity: 'success',
      });
    } catch (err) {
      const error = err as Error;
      let errorId = '';
      switch (error.message) {
        case 'Ticket already used':
          errorId = 'operating_ticket_already_used';
          break;
        case 'Invalid ticket':
          errorId = 'operating_unknown_error';
          break;
        case 'Ticket not found':
          errorId = 'operating_ticket_not_found';
          break;
        default:
          errorId = 'operating_ticket_validation_generic_error';
          break;
      }
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id={errorId} />),
        severity: 'error',
      });
    }
  }, [feedback, id, displayId, validateTicket, waitingLineId]);

  return (
    <>
      <SmallOutlinedButton
        onClick={() => setConfirmModalOpen(true)}
      >
        <FormattedMessage id="ticket_validate" />
      </SmallOutlinedButton>
      <ConfirmModal
        title="ticket_validate"
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={handleValidateTicket}
        closeText="general_no"
        confirmText="general_yes"
      >
        <FormattedMessage id="ticket_validate_body" values={{ displayId, b: boldText }} />
      </ConfirmModal>
    </>
  );
}

export default ValidateButton;
