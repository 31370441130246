import Loader from 'components/Loading/Loader';
import useCalledTicketSubscription from 'lib/graphql/entities/tickets/useCalledTicketSubscription';
import usePagination, { mergePaginatedList } from 'lib/usePagination';
import { useMemo } from 'react';

import { TicketCard, TicketCardNumber, TicketsList } from '../styles';
import ValidateButton from './ValidateButton';

interface CalledTicketsProps {
  waitingLineId: string
}

function CalledTickets(props: CalledTicketsProps) {
  const { waitingLineId } = props;

  const { data, loading } = usePagination(
    useCalledTicketSubscription({ variables: { waitingLineId } }),
    (d) => d.getAllCalledWaitingLineTickets.lastEvaluated,
    (existing, incoming) => ({
      getAllCalledWaitingLineTickets: mergePaginatedList(
        existing.getAllCalledWaitingLineTickets,
        incoming.getAllCalledWaitingLineTickets,
        'tickets',
      ),
    }),
  );
  const tickets = data?.getAllCalledWaitingLineTickets.tickets;

  const sortedTickets = useMemo(() => (
    [...tickets || []].sort((a, b) => a.displayId - b.displayId)
  ), [tickets]);

  return (
    <Loader isLoading={loading || !waitingLineId}>
      <TicketsList>
        {sortedTickets.map((ticket) => (
          <TicketCard key={ticket.id}>
            <TicketCardNumber>
              {ticket.displayId}
            </TicketCardNumber>
            <ValidateButton
              ticket={ticket}
              waitingLineId={waitingLineId!}
            />
          </TicketCard>
        ))}
      </TicketsList>
    </Loader>
  );
}

export default CalledTickets;
