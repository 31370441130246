import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export interface GraphqlErrorProps {
  error: Error | ApolloError;
}

export default function GraphqlError({ error }: GraphqlErrorProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div>
      <FormattedMessage id="generic_graphql_error" />
    </div>
  );
}
