import { Grid, MenuItem } from '@mui/material';
import FormBooleanField from 'components/FormFields/FormBooleanField';
import FormDateField from 'components/FormFields/FormDateTimeField/FormDateField';
import FormTimeField from 'components/FormFields/FormDateTimeField/FormTimeField';
import FormNumberFieldWithControls from 'components/FormFields/FormNumberFieldWithControls';
import FormSelectField from 'components/FormFields/FormSelectField';
import { WaitingLineInput, WaitingLineUpdateInput } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import useEvent from 'providers/event/useEvent';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const DURATION_OPTIONS = [15, 20, 25, 30];

function TimeSlotsConfiguration() {
  const event = useEvent();
  const { watch } = useFormContext<WaitingLineInput | WaitingLineUpdateInput>();

  const hasTimeSlots = watch('hasTimeSlots');
  const timeSlotStart = watch('timeSlotStart');
  const timeSlotEnd = watch('timeSlotEnd');
  const timeSlotScheduleStart = watch('timeSlotScheduleStart');
  const timeSlotScheduleEnd = watch('timeSlotScheduleEnd');

  const validateSlotStart = useCallback((value: DateTime | string) => {
    const time = typeof value === 'string' ? DateTime.fromISO(value) : value;
    if (timeSlotEnd) {
      const slotEnd = DateTime.fromISO(timeSlotEnd);
      if (time.diff(slotEnd).milliseconds > 0) {
        return 'waiting_line_time_slots_start_error_after_end';
      }
    }
    return undefined;
  }, [timeSlotEnd]);

  const validateSlotEnd = useCallback((value: DateTime | string) => {
    const time = typeof value === 'string' ? DateTime.fromISO(value) : value;
    if (time.diff(event.endTime).milliseconds > 0) {
      return 'waiting_line_time_slots_end_error_after_event';
    }
    if (timeSlotStart) {
      const slotStart = DateTime.fromISO(timeSlotStart);
      if (time.diff(slotStart).milliseconds < 0) {
        return 'waiting_line_time_slots_end_error_before_start';
      }
    }
    return undefined;
  }, [event.endTime, timeSlotStart]);

  const validateScheduleStart = useCallback((value: DateTime | string) => {
    const time = typeof value === 'string' ? DateTime.fromISO(value) : value;
    if (timeSlotScheduleEnd) {
      const scheduleEnd = DateTime.fromISO(timeSlotScheduleEnd);
      if (time.diff(scheduleEnd).milliseconds > 0) {
        return 'waiting_line_time_slots_schedule_start_error_after_end';
      }
    }
    return undefined;
  }, [timeSlotScheduleEnd]);

  const validateScheduleEnd = useCallback((value: DateTime | string) => {
    const time = typeof value === 'string' ? DateTime.fromISO(value) : value;
    if (timeSlotScheduleStart) {
      const scheduleStart = DateTime.fromISO(timeSlotScheduleStart);
      if (time.diff(scheduleStart).milliseconds < 0) {
        return 'waiting_line_time_slots_schedule_end_error_before_start';
      }
    }
    return undefined;
  }, [timeSlotScheduleStart]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormBooleanField
          name="hasTimeSlots"
          labelId="waiting_line_time_slots_enable"
          sideBySide
        />
      </Grid>
      {hasTimeSlots && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item>
                <FormDateField
                  labelId="waiting_line_time_slots_start"
                  fieldDescription="waiting_line_time_slots_start_description"
                  name="timeSlotStart"
                  rules={{
                    validate: validateSlotStart,
                  }}
                  required
                />
              </Grid>
              <Grid item>
                <FormDateField
                  labelId="waiting_line_time_slots_end"
                  fieldDescription="waiting_line_time_slots_end_description"
                  name="timeSlotEnd"
                  rules={{
                    validate: validateSlotEnd,
                  }}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item>
                <FormTimeField
                  labelId="waiting_line_time_slots_schedule_start"
                  fieldDescription="waiting_line_time_slots_schedule_start_description"
                  name="timeSlotScheduleStart"
                  rules={{
                    validate: validateScheduleStart,
                  }}
                  required
                />
              </Grid>
              <Grid item>
                <FormTimeField
                  labelId="waiting_line_time_slots_schedule_end"
                  fieldDescription="waiting_line_time_slots_schedule_end_description"
                  name="timeSlotScheduleEnd"
                  rules={{
                    validate: validateScheduleEnd,
                  }}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <FormSelectField
                  name="timeSlotDuration"
                  labelId="waiting_line_time_slots_duration"
                  fieldDescription="waiting_line_time_slots_duration_description"
                  required
                >
                  {DURATION_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      <FormattedMessage
                        id="waiting_line_time_slots_duration_option"
                        values={{ value: option }}
                      />
                    </MenuItem>
                  ))}
                </FormSelectField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormNumberFieldWithControls
              name="ticketsPerTimeSlot"
              labelId="waiting_line_time_slots_tickets_count"
              fieldDescription="waiting_line_time_slots_tickets_count_description"
              required
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default TimeSlotsConfiguration;
