import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  buttonClasses,
  Collapse,
  Drawer,
  drawerClasses,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Pagination,
  Paper,
  Typography,
} from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { ListCard, ListCardSection } from 'components/ListCard';
import styled, { keyframes } from 'styled-components';

export const TicketIcon = styled.img`
  height: 20px;
  width: 30px;
`;

export const ProgressTicketBar = styled(LinearProgress)`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.text.disabled};

  & .${linearProgressClasses.bar1Determinate} {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const SoldTicketContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ProgressContainer = styled(SoldTicketContainer)`
  flex-direction: column;
  gap: 0;
`;

export const RowProgressContainer = styled(SoldTicketContainer)`
  flex-direction: row;
  gap: 1rem;
  align-items: flex-end;
`;

export const ProgressTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

export const CenteredProgressTop = styled(ProgressTop)`
  justify-content: center;
`;

export const ProgressText = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: black;
  opacity: 0.5;
  justify-content: flex-start;
`;

export const TicketsNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CurrentTicketsSold = styled(Typography)`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: black;
  justify-content: right;
`;

export const TicketsLeft = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: black;
  margin-left: 0.5rem;
`;

export const RightSideCard = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 50%;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const StatusLabel = styled(Typography)`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const CollapseSection = styled(ListCardSection)`
  padding-top: 0 !important;
`;

export const TicketListTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
`;

export const BackgroundlessIconButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

const expendPaperAnimation = keyframes`
  0% {
    width: 97%;
  }
  100% {
    width: 100%;
  }
`;

const collapsePaperAnimation = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 97%;
  }
`;

export const ExpendablePaper = styled(Paper)<{ $expended: boolean }>`
  animation-name: ${({ $expended }) => ($expended ? expendPaperAnimation : collapsePaperAnimation)};
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

export const FullWidthCollapse = styled(Collapse)`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
`;

export const DisplayId = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 2rem;

  @media (width <= 600px) {
    margin-left: 1rem;
  }
`;

export const CalledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ContainerCheck = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.success.main};
`;

export const StatusCheck = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

export const NotCalledContainer = styled(ContainerCheck)`
  background: ${({ theme }) => theme.palette.text.secondary};
`;

export const NoShowContainer = styled(ContainerCheck)`
  background: ${({ theme }) => theme.palette.warning.main};
`;

export const DisplayIdHeader = styled.span`
  margin-left: 2rem;
  font-weight: 600;

  @media (width <= 600px) {
    margin-left: 1rem;
  }
`;

export const WaitingLineListPagination = styled(Pagination)`
  margin-top: 2.5rem;
  padding-right: 1rem;

  @media (width <= 600px) {
    margin-top: 1rem;
    padding-right: 0;
  }
`;

export const BorderlessFooterDataGrid = styled(DataGrid)`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;

  & .${gridClasses.row}, .${gridClasses.columnHeaders}, .${gridClasses.overlay} {
    border-left: 1px solid ${({ theme }) => theme.palette.divider};
    border-right: 1px solid ${({ theme }) => theme.palette.divider};
  }

  & .${gridClasses.footerContainer} {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
  }

  & .${gridClasses.cell}, .${gridClasses.columnHeader} {
    &:focus, &:focus-within {
      outline: none;
    }
  }

  @media (width <= 600px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    & .${gridClasses.footerContainer} {
      border: none;
      border-top: 1px solid ${({ theme }) => theme.palette.divider};
      justify-content: center;
      min-height: 2rem;
      height: 2rem;
    }
  }
`;

export const ListCardSectionWithMargin = styled(ListCardSection)`
  margin-right: 1rem;
`;

export const TicketMoreMenu = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
`;

export const TicketMoreMenuTextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TicketMoreMenuDisplayId = styled(Typography)`
 font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const MobileListCard = styled(ListCard)`
  display: flex;
  flex-direction: column;
`;

export const RoundedButton = styled(Button)`
  &.${buttonClasses.root} {
    border-radius: 20px;
    border: 2px solid;
  }
`;

export const WaitingLineTicketListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const WaitingLineOperationsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 0.7;
`;

export const RoundedDrawer = styled(Drawer)`

  & .${drawerClasses.paper} {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 1.5rem;
  }
`;

export const MobileWaitingLineTitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-flow: row nowrap;
`;

export const ScannedContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ProgressDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 1rem;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LeftGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  
  @media only screen and (width > 1000px) {
    margin: 0.75rem 0;
    padding-right: 1.5rem;
  }
`;

export const UsedTickets = styled(TicketsNumber)`
  padding-top: 1rem;
`;

export const BigTicketsSold = styled(CurrentTicketsSold)`
  font-size: 32px;
  padding-bottom: 0.3rem;
`;

export const ColumnTickets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoBorderButton = styled(BackgroundlessIconButton)`
  border: none;

  @media only screen and (width < 1000px) {
    margin-right: 0.5rem;
  }
`;

export const SoldInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
