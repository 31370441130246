import { TextField, TextFieldProps } from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import { useMemo } from 'react';
import {
  FieldValues,
  RegisterOptions,
  useController,
  ValidationRule,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormField, { FormFieldProps } from './FormField';

type CustomMUITextFieldProps = Omit<TextFieldProps, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name' | 'inputProps' | 'type'>;

interface FormNumberFieldProps extends FormFieldProps, CustomMUITextFieldProps {
  minimum?: ValidationRule<number>
  maximum?: ValidationRule<number>
  innerFieldLabel?: string
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'min' | 'max'>
}

function FormNumberField(props: FormNumberFieldProps) {
  const {
    labelId,
    maximum,
    minimum = 0,
    name,
    required,
    innerFieldLabel,
    fieldDescription,
    rules = {},
    ...textFieldProps
  } = props;
  const intl = useIntl();

  const newRules = useMemo(
    () => ({ min: minimum, max: maximum, ...rules }),
    [minimum, maximum, rules],
  );
  const { field } = useController({ name, rules: { required, ...newRules } });
  const error = useFieldFormError(name);

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <TextField
        size="small"
        fullWidth
        value={field.value}
        placeholder={innerFieldLabel ? intl.formatMessage({ id: innerFieldLabel }) : ''}
        onChange={(event) => {
          const { value } = event.target;
          field.onChange(value === '' ? minimum : +value);
        }}
        onBlur={field.onBlur}
        ref={field.ref}
        error={Boolean(error?.type) || false}
        type="number"
        inputProps={{ min: minimum, max: maximum }}
        {...textFieldProps}
      />
    </FormField>
  );
}

export default FormNumberField;
