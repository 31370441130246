import { Grid } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import NoOrganization from 'assets/images/organization/no_organization.png';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import Loader from 'components/Loading/Loader';
import { EventRole, InvitationStatusEnum, useGetAllAcceptedInvitationsForUserLazyQuery } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import useIsMobile from 'lib/useIsMobile';
import useOrganization from 'providers/organization/useOrganization';
import { useCallback, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, Content, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import OrganizationCard from './OrganizationCard';
import { List, NoOrganizationText } from './styles';

export default function OrganizationSelector() {
  useFormattedDocumentTitle('organizations');
  const [, setLocation] = useLocation();
  const { firstBoot, setFirstBoot } = useContext(AuthenticatorContext);
  const { setInvitationId, setOrganizationId } = useOrganization();

  const isMobile = useIsMobile();

  const [getAllAcceptedInvitationsForUser] = useGetAllAcceptedInvitationsForUserLazyQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ['getAllAcceptedInvitationsForUser'],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllAcceptedInvitationsForUser({
        fetchPolicy: 'network-only',
        variables: {
          start: pageParam,
        },
      });
      return result.data?.getAllAcceptedInvitationsForUser;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: any) => lastPage?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (!data || isLoading || isFetching) return;
    const firstPageOrganizations = data?.pages[0]?.invitations || [];

    if (firstBoot && firstPageOrganizations.length === 1) {
      setInvitationId(firstPageOrganizations[0].id);
      setLocation('~/');
    }

    setFirstBoot(false);
  }, [
    data,
    isLoading,
    isFetching,
    firstBoot,
    setFirstBoot,
    setLocation,
    setInvitationId,
  ]);

  const handleOrganizationChange = useCallback((newInvitationId: string) => {
    setInvitationId(newInvitationId);
    setLocation('~/');
  }, [setInvitationId, setLocation]);

  const handleEditOrganization = useCallback((organizationId: string, invitationId: string) => {
    setInvitationId(invitationId);
    setOrganizationId(organizationId);
    setLocation('~/organization/edit');
  }, [setInvitationId, setLocation, setOrganizationId]);

  const invitations = data?.pages.flatMap((page) => page?.invitations) || [];

  return (
    <Loader isLoading={isLoading || isFetching || firstBoot}>
      <Content>
        <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-between">
          <Title>
            <FormattedMessage id="organizations" />
          </Title>
          <ContainedButton color="secondary" onClick={() => setLocation('~/organizations/create')}>
            <FormattedMessage id="create_organization" />
          </ContainedButton>
        </Grid>
        <List>
          <Grid container direction="column" gap={1}>
            {invitations.map((invitation) => {
              if (invitation?.status === InvitationStatusEnum.Accepted) {
                return (
                  <OrganizationCard
                    key={invitation.organizationId}
                    organizationId={invitation.organizationId}
                    invitationId={invitation.id}
                    handleOrganizationChange={handleOrganizationChange}
                    handleEditOrganization={handleEditOrganization}
                    events={invitation.events as EventRole[]}
                  />
                );
              }
              return null;
            })}
          </Grid>
        </List>
        {invitations.length === 0 && (
          <Grid container direction="column" textAlign="center" m={5}>
            <Grid item>
              <img src={NoOrganization} alt="" />
            </Grid>
            <Grid item paddingTop="1rem">
              <NoOrganizationText variant="body1">
                <FormattedMessage id="organization_none" />
              </NoOrganizationText>
            </Grid>
          </Grid>
        )}
      </Content>
    </Loader>
  );
}
