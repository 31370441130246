import { faCircle as faCircleOutline } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faCircleHalf } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '@mui/material';
import { AppTheme } from 'app-themes/src/types/AppTheme';

import {
  ColorSwatchCheckIcon,
  ColorSwatchContainer,
  ColorSwatchIcon,
  ColorSwatchOutlineIcon,
  ColorSwatchSecondaryIcon,
  ColorSwatchSelectedIcon,
} from './styles';

export interface ColorSwatchProps {
  theme: AppTheme;
  selected: boolean;
  onClick: () => void;
}

export default function ColorSwatch(props: ColorSwatchProps) {
  const { theme: { theme }, selected, onClick } = props;

  return (
    <ColorSwatchContainer>
      <IconButton onClick={onClick}>
        {selected ? (
          <>
            <ColorSwatchSelectedIcon
              icon={faCircleOutline}
              $color={theme.palette.background.default}
            />
            <ColorSwatchOutlineIcon
              icon={faCircleOutline}
              $color={theme.palette.background.default}
            />
            <ColorSwatchIcon icon={faCircleHalf} $color={theme.palette.background.default} />
            <ColorSwatchSecondaryIcon icon={faCircleHalf} $color={theme.palette.primary.main} />
            <ColorSwatchCheckIcon
              icon={faCheck}
              $color={theme.palette.custom.background.contrastText}
            />
          </>
        ) : (
          <>
            <ColorSwatchOutlineIcon
              icon={faCircleOutline}
              $color={theme.palette.background.default}
            />
            <ColorSwatchIcon icon={faCircleHalf} $color={theme.palette.background.default} />
            <ColorSwatchSecondaryIcon icon={faCircleHalf} $color={theme.palette.primary.main} />
          </>
        )}
      </IconButton>
    </ColorSwatchContainer>
  );
}
