import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import PageLayout from 'components/PageLayout';
import StripeDashboardButton from 'components/Stripe/Accounts/DashboardButton/StripeDashboardButton';
import { OrganizationInput, useGetOrganizationQuery, useUpdateOrganizationMutation } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import useOrganization from 'providers/organization/useOrganization';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import OrganizationForm from './OrganizationForm';

function UpdateOrganization() {
  useFormattedDocumentTitle('organization_update');
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const { setOrganizationId, setInvitationId } = useOrganization();

  const { data, loading } = useGetOrganizationQuery();

  const organization = data?.getOrganization;

  const [updateOrganization] = useUpdateOrganizationMutation();

  const defaultValues: OrganizationInput = {
    ...organization,
    name: organization?.name || '',
  };

  useEffect(() => {
    if (loading) return;

    if (!organization) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="organization_not_found" />),
        severity: 'error',
      });

      setLocation('~/');
    }
  }, [feedback, loading, organization, setLocation]);

  const onSubmit: SubmitHandler<OrganizationInput> = async (formData) => {
    if (!organization) return;

    // TODO: Handle loading & errors

    await updateOrganization({
      variables: {
        id: organization.id,
        organization: {
          name: formData.name,
          description: formData.description,
        },
      },
    });

    feedback({
      anchor: { horizontal: 'right', vertical: 'bottom' },
      content: (<FormattedMessage id="update_organization_success" />),
      severity: 'success',
    });

    setInvitationId(null);
    setOrganizationId(null);
    setLocation('~/');
  };

  return (
    <Loader isLoading={loading}>
      <PageLayout>
        <OrganizationForm
          title="update"
          defaultValues={defaultValues}
          onSubmit={onSubmit}
        >
          {organization?.stripeAccountLinked && (
            <StripeDashboardButton organization={organization} />
          )}
        </OrganizationForm>
      </PageLayout>
    </Loader>
  );
}

export default UpdateOrganization;
