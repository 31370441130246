import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Drawer } from '@mui/material';
import useValidateRole from 'lib/access/useValidateRole';
import useIsMobile from 'lib/useIsMobile';
import { useState } from 'react';
import { useLocation } from 'wouter';

import EventSideView from './EventSideView';
import { ContentLayout, DrawerButton, DrawerIcon } from './styles';

interface SideViewLayoutProps {
  children: React.ReactNode;
}

function SideViewLayout(props: SideViewLayoutProps) {
  const { children } = props;

  const [location] = useLocation();
  const validateRole = useValidateRole();
  const isMobile = useIsMobile();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <>
      {isMobile ? (
        <>
          <DrawerButton onClick={() => setDrawerOpen(true)}>
            <DrawerIcon icon={faAngleRight} size="xl" />
          </DrawerButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <EventSideView
              isMobile={isMobile}
              setDrawerOpen={setDrawerOpen}
            />
          </Drawer>
        </>
      ) : (
        <EventSideView
          isMobile={isMobile}
          setDrawerOpen={setDrawerOpen}
        />
      )}
      <ContentLayout
        $isMobile={isMobile}
        $isDisabled={!validateRole('ADMIN') && location === '/edit'}
      >
        {children}
      </ContentLayout>
    </>
  );
}

export default SideViewLayout;
