import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, ListItemText } from '@mui/material';
import Loader from 'components/Loading/Loader';
import { useGetAllEventWebhooksQuery } from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { FormattedMessage } from 'react-intl';
import { SmallTitle } from 'styles/styles';
import { useLocation } from 'wouter';

import {
  BorderedListItem,
  EmptyList,
  FullWidthList,
} from '../styles';
import CallsGraph from './components/CallsGraph/CallsGraph';
import {
  ListHeaderText,
  UrlListItemButton,
  UrlListItemIcon,
} from './styles';

export default function WebhookList() {
  const event = useEvent();

  const [, setLocation] = useLocation();

  const { data, loading } = useGetAllEventWebhooksQuery({
    variables: {
      eventId: event.id || '',
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Grid container direction="column">
      <Grid item>
        <SmallTitle>
          <FormattedMessage id="webhooks_endpoints" />
        </SmallTitle>
      </Grid>
      <Grid item width="100%">
        <Loader isLoading={loading}>
          <Grid container alignItems="center">
            {data?.getAllEventWebhooks.webhooks.length === 0 ? (
              <EmptyList>
                <FormattedMessage id="webhook_no_webhooks" />
              </EmptyList>
            ) : (
              <FullWidthList>
                <BorderedListItem disablePadding $borderBottom={{ enableBorder: true }}>
                  <Grid container direction="row">
                    <Grid item xs={8}>
                      <ListHeaderText>
                        <FormattedMessage id="webhook_url" />
                      </ListHeaderText>
                    </Grid>
                    <Grid item xs={2}>
                      <ListHeaderText>
                        <FormattedMessage id="webhook_last_7_days" />
                      </ListHeaderText>
                    </Grid>
                    <Grid item xs={2} justifyItems="flex-end">
                      <ListHeaderText>
                        <FormattedMessage id="webhook_error_rate" />
                      </ListHeaderText>
                    </Grid>
                  </Grid>
                </BorderedListItem>
                {data?.getAllEventWebhooks.webhooks.map((webhook) => (
                  <BorderedListItem
                    disablePadding
                    $borderBottom={{ enableBorder: true }}
                    $borderTop={{ enableBorder: true }}
                    key={webhook.id}
                  >
                    <UrlListItemButton onClick={() => setLocation(`/${webhook.id}`)}>
                      <UrlListItemIcon>
                        <FontAwesomeIcon icon={faGlobe} />
                      </UrlListItemIcon>
                      <Grid container direction="row">
                        <Grid item xs={8}>
                          <ListItemText>
                            {webhook.endpointUrl}
                          </ListItemText>
                        </Grid>
                        <Grid item xs={2}>
                          <ListItemText>
                            <CallsGraph data={webhook.lastDaysMetadata || []} />
                          </ListItemText>
                        </Grid>
                        <Grid item xs={2} justifyItems="flex-end">
                          <ListItemText>
                            {`${
                              (((webhook.metadata?.errors || 0)
                              / ((
                                (webhook.metadata?.errors || 0)
                                + (webhook.metadata?.success || 0))
                                || 1))
                              * 100).toFixed(0)
                            }%`}
                          </ListItemText>
                        </Grid>
                      </Grid>
                    </UrlListItemButton>
                  </BorderedListItem>
                ))}
              </FullWidthList>
            )}
          </Grid>
        </Loader>
      </Grid>
    </Grid>
  );
}
