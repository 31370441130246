import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import SelectIcon from 'assets/images/cause/icon_select.svg';
import WandIcon from 'assets/images/cause/icon_wand.svg';
import CauseObnlDialog from 'components/Causes/CauseObnlDialog';
import CauseOptionCard from 'components/Causes/CauseOptionCard';
import SupportedOrganizationCard from 'components/Causes/SupportedOrganizationCard';
import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import PageLayout from 'components/PageLayout';
import EnsureRole from 'lib/access/EnsureRole';
import useValidateRole from 'lib/access/useValidateRole';
import { GetAllObnlsItem, GetObnlItem } from 'lib/graphql/entities/obnl/types';
import {
  EventStatus,
  useAddObnlToEventMutation,
  useChangeEventShowObnlChoiceMutation,
  useGetAllObnlsLazyQuery,
  useGetObnlLazyQuery,
} from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ContainedButton,
  NeutralOutlinedButton,
  Separator,
  SmallTitle,
  StickySteps,
  StickyStepsContent,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import { AllowObnlCard, ObnlText } from './styles';

function EventCauses() {
  useEventDocumentTitle('causes');
  const event = useEvent();
  const { obnls, refetchObnls } = event;

  const feedback = useFeedback();
  const [, setLocation] = useLocation();

  const [selectedObnl, setSelectedObnl] = useState<GetAllObnlsItem | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSelection = () => {
    setLocation('/causes/selection');
  };

  const [getAllObnls] = useGetAllObnlsLazyQuery();
  const [addObnlToEvent] = useAddObnlToEventMutation();
  const [changeEventShowObnlChoice] = useChangeEventShowObnlChoiceMutation();

  const validateRole = useValidateRole();

  const handleChooseForMe = async () => {
    const { data } = await getAllObnls();
    const results = data?.getAllObnls.obnls ?? [];

    const eventObnls = event.obnls || [];

    // Filter results to only include items not already in event.obnls
    const uniqueResults = results.filter((item) => !eventObnls.includes(item.id));

    if (uniqueResults.length > 0) {
      const randomIndex = Math.floor(Math.random() * uniqueResults.length);
      const obnl = uniqueResults[randomIndex];

      try {
        await addObnlToEvent({
          variables: {
            eventId: event.id!,
            obnlId: obnl.id,
          },
        });
        refetchObnls();
        setSelectedObnl(obnl);
        setDialogOpen(true);
      } catch (error) {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="add_obnl_error" />),
          severity: 'error',
        });
      }
    } else {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="no_obnl_available_error" />),
        severity: 'error',
      });
    }
  };

  const [getObnl, { loading }] = useGetObnlLazyQuery();
  const [obnlsData, setObnlsData] = useState<GetObnlItem[]>([]);

  useEffect(() => {
    refetchObnls();
    if (obnls) {
      const fetchObnls = async () => {
        const promises = obnls.map((obnl) => getObnl({
          variables: {
            id: obnl,
          },
        }).then((result) => result.data?.getObnl));
        const results = await Promise.all(promises) as GetObnlItem[];
        setObnlsData(results);
      };
      fetchObnls();
    }
  }, [obnls, getObnl, refetchObnls]);

  useEffect(() => {
    const updateObnlChoice = async () => {
      if (event.obnls?.length <= 1) {
        if (event.allowObnlChoice) {
          await changeEventShowObnlChoice({ variables: { id: event.id } });
        }
      } else if (event.obnls?.length > 1 && !event.allowObnlChoice) {
        await changeEventShowObnlChoice({ variables: { id: event.id } });
      }
    };

    updateObnlChoice();
  }, [changeEventShowObnlChoice, event.allowObnlChoice, event.id, event.obnls?.length]);

  return (
    <PageLayout>
      <Grid container direction="column" height="100%">
        <Grid item flexGrow={1}>
          <Title>
            <FormattedMessage id={validateRole('ADMIN') ? 'choose_one_goal_from_sdg_title' : 'supported_organizations'} />
          </Title>
          <Grid container direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="pass_percentage_donated_to_cause" />
            </Typography>
          </Grid>
          <Grid container direction="column">
            <EnsureRole requiredRole="ADMIN">
              <Grid container item xs={12} direction="row" gap="1rem" justifyContent="space-around" marginBottom={2}>
                <CauseOptionCard
                  icon={SelectIcon}
                  title="add_supported_organization"
                  description="select_goal_and_organization"
                  label="start_selection"
                  onClick={handleSelection}
                />
                <CauseOptionCard
                  icon={WandIcon}
                  title="choose_obnl_for_me"
                  description="suggest_random_organization"
                  label="choose_for_me"
                  onClick={handleChooseForMe}
                />
              </Grid>
            </EnsureRole>
            {obnlsData && obnlsData.length > 0 && (
              <>
                <EnsureRole requiredRole="ADMIN">
                  <SmallTitle>
                    <FormattedMessage id="supported_organizations" />
                  </SmallTitle>
                </EnsureRole>
                <Loader isLoading={loading}>
                  <Grid container direction="column" gap="1rem" marginTop="1rem" justifyContent="space-around">
                    {!event.allowObnlChoice && (
                      <AllowObnlCard variant="outlined">
                        <Grid
                          container
                          direction="row"
                          gap="1rem"
                          justifyContent="center"
                          padding="1rem 2rem"
                          alignItems="center"
                        >
                          <Grid item>
                            <ObnlText>
                              <FormattedMessage id="show_supported_organizations" />
                            </ObnlText>
                          </Grid>
                        </Grid>
                      </AllowObnlCard>
                    )}
                    {obnlsData.filter(Boolean).map((obnl) => (
                      <SupportedOrganizationCard
                        obnl={obnl!}
                        key={obnl!.id}
                      />
                    ))}
                  </Grid>
                </Loader>
              </>
            )}
          </Grid>
        </Grid>
        {event.status === EventStatus.Draft && (
          <EnsureRole requiredRole="ADMIN">
            <StickySteps>
              <Separator />
              <StickyStepsContent>
                <NeutralOutlinedButton
                  onClick={() => setLocation('/waitingLines')}
                  startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                  <FormattedMessage id="previous_step" />
                </NeutralOutlinedButton>
                <ContainedButton
                  color="secondary"
                  onClick={() => setLocation('/publish')}
                  endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  <FormattedMessage id="next_step" />
                </ContainedButton>
              </StickyStepsContent>
            </StickySteps>
          </EnsureRole>
        )}
        {selectedObnl && (
          <CauseObnlDialog
            obnl={selectedObnl}
            dialogOpen={dialogOpen}
            onAddMore={() => {
              setDialogOpen(false);
            }}
            onClose={() => {
              setDialogOpen(false);
            }}
            canAddMore={false}
          />
        )}
      </Grid>
    </PageLayout>
  );
}

export default EventCauses;
