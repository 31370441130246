import { TicketStatus } from 'lib/graphql/graphql';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Progress,
  ProgressContainer,
  ProgressText,
  ProgressTicketBar,
  TicketsLeft,
  TicketSold,
} from './styles';

interface TicketProgressProps {
  tickets: { status: TicketStatus }[]
}

function TicketProgress(props: TicketProgressProps) {
  const { tickets } = props;

  const validated = useMemo(() => (
    tickets.filter((ticket) => ticket.status === TicketStatus.Validated)
  ), [tickets]);

  const value = (validated.length / (tickets.length || 1)) * 100;

  return (
    <ProgressContainer>
      <Progress>
        <ProgressText>
          <FormattedMessage id="operator_tickets" />
        </ProgressText>
        <ProgressTicketBar
          variant="determinate"
          value={value}
        />
      </Progress>
      <TicketsLeft>
        <TicketSold>
          {validated.length}
        </TicketSold>
        {` / ${tickets.length}`}
      </TicketsLeft>
    </ProgressContainer>
  );
}

export default TicketProgress;
