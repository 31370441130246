import Settings from './Settings';

const settings: Settings = {
  anyware: {
    licenseKey: 'novom:key',
    rootTopic: 'movon/ssideleau',
  },
  appUrl: 'http://192.168.1.10:5173',
  appThemes: {
    colors: [
      '#1e1e24', // Raisin black
      '#92140c', // Penn red
      '#e0a458', // Earth yellow
      '#3c6e71', // Caribbean Current
      '#f3e8ee', // Lavender blush
      '#303036', // Jet
      '#18206f', // Midnight blue
      '#bcd2ee', // Columbia blue
      '#848c8e', // Battleship grey
      '#435058', // Outer Space
      '#34403a', // Black olive
      '#6cae75', // Asparagus
    ],
  },
  cloudinary: {
    cloudName: 'novom-networks',
    projectName: 'MovOnVIP',
  },
  displayUrl: 'http://192.168.1.10:5175',
  devIp: '192.168.1.10',
  mapboxToken: 'pk.eyJ1Ijoibm92b20taW50ZXJhY3RpdmUiLCJhIjoiY2x2MnFzaXY5MGt2YzJscGM5MG15eGJmOCJ9.zHktKgLYVCQCDuKOVuf5jQ',
  mapsApiKey: 'AIzaSyCKaYhYCVXoJnlp-QLStQsoItEgt7YMy88',
  graphql: {
    url: 'http://192.168.1.10:3000/local/graphql',
  },
  logLevel: 'DEBUG',
  requireMovonValidationForPublish: false,
  support: {
    en: 'https://support.movon.vip/en/hc/3673856758',
    es: 'https://support.movon.vip/es/hc/3673856758',
    fr: 'https://support.movon.vip/fr/hc/3673856758',
  },
  urlShortener: {
    rootUrl: 'https://go.movon.vip/',
  },
};

export default settings;
