import { FormControl, FormHelperText } from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import {
  PropsWithChildren,
  useCallback,
} from 'react';
import { ValidationRule } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormDescription, FormLabel } from 'styles/styles';

import FormErrors from './FormErrors';
import {
  FormInputContainer,
  FormLabelContainer,
  SideFormInputContainer,
  StyledFormControl,
} from './styles';

export interface FormFieldProps extends PropsWithChildren {
  labelId?: string
  name: string
  required?: string | ValidationRule<boolean>
  fieldDescription?: string
  sideBySide?: boolean
}

function FormField(props: FormFieldProps) {
  const {
    required,
    labelId,
    name,
    children,
    fieldDescription,
    sideBySide = false,
  } = props;
  const fieldError = useFieldFormError(name);
  const { formatMessage } = useIntl();

  const helperTextRenderer = useCallback(() => {
    if (fieldError) {
      return <FormErrors error={fieldError} />;
    }
    return null;
  }, [fieldError]);

  if (sideBySide) {
    return (
      <StyledFormControl error={Boolean(fieldError)} required={Boolean(required)} fullWidth>
        {labelId && (
          <FormLabelContainer>
            <FormLabel>
              <FormattedMessage id={labelId} />
              {required && ' *'}
              {(fieldDescription) && (
              <FormDescription title={formatMessage({ id: fieldDescription })}>
                <FormattedMessage id={fieldDescription} />
              </FormDescription>
              )}
            </FormLabel>
          </FormLabelContainer>
        )}
        <SideFormInputContainer>
          {children}
        </SideFormInputContainer>
        <FormHelperText>
          {helperTextRenderer()}
        </FormHelperText>
      </StyledFormControl>
    );
  }

  return (
    <FormControl error={Boolean(fieldError)} required={Boolean(required)} fullWidth>
      {labelId && (
        <FormLabel>
          <FormattedMessage id={labelId} />
          {required && ' *'}
        </FormLabel>
      )}
      {(fieldDescription) && (
        <FormDescription title={formatMessage({ id: fieldDescription })}>
          <FormattedMessage id={fieldDescription} />
        </FormDescription>
      )}
      <FormInputContainer>
        {children}
      </FormInputContainer>
      <FormHelperText>
        {helperTextRenderer()}
      </FormHelperText>
    </FormControl>
  );
}

export default FormField;
