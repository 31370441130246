import { styled } from 'styled-components';

export const ActionTitle = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
`;

export const HTTPTitle = styled.div`
  font-size: 1.2rem;
  padding: 1.8rem 0 0.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const HTTPStatus = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};
  filter: opacity(0.7);
`;
