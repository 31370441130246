import { faHouse, faTicket } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material';
import appThemes, { defaultTheme } from 'app-themes';
import useFeedback from 'components/Feedback/useFeedback';
import PageLayout from 'components/PageLayout';
import { useUpdateEventMutation } from 'lib/graphql/graphql';
import settings from 'lib/settings';
import useEvent from 'providers/event/useEvent';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'styles/styles';

import ColorSwatch from './components/ColorSwatch/ColorSwatch';
import {
  ControlButtons,
  Preview,
  PreviewContainer,
  PreviewControls,
  PreviewIframe,
  SectionTitle,
  TitleSection,
} from './styles';

enum PreviewPages {
  HOME,
  TICKETS,
}

export default function EventTheme() {
  const event = useEvent();
  const [selectedTheme, setSelectedTheme] = useState<string>(event.theme || defaultTheme.name || '');
  const [saveTheme] = useUpdateEventMutation();
  const [previewPage, setPreviewPage] = useState<PreviewPages>(PreviewPages.HOME);
  const feedback = useFeedback();

  const handleSave = useCallback(async () => {
    try {
      await saveTheme({
        variables: {
          event: {
            theme: selectedTheme,
          },
          id: event.id,
        },
      });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="event_theme_saved" />),
        severity: 'success',
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="event_theme_save_error" />),
        severity: 'error',
      });
    }
  }, [selectedTheme, event.id, saveTheme, feedback]);

  const previewUrl = useMemo(() => {
    switch (previewPage) {
      case PreviewPages.TICKETS:
        return encodeURI(`${settings.appUrl}/event/${event.id}/tickets?preview=true&theme=${selectedTheme}`);
      case PreviewPages.HOME:
      default:
        return encodeURI(`${settings.appUrl}/event/${event.id}?preview=true&theme=${selectedTheme}`);
    }
  }, [event.id, previewPage, selectedTheme]);

  return (
    <PageLayout>
      <Grid container direction="column" height="100%" wrap="nowrap">
        <Grid item flexGrow={1} marginBottom="2rem">
          <Title>
            <FormattedMessage id="theme_title" />
          </Title>
          <Grid container direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="theme_description" />
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Grid xs={4} item>
              <Grid container direction="column" justifyContent="space-between" sm={9}>
                <Grid item>
                  <SectionTitle>
                    <FormattedMessage id="event_theme_colors" />
                  </SectionTitle>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={1}>
                    {appThemes.map((theme) => (
                      <Grid item key={theme.name}>
                        <ColorSwatch
                          theme={theme}
                          selected={selectedTheme === theme.name}
                          onClick={() => setSelectedTheme(theme.name)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <ControlButtons item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={selectedTheme === (event.theme ?? defaultTheme.name)}
                  >
                    <FormattedMessage id="event_theme_save" />
                  </Button>
                </ControlButtons>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="row" justifyContent="center">
                <Grid item flexDirection="row">
                  <Paper>
                    <Grid container direction="column">
                      <TitleSection>
                        <SectionTitle>
                          <FormattedMessage id="event_theme_preview" />
                        </SectionTitle>
                      </TitleSection>
                      <Grid container direction="row">
                        <PreviewControls>
                          <List
                            subheader={<ListSubheader><FormattedMessage id="event_theme_pages" /></ListSubheader>}
                          >
                            <ListItem disablePadding>
                              <ListItemButton
                                selected={previewPage === PreviewPages.HOME}
                                onClick={() => setPreviewPage(PreviewPages.HOME)}
                              >
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faHouse} />
                                </ListItemIcon>
                                <ListItemText primary={(<FormattedMessage id="event_theme_home" />)} />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                selected={previewPage === PreviewPages.TICKETS}
                                onClick={() => setPreviewPage(PreviewPages.TICKETS)}
                              >
                                <ListItemIcon>
                                  <FontAwesomeIcon icon={faTicket} />
                                </ListItemIcon>
                                <ListItemText primary={(<FormattedMessage id="event_theme_waiting_lines" />)} />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </PreviewControls>
                        <PreviewContainer>
                          <Preview>
                            <PreviewIframe key={previewUrl} src={previewUrl} title="Preview" />
                          </Preview>
                        </PreviewContainer>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
