import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  TextField,
} from '@mui/material';
import FormAutocompleteField from 'components/FormFields/FormAutocompleteField';
import FormButtons from 'components/FormFields/FormButtons';
import FormTextField from 'components/FormFields/FormTextField';
import PageLayout from 'components/PageLayout';
import EnsureRole from 'lib/access/EnsureRole';
import { WebhookAction } from 'lib/graphql/graphql';
import UnsavedFormQuitConfirmation from 'lib/routing/UnsavedFormQuitConfirmation';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  NeutralOutlinedButton,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

interface WebhookFormProps {
  title: String
  onSubmit: SubmitHandler<any>
}

export default function WebhookForm(props: WebhookFormProps) {
  const { title, onSubmit } = props;

  const [, setLocation] = useLocation();

  return (
    <PageLayout>
      <UnsavedFormQuitConfirmation />
      {title === 'create' && (
        <Grid container mt={2} mb={2}>
          <NeutralOutlinedButton
            onClick={() => setLocation('/')}
            startIcon={<FontAwesomeIcon size="1x" icon={faChevronLeft} />}
          >
            <FormattedMessage id="back_to_webhook_list" />
          </NeutralOutlinedButton>
        </Grid>
      )}
      <Grid container direction="column" gap={2}>
        <Title>
          <FormattedMessage id={`webhook_${title}`} />
        </Title>
        <FormTextField
          fullWidth
          required
          labelId="webhook_endpoint_url"
          name="endpointUrl"
          key="endpointUrl"
          fieldDescription="webhook_endpoint_url_description"
          placeholder="https://"
        />
        <FormTextField
          fullWidth
          multiline
          rows={3}
          labelId="webhook_description"
          name="description"
          key="description"
          fieldDescription="webhook_description_field_description"
          rules={{
            maxLength: 300,
          }}
        />
        <FormAutocompleteField
          fullWidth
          labelId="webhook_actions"
          name="actions"
          key="actions"
          fieldDescription="webhook_actions_description"
          multiple
          options={[WebhookAction.TicketCalled]}
          getOptionLabel={(option) => option as string}
          renderInput={(params) => (
            <TextField {...params} />
          )}
        />
        <Grid container justifyContent="flex-end" gap={1}>
          <EnsureRole requiredRole="ADMIN">
            <FormButtons submit={onSubmit} saveTextId={title === 'create' ? 'create_webhook' : ''} />
          </EnsureRole>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
