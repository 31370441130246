import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, IconButton } from '@mui/material';
import goldTicketIcon from 'assets/images/waitingLine/GoldTicketIcon.svg';
import silverTicketIcon from 'assets/images/waitingLine/SilverTicketIcon.svg';
import { GetAllWaitingLineTicketsItem } from 'lib/graphql/entities/tickets/types';
import { WaitingLineType } from 'lib/graphql/graphql';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import CallTicketItem from './components/CallTicketItem';
import ValidateTicketItem from './components/ValidateTicketItem';
import OptionsMenu from './OptionsMenu';
import {
  TicketIcon,
  TicketMoreMenu,
  TicketMoreMenuDisplayId,
  TicketMoreMenuTextSection,
} from './styles';

export interface WaitingLineTicketMoreProps {
  waitingLineType: WaitingLineType
  ticket: GetAllWaitingLineTicketsItem
  isFreemium: boolean
  refetch: () => void
}

function WaitingLineTicketMore(props: WaitingLineTicketMoreProps) {
  const {
    isFreemium,
    waitingLineType,
    ticket,
    refetch,
  } = props;

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const ticketIcon = waitingLineType === WaitingLineType.Gold ? goldTicketIcon : silverTicketIcon;

  const open = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }, [setAnchorElement]);

  const close = useCallback(() => {
    setAnchorElement(null);
  }, [setAnchorElement]);

  const closeAndRefetch = useCallback(() => {
    close();
    refetch();
  }, [close, refetch]);

  return (
    <>
      <IconButton onClick={open}>
        <FontAwesomeIcon icon={faEllipsisVertical} size="sm" />
      </IconButton>
      <OptionsMenu
        anchorElement={anchorElement}
        onClose={close}
      >
        <TicketMoreMenu>
          <TicketIcon src={ticketIcon} />
          <TicketMoreMenuTextSection>
            <TicketMoreMenuDisplayId>
              {ticket?.displayId}
            </TicketMoreMenuDisplayId>
            {ticket?.contactInfo?.value || <FormattedMessage id="ticket_no_communication_address" />}
          </TicketMoreMenuTextSection>
        </TicketMoreMenu>
        <Divider />
        {isFreemium && (
          <CallTicketItem
            onCall={closeAndRefetch}
            ticket={ticket}
          />
        )}
        <ValidateTicketItem
          onValidate={closeAndRefetch}
          ticket={ticket}
        />
      </OptionsMenu>
    </>
  );
}

export default WaitingLineTicketMore;
