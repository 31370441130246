import { QueryHookOptions } from '@apollo/client';
import { useAseSubscription } from '@novominteractive/ase-react-apollo';
import {
  DisplayTicket,
  GetAllCalledTicketsDocument,
  GetAllCalledTicketsQuery,
  GetAllCalledTicketsQueryVariables,
  TicketStatus,
} from 'lib/graphql/graphql';

type Options = QueryHookOptions<
GetAllCalledTicketsQuery,
GetAllCalledTicketsQueryVariables
>;
type UseCalledTicketSubscriptionOptions = Omit<Options, 'variables'> & { variables: NonNullable<Options['variables']> };

function useCalledTicketSubscription(options: UseCalledTicketSubscriptionOptions) {
  const { waitingLineId } = options.variables;

  return useAseSubscription<
  DisplayTicket,
  GetAllCalledTicketsQuery,
  GetAllCalledTicketsQueryVariables
  >(
    waitingLineId,
    GetAllCalledTicketsDocument,
    (previous, newTicket) => {
      if (newTicket.status !== TicketStatus.Activated) {
        return {
          getAllCalledWaitingLineTickets: {
            ...previous?.getAllCalledWaitingLineTickets,
            tickets: (previous?.getAllCalledWaitingLineTickets.tickets || []).filter(
              (ticket) => ticket.displayId !== newTicket.displayId,
            ),
          },
        };
      }
      return {
        // TODO prevent duplication
        getAllCalledWaitingLineTickets: {
          ...previous?.getAllCalledWaitingLineTickets,
          tickets: [
            ...(previous?.getAllCalledWaitingLineTickets?.tickets ?? []).filter(
              (ticket) => ticket.displayId !== newTicket.displayId,
            ),
            newTicket,
          ],
        },
      };
    },
    options,
  );
}

export default useCalledTicketSubscription;
