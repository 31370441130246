/* eslint-disable no-bitwise */
import { currenciesMap } from 'lib/constants/currencies';
import { Organization } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import { IntlShape } from 'react-intl';
import Stripe from 'stripe';

interface Company {
  name: string;
  address: string;
  gstId: string;
  qstId: string;
}

interface Customer {
  company: string;
  address: string;
  phone: string;
  email: string;
}

interface Invoice {
  number: number;
  date: string;
}

interface Item {
  id: string;
  date: string;
  price: number;
  currency: string;
}

interface Payload {
  company: Company;
  customer: Customer;
  invoice: Invoice;
  item: Item;
  note: string;
}

function numericHash(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash;
  }
  return Math.abs(hash);
}

function wrapText(text: string, font: any, fontSize: number, maxWidth: number): string[] {
  const words = text.split(' ');
  const lines: string[] = [];
  let currentLine = '';

  words.forEach((word) => {
    const testLine = `${currentLine + word} `;
    const testWidth = font.widthOfTextAtSize(testLine, fontSize);

    if (testWidth > maxWidth && currentLine !== '') {
      lines.push(currentLine.trim());
      currentLine = `${word} `;
    } else {
      currentLine = testLine;
    }
  });

  lines.push(currentLine.trim());
  return lines;
}

export function createPayload(
  payout: Stripe.Payout,
  organization: Organization,
  stripeAccount: Stripe.Account,
  locale: string,
  invoiceNumber?: number | null,
) : Payload {
  const companyName = stripeAccount.company?.name
    || stripeAccount.business_profile?.name
    || organization.name || '-';
  const address = stripeAccount.company?.address?.line1
    || stripeAccount.individual?.address?.line1
    || organization.location || '-';

  const payload = {
    company: {
      name: companyName,
      address,
      gstId: organization.gstId || '-',
      qstId: organization.qstId || '-',
    },
    customer: {
      company: 'Novom Interactive inc.',
      address: '107, rue Principale Ouest, suite 250, Magog, QC, J1X 2A6',
      phone: '(819) 769-0741',
      email: 'support@movon.vip',
    },
    invoice: {
      number: invoiceNumber || numericHash(payout.id),
      date: DateTime.now().setLocale(locale).setZone('UTC').toFormat('D'),
    },
    item: {
      id: payout.id,
      date: DateTime.fromSeconds(payout.created).setLocale(locale).setZone('UTC').toFormat('D'),
      price: payout.amount / 100,
      currency: payout.currency,
    },
    note: '',
  };

  return payload;
}

export async function createInvoicePDF(payload: Payload, intl: IntlShape) {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([600, 800]);

  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const fontSize = 12;
  const maxWidth = 200;

  page.drawText(intl.formatMessage({ id: 'invoice' }).toUpperCase(), {
    x: 50, y: 750, size: 24, font: boldFont,
  });
  page.drawText(intl.formatMessage({ id: 'invoice_number' }, { number: payload.invoice.number }), {
    x: 50, y: 730, size: fontSize, font,
  });
  page.drawText(intl.formatMessage({ id: 'invoice_payment_id' }, { id: payload.item.id }), {
    x: 50, y: 715, size: fontSize, font,
  });
  page.drawText(intl.formatMessage({ id: 'invoice_date' }, { date: payload.invoice.date }), {
    x: 50, y: 700, size: fontSize, font,
  });

  // Bill to Details
  page.drawText(intl.formatMessage({ id: 'invoice_bill_to' }), {
    x: 50, y: 650, size: fontSize, font: boldFont,
  });
  page.drawText(payload.customer.company, {
    x: 50, y: 634, size: fontSize, font,
  });
  page.drawText(payload.customer.address, {
    x: 50, y: 618, size: fontSize, font,
  });
  page.drawText(payload.customer.phone, {
    x: 50, y: 602, size: fontSize, font,
  });
  page.drawText(payload.customer.email, {
    x: 50, y: 586, size: fontSize, font,
  });

  page.drawText(intl.formatMessage({ id: 'invoice_payment_from' }, { date: payload.item.date }), {
    x: 50, y: 500, size: fontSize, font: boldFont,
  });

  page.drawLine({
    start: { x: 50, y: 490 },
    end: { x: 300, y: 490 },
    thickness: 1,
  });

  // Company Details
  page.drawText(payload.company.name, {
    x: 360, y: 730, size: fontSize, font: boldFont,
  });
  const addressLines = wrapText(payload.company.address, font, fontSize, maxWidth);
  let yPosition = 710;
  addressLines.forEach((line) => {
    page.drawText(line, {
      x: 360, y: yPosition, size: fontSize, font,
    });
    yPosition -= 16;
  });
  page.drawText(`${intl.formatMessage({ id: 'invoice_gst_id' })}: ${payload.company.gstId}`, {
    x: 360, y: yPosition, size: fontSize, font,
  });
  yPosition -= 16;
  page.drawText(`${intl.formatMessage({ id: 'invoice_qst_id' })}: ${payload.company.qstId}`, {
    x: 360, y: yPosition, size: fontSize, font,
  });

  // Invoice Details
  page.drawText(intl.formatMessage({ id: 'invoice_price' }), {
    x: 50, y: 460, size: fontSize, font,
  });
  page.drawText(intl.formatMessage({ id: 'invoice_gst' }), {
    x: 50, y: 440, size: fontSize, font,
  });
  page.drawText(intl.formatMessage({ id: 'invoice_qst' }), {
    x: 50, y: 420, size: fontSize, font,
  });
  page.drawText(intl.formatMessage({ id: 'invoice_total' }), {
    x: 50, y: 390, size: 18, font: boldFont,
  });

  const currency = payload.item.currency.toUpperCase();
  const price = (payload.item.price).toFixed(currenciesMap[currency].decimalDigits);
  const GST = 0.05; // TPS
  const QST = 0.09975; // TVQ
  const TAXES = 1 + GST + QST;
  const preTaxPrice = parseFloat(price) / TAXES;

  // Calculate GST and QST separately
  const gstAmount = (preTaxPrice * GST).toFixed(currenciesMap[currency].decimalDigits);
  const qstAmount = (preTaxPrice * QST).toFixed(currenciesMap[currency].decimalDigits);

  // Format numbers with comma as decimal separator
  const formatNumber = (number: string) => parseFloat(number).toLocaleString('fr-CA', {
    minimumFractionDigits: currenciesMap[currency].decimalDigits,
    maximumFractionDigits: currenciesMap[currency].decimalDigits,
  });

  page.drawText(`${formatNumber(preTaxPrice.toFixed(currenciesMap[currency].decimalDigits))}${currenciesMap[currency].symbol} ${currency}`, {
    x: 200, y: 460, size: fontSize, font,
  });
  page.drawText(`${formatNumber(gstAmount)}${currenciesMap[currency].symbol} ${currency}`, {
    x: 200, y: 440, size: fontSize, font,
  });
  page.drawText(`${formatNumber(qstAmount)}${currenciesMap[currency].symbol} ${currency}`, {
    x: 200, y: 420, size: fontSize, font,
  });
  page.drawText(`${formatNumber(price)}${currenciesMap[currency].symbol} ${currency}`, {
    x: 200, y: 390, size: 18, font: boldFont,
  });

  page.drawLine({
    start: { x: 50, y: 370 },
    end: { x: 300, y: 370 },
    thickness: 1,
  });

  // Note at the bottom of the page
  page.drawText(payload.note, {
    x: 50, y: 50, size: fontSize, font,
  });

  const { locale } = intl;

  // Save the PDF and create a download link
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${locale.toUpperCase()}-${payload.item.id}.pdf`;
  link.click();
}
