import { faCircleCheck, faCircleExclamation, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { ListItemButton, useTheme } from '@mui/material';
import { WebhookCallPaginatedListItem } from 'lib/graphql/entities/webhhook/types';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { BorderedListItem } from '../../../styles';
import { CallItemContainer, CallItemIdentification, StatusIcon } from './styles';

export interface WebhookCallListItemProps {
  webhookCall: WebhookCallPaginatedListItem;
  onClick?: (webhookCallId: string) => void;
  selected: boolean;
}

export default function WebhookCallListItem(props: WebhookCallListItemProps) {
  const { webhookCall, onClick, selected } = props;
  const theme = useTheme();
  const {
    id,
    action,
    statusCode,
    createdAt,
  } = webhookCall;
  const time = useMemo(() => (DateTime.fromISO(createdAt).toFormat('h:mm:ss a')), [createdAt]);
  return (
    <BorderedListItem
      $borderBottom={
        { enableBorder: true }
      }
      $borderLeft={
        {
          enableBorder: selected,
          $borderColor: theme.palette.secondary.main,
          $borderSize: '0.3rem',
        }
      }
      key={id}
      disablePadding
    >
      <ListItemButton onClick={() => onClick && onClick(id)} selected={selected}>
        <CallItemContainer>
          <CallItemIdentification>
            <StatusIcon
              icon={statusCode === 200 ? faCircleCheck
                : ((statusCode && faCircleXmark) || faCircleExclamation)}
              $status={statusCode || undefined}
            />
            <div>
              {action}
            </div>
          </CallItemIdentification>
          <div>
            {time}
          </div>
        </CallItemContainer>
      </ListItemButton>
    </BorderedListItem>
  );
}
