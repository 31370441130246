import { inputBaseClasses, Select } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 30rem;
  min-width: 220px;
`;

export const FullWidthSelect = styled(Select)`
  flex-grow: 1;

  .${inputBaseClasses.input} {
    padding: 8px 12px;
    padding-right: 32px;
  }
`;
