import { Grid } from '@mui/material';
import { styled } from 'styled-components';

export const PreviewContainer = styled.div`
  height: 844px;
  width: 390px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin: 0 2rem 2rem 1rem;
`;

export const PreviewIframe = styled.iframe`
  height: 844px;
  width: 390px;
  border: none;
`;

export const Preview = styled.div`
  position: relative;
  

  /* This overlay is used to prevent the iframe from being clicked */
  *&::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    border: 1px solid #000;
  }
`;

export const ControlButtons = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  flex-direction: row;
`;

export const PreviewControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
`;

export const SectionTitle = styled.span`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const TitleSection = styled.div`
  margin: 1rem;
`;
