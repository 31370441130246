import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import { GetAllWaitingLineTicketsItem } from 'lib/graphql/entities/tickets/types';
import { QueueType, TicketStatus, useValidateTicketMutation } from 'lib/graphql/graphql';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

function boldText(text: React.ReactNode) {
  return <b>{text}</b>;
}

interface ValidateTicketItemProps {
  onValidate: () => void
  ticket: GetAllWaitingLineTicketsItem
}

function ValidateTicketItem(props: ValidateTicketItemProps) {
  const { onValidate, ticket } = props;

  const feedback = useFeedback();

  const [validateTicket] = useValidateTicketMutation();

  const [modalOpen, setModalOpen] = useState(false);

  const handleValidateTicket = useCallback(async () => {
    try {
      await validateTicket({
        variables: {
          waitingLineId: ticket.waitingLineId,
          ticketId: ticket.id,
        },
      });
      onValidate();
      setModalOpen(false);
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (
          <FormattedMessage
            id="ticket_validated"
            values={{ displayId: ticket.displayId, b: boldText }}
          />
        ),
        severity: 'success',
      });
    } catch (err) {
      const error = err as Error;
      let errorId = '';
      switch (error.message) {
        case 'Ticket already used':
          errorId = 'operating_ticket_already_used';
          break;
        case 'Invalid ticket':
          errorId = 'operating_unknown_error';
          break;
        case 'Ticket not found':
          errorId = 'operating_ticket_not_found';
          break;
        default:
          errorId = 'operating_ticket_validation_generic_error';
          break;
      }

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id={errorId} />),
        severity: 'error',
      });
    }
  }, [validateTicket, ticket, feedback, setModalOpen, onValidate]);

  const disabled = (
    (ticket.queueType !== QueueType.Called && ticket.queueType !== QueueType.NoShow)
    || ticket.status === TicketStatus.Validated
  );

  return (
    <>
      <MenuItem
        disabled={disabled}
        onClick={() => setModalOpen(true)}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={faBadgeCheck} size="sm" />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="ticket_validate" />
        </ListItemText>
      </MenuItem>
      <ConfirmModal
        title="ticket_validate"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleValidateTicket}
        closeText="general_no"
        confirmText="general_yes"
      >
        <FormattedMessage id="ticket_validate_body" values={{ displayId: ticket.displayId, b: boldText }} />
      </ConfirmModal>
    </>
  );
}

export default ValidateTicketItem;
