/* cSpell:language fr, en */
/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": true}] */

import { WaitingLineStatus } from 'lib/graphql/graphql';

export default {
  accept: 'Accepter',
  accept_invitation_error: 'Une erreur s\'est produite lors de l\'acceptation de l\'invitation',
  accept_invitation_success: 'Invitation acceptée avec succès',
  account: 'Compte',
  account_needed: 'Un compte est requis pour accepter cette invitation',
  actions: 'Actions',

  add: 'Ajouter',
  add_obnl_error: 'Une erreur s\'est produite lors de l\'ajout de l\'organisation',
  add_supported_organization: 'Ajouter une organisation à soutenir',
  add_webhook: 'Nouveau webhook',

  apply: 'Appliquer',

  automatic_timezone_failed: 'Une erreur est survenue lors de la récupération automatique du fuseau horaire, veuillez le sélectionner manuellement',

  avatar_organization_create: 'Créer une organisation',
  avatar_organization_edit: 'Modifier l\'organisation',
  avatar_organization_manage: 'Gérer mes organisations',

  average_wait_time: 'Temps d\'attente moyen',

  back: 'Retour',
  back_to_webhook_list: 'Retour à la liste des webhooks',

  balance: 'Solde',

  cancel: 'Annuler',
  causes: 'Causes',
  causes_obnl_dialog_add_another: 'Voulez-vous ajouter une autre organisation à soutenir?',
  causes_obnl_dialog_delete_warning: 'Cette organisation ne sera plus soutenue par votre événement',
  causes_obnl_dialog_subheader: 'Elle fait maintenant partie des organisations que vous soutenez via la vente de vos laissez-passer MovOn.VIP',
  causes_obnl_dialog_title: 'Organisation ajoutée avec succès',
  change_password_error: 'Une erreur s\'est produite lors de la modification de votre mot de passe',
  change_password_incorrect_password: 'Votre mot de passe actuel est incorrect',
  change_password_link: 'Changer de mot de passe?',
  change_password_success: 'Votre mot de passe a été modifié avec succès',
  change_password_title: 'Changer de mot de passe',
  change_visibility_confirm_hidden: 'Êtes-vous sûr de vouloir archiver cet événement?',
  change_visibility_error: 'Une erreur s\'est produite lors du changement de statut de l\'événement',
  change_visibility_success: 'Statut de l\'événement mis à jour avec succès',
  change_visibility_to_hidden: 'L\'événement ne sera plus visible pour les visiteurs et ils ne seront plus en mesure d\'acheter de laissez-passer',
  check_email_not_altered: 'Veuillez vous assurer que votre client de messagerie n\'a pas modifié l\'URL de quelconque façon ou que vous n\'avez pas déjà vérifié l\'adresse courriel.',
  check_your_email: 'Vérifiez votre courriel',
  choose_for_me: 'Choisir pour moi',
  choose_obnl_for_me: 'Choisir une organisation pour moi',
  choose_obnl_to_support: 'Choisir une organisation à soutenir',
  choose_one_goal_from_sdg_description: 'Choisissez un objectif parmi les objectifs de développement durable adoptés par les Nations Unies. Vous pourrez en ajouter d\'autres plus tard.',
  choose_one_goal_from_sdg_title: 'Choisir un objectif',
  clear: 'Effacer',
  click_link_email_to_confirm: 'Veuillez cliquer sur le lien dans le courriel que nous venons de vous envoyer pour confirmer votre adresse courriel.',
  collapse: 'Réduire',
  confirm_password: 'Confirmer le mot de passe',
  confirm_your_email: 'Confirmer votre courriel',
  control_number: 'Numéro de contrôle',
  create_event: 'Créer un événement',
  create_event_description: 'Entrer la description de l\'événement',
  create_event_details: 'Ajoutez les détails de votre événement et créez des files d\'attente VIP',
  create_event_error: 'Une erreur s\'est produite lors de la création de l\'événement',
  create_event_name: 'Entrer le nom de l\'événement',
  create_event_short_description: 'Entrer une courte description de votre événement',
  create_event_success: 'Événement créé avec succès',
  create_invitation_error_already_exists: 'Une invitation existe déjà pour ce courriel',
  create_invitation_success: 'Invitation créée avec succès',
  create_invoice: 'Générer une facture',
  create_invoice_disabled: 'La génération de facture est disponible uniquement pour les paiements payés',
  create_organization: 'Créer une organisation',
  create_organization_description: 'Entrer une courte description qui sera visible dans la liste des organisations',
  create_organization_language: 'Sélectionner la langue qui sera utilisée par défaut pour les champs',
  create_organization_name: 'Entrer le nom complet de l\'organisation',
  create_organization_success: 'Organisation créée avec succès',
  create_waiting_line: 'Créer une file d\'attente',
  create_waiting_line_error: 'Une erreur s\'est produite lors de la création de la file d\'attente',
  create_waiting_line_success: 'File d\'attente créée avec succès',
  create_webhook: 'Créer le webhook',
  create_webhook_error: 'Une erreur s\'est produite lors de la création du webhook',
  create_webhook_success: 'Webhook créé avec succès',
  current_time_slot: 'Plage horaire actuelle',

  dashboard: 'Tableau de bord',
  dashboard_begin_here: 'Commencez ici',
  date: 'Date',
  date_field_next_month: 'Mois suivant',
  date_field_open_next_view: 'Ouvrir la vue suivante',
  date_field_open_previous_view: 'Ouvrir la vue précédente',
  date_field_previous_month: 'Mois précédent',
  delete: 'Supprimer',
  delete_confirm: 'Êtes-vous sûr de vouloir supprimer?',
  delete_event_success: 'Événement supprimé avec succès',
  delete_invitation_error: 'Une erreur s\'est produite lors de la suppression de l\'invitation',
  delete_invitation_success: 'Invitation supprimée avec succès',
  delete_irreversible: 'Cette action est irréversible',
  delete_obnl_error: 'Vous devez soutenir au moins une organisation, veuillez d\'abord en ajouter une autre si vous voulez supprimer celle-ci',
  delete_obnl_success: 'Organisation retirée avec succès',
  delete_organization_success: 'Organisation supprimée avec succès',
  delete_waiting_line_success: 'File d\'attente supprimée avec succès',
  description: 'Description',
  did_not_get_it: 'Vous n\'avez rien reçu?',
  dont_have_an_account: 'Vous n\'avez pas de compte? ',

  edit: 'Modifier',
  email: 'Courriel',
  enter_confirm_password: 'Entrez votre mot de passe à nouveau',
  enter_email: 'Entrez votre courriel',
  enter_new_password: 'Entrez votre nouveau mot de passe',
  enter_password: 'Entrez votre mot de passe',
  errors_in_form: 'Une ou des erreurs sont présentes dans le formulaire, veuillez les corriger avant de continuer',
  event: 'Événement',
  event_begin: 'Début de l\'événement',
  event_begin_title: 'Quand votre événement commence-t-il?',
  event_create: 'Créer un nouvel événement',
  event_date_invalid: 'Date invalide',
  event_end: 'Fin de l\'événement',
  event_end_error: 'La fin de l\'événement doit être après son début',
  event_end_error_future: 'La fin de l\'événement doit être dans le futur',
  event_end_title: 'Quand votre événement se termine-t-il?',
  event_ends: 'Termine le',
  event_hide_archived: 'Masquer les événements archivés',
  event_image: 'Image de l\'événement',
  event_image_description: 'Téléversez une image représentant votre événement (ratio de 16:9 recommandé)',
  event_information: 'Informations sur l\'événement',
  event_languages: 'Langues de l\'événement',
  event_languages_description: 'Langues dans lesquelles les informations de l\'événement seront affichées. La première sera la langue par défaut.',
  event_location: 'Lieu de l\'événement',
  event_location_placeholder: 'Commencez à taper une adresse...',
  event_media_browse: 'Parcourir',
  event_media_drag: 'Glisser et déposer un fichier ou',
  event_media_file_invalid_type: 'Type de fichier invalide, doit être en JPG, JPEG ou PNG',
  event_media_supported: 'Fichiers supportés: JPG et PNG',
  event_my_events: 'Mes événements',
  event_name: 'Nom de l\'événement',
  event_no_events: 'Aucun événement à afficher',
  event_not_found: 'Événement non trouvé',
  event_payment: 'Configuration des paiements',
  event_publish_again: 'Vous devez publier l\'événement de nouveau pour le rendre visible aux visiteurs',
  event_publish_disabled: 'Publier l\'événement pour déverrouiller cette fonctionnalité',
  event_publish_invite: 'Invitez des membres de l\'équipe pour gérer votre événement',
  event_publish_next: 'Prochaine étape?',
  event_publish_no_causes: 'Vous devez sélectionner une cause pour publier l\'événement',
  event_publish_no_waiting_lines: 'Vous devez créer une file d\'attente pour publier l\'événement',
  event_publish_not_validated_by_movon: 'Votre organisation n\'est pas validée par MovOn.VIP',
  event_publish_operate: 'Gérez les scans et les ventes de laissez-passer',
  event_publish_set_display: 'Préparez l\'affichage de vos files d\'attente',
  event_published: 'Votre événement est publié!',
  event_qr_copied: 'Lien copié avec succès',
  event_qr_copy_error: 'Une erreur s\'est produite lors de la copie du lien',
  event_qr_download: 'Télécharger le code QR',
  event_qr_download_error: 'Une erreur s\'est produite lors du téléchargement du code QR',
  event_qr_downloaded: 'Code QR téléchargé avec succès',
  event_share_link: 'Partagez le lien de votre événement',
  event_short_url_description: 'Entrez un lien court facile à taper pour accéder à votre événement',
  event_side_configuration: 'Configuration',
  event_side_integrations: 'Intégrations',
  event_side_management: 'Gestion',
  event_starts: 'Débute le',
  event_status_draft: 'Brouillon',
  event_status_hidden: 'Archivé',
  event_status_published: 'Publié',
  event_support: 'Configuration du support',
  event_theme_colors: 'Thèmes de couleur',
  event_theme_home: 'Accueil',
  event_theme_pages: 'Pages',
  event_theme_preview: 'Aperçu',
  event_theme_save: 'Sauvegarder',
  event_theme_save_error: 'Une erreur s\'est produite lors de la sauvegarde du thème',
  event_theme_saved: 'Thème sauvegardé',
  event_theme_waiting_lines: 'Files d\'attente',
  event_timezone: 'Fuseau horaire de l\'événement',
  event_type: 'Type d\'événement',
  event_unpublish: 'Archiver',
  event_update: 'Informations sur l\'événement',
  event_view_display: 'Voir l\'affichage',
  events: 'Événements',
  expand: 'Agrandir',
  explanations: 'Explications',

  field_decimals: 'Le nombre de décimales doit être de 2 ou moins',
  field_format: 'Format invalide (utilisez un point pour séparer les décimales)',
  field_max_length_default: 'Le texte dépasse la limite de caractères',
  field_min_default: 'La valeur minimale est 1',
  field_minimum_price: 'Le prix minimum est de 2.00',
  field_positive: 'La valeur doit être positive',
  field_required: 'Ce champ est requis',
  filter_all: 'Tout',
  filter_all_status: 'Tous les statuts',
  filter_all_waiting_lines: 'Toutes les files d\'attente',
  filter_last_week: 'Semaine dernière',
  filter_no_balance: 'Aucun solde avec les filtres actuels',
  filter_no_payout: 'Aucun paiement avec les filtres actuels',
  filter_this_month: 'Ce mois',
  filter_today: 'Aujourd\'hui',
  filter_yesterday: 'Hier',
  filters: 'Filtres',
  financial_report: 'Rapport financier',
  finish: 'Terminer',
  forgot_password: 'Mot de passe oublié?',
  funds_in_stripe_account: 'Fonds dans votre compte Stripe',

  general_close: 'Fermer',
  general_date_time_picker_title: 'Sélectionner la date et l\'heure',
  general_generate: 'Générer',
  general_lorem_ipsum_short: 'Lorem ipsum',
  general_no: 'Non',
  general_permission_error: 'Vous n\'avez pas la permission de faire cette action',
  general_remaining: 'Restant',
  general_time_from: 'Du',
  general_time_sales_end: 'Fin des ventes',
  general_time_sales_start: 'Début des ventes',
  general_time_to: 'Au',
  general_update: 'Mettre à jour',
  general_yes: 'Oui',

  generic_graphql_error: 'Une erreur s\'est produite, veuillez réessayer. Si le problème persiste, veuillez contacter le support.',

  go_back_to_login: 'Revenir à l\'écran de connexion',

  have_an_account: 'Vous avez un compte? ',

  information: 'Informations',
  invalid_credentials: 'Identifiants invalides',
  invalid_email: 'Courriel doit contenir un "@" suivi du domaine et de son extension',

  invalid_format: 'Format invalide',

  invitation_invalid_email_tooltip: 'Cette adresse est invalide et cette invitation ne peut donc pas être acceptée. Vérifiez que l\'adresse est correcte avant de créer une nouvelle invitation. Vous pouvez supprimer cette invitation.',
  invitation_status_accepted: 'Acceptée',
  invitation_status_pending: 'En attente',
  invitations: 'Invitations',
  invite: 'Inviter',
  invite_user: 'Inviter un membre de l\'équipe',

  invoice: 'Facture',
  invoice_bill_to: 'Facturé à',
  invoice_date: 'Date de la facture: {date}',
  invoice_gst: 'TPS (5%)',
  invoice_gst_id: 'Numéro de TPS',
  invoice_number: 'Numéro de la facture: {number}',
  invoice_number_field: 'Numéro de la facture',
  invoice_payment_from: 'Paiement du {date}',
  invoice_payment_id: 'ID du paiement: {id}',
  invoice_price: 'Prix',
  invoice_qst: 'TVQ (9,975%)',
  invoice_qst_id: 'Numéro de TVQ',
  invoice_total: 'Total',

  language_default: ' (défaut)',
  language_en: 'Anglais',
  language_es: 'Espagnol',
  language_fr: 'Français',

  loading: 'Chargement',
  login: 'Se connecter',
  logout: 'Se déconnecter',

  mediakit_branding_guide: 'Guide de marque',
  mediakit_download: 'Télécharger',
  mediakit_download_kit: 'Téléchargez votre kit média',
  mediakit_download_zip: 'Télécharger (zip)',
  mediakit_file_size: '{size} ko',
  mediakit_logos: 'Logos',
  mediakit_number_files: '{number} fichiers',
  mediakit_number_pictures: '{number} images',
  mediakit_operating: 'Guide d\'utilisation',
  mediakit_posters: 'Affiches',
  mediakit_qr: 'Codes QR',
  mediakit_subtitle: 'Téléchargez tout ce dont vous avez besoin pour faire de votre événement un succès',
  mediakit_view_files: 'Afficher tous les fichiers',
  mediakit_view_pictures: 'Afficher toutes les images',

  name: 'Nom',
  new_email_error: 'Une erreur s\'est produite lors de l\'envoi du courriel, veuillez réessayer',
  new_email_sent: 'Un nouveau courriel a été envoyé, veuillez vérifier votre boîte de réception',
  new_event: 'Nouvel événement',
  new_password: 'Nouveau mot de passe',
  next: 'Suivant',
  next_step: 'Étape suivante',
  next_time_slot: 'Prochaine plage horaire',
  no_invitations: 'Aucune invitation.',
  no_obnl_available_error: 'Vous avez déjà ajouté toutes les organisations disponibles, vous ne pouvez pas en ajouter plus',
  no_supported_organizations_found: 'Aucune organisation trouvée.',

  objectives_and_organizations: 'Objectifs et organismes',
  obnl_already_selected: 'Organisation déjà choisie',
  obnl_cities: 'Villes et communautés durables',
  obnl_cities_description: 'Rendre les villes inclusives, sûres et durables.',
  obnl_climate: 'Mesures contre les changements climatiques',
  obnl_climate_description: 'Agir d\'urgence pour lutter contre le changement climatique.',
  obnl_consumption: 'Consommation et production responsables',
  obnl_consumption_description: 'Garantir des modes de consommation et de production durables.',
  obnl_education: 'Éducation de qualité',
  obnl_education_description: 'Garantir une éducation inclusive, équitable et de qualité.',
  obnl_energy: 'Énergie abordable et propre',
  obnl_energy_description: 'Garantir l\'accès à une énergie abordable, fiable et durable.',
  obnl_gender: 'Égalité entre les sexes',
  obnl_gender_description: 'Réaliser l\'égalité des sexes et l\'autonomisation de toutes les femmes et de toutes les filles.',
  obnl_health: 'Bonne santé et bien-être',
  obnl_health_description: 'Garantir une vie saine et promouvoir le bien-être de tous à tout âge.',
  obnl_hunger: 'Faim zéro',
  obnl_hunger_description: 'Assurer la sécurité alimentaire et améliorer la nutrition.',
  obnl_industry: 'Industrie, innovation et infrastructure',
  obnl_industry_description: 'Construire des infrastructures résilientes et favoriser l\'innovation.',
  obnl_inequalities: 'Inégalités réduites',
  obnl_inequalities_description: 'Réduire les inégalités au sein des pays et entre eux.',
  obnl_life_land: 'Vie terrestre',
  obnl_life_land_description: 'Protéger, restaurer et promouvoir l\'utilisation durable des écosystèmes terrestres.',
  obnl_partnerships: 'Partenariats pour la réalisation des objectifs',
  obnl_partnerships_description: 'Renforcer les partenariats mondiaux pour le développement durable.',
  obnl_peace: 'Paix, justice et institutions efficaces',
  obnl_peace_description: 'Promouvoir des sociétés pacifiques et inclusives.',
  obnl_poverty: 'Pas de pauvreté',
  obnl_poverty_description: 'Mettre fin à la pauvreté sous toutes ses formes et partout.',
  obnl_water: 'Eau propre et assainissement',
  obnl_water_description: 'Assurer la disponibilité et la gestion durable de l\'eau et de l\'assainissement pour tous.',
  obnl_water_life: 'Vie aquatique',
  obnl_water_life_description: 'Conserver et utiliser durablement les océans, les mers et les ressources marines.',
  obnl_work: 'Travail décent et croissance économique',
  obnl_work_description: 'Promouvoir une croissance économique inclusive et durable.',
  obnls_breakdown: 'Répartition des organisations soutenues',
  operating_invalid_waiting_line: 'Voulez-vous tout de même valider ce laissez-passer?',
  operating_mixed_validation: 'Voulez-vous tout de même valider {count, plural, one {le} other {les}} {number} laissez-passer non validés?',
  operating_multiple_codes_detected: 'Plusieurs codes QR détectés',
  operating_ticket_already_called: 'Déjà appelé',
  operating_ticket_already_used: 'Déjà validé',
  operating_ticket_call_generic_error: 'Une erreur s\'est produite lors de l\'appel du laissez-passer',
  operating_ticket_confirmation: 'Confirmation',
  operating_ticket_mixed: 'Ce code QR contient des laissez-passer déjà validés',
  operating_ticket_not_found: 'Laissez-passer non trouvé',
  operating_ticket_not_valid: 'Non valide',
  operating_ticket_validation_generic_error: 'Une erreur s\'est produite lors de la validation du laissez-passer',
  operating_ticket_wrong_waiting_line: 'Mauvaise file',
  operating_unknown_error: 'Format de code QR inconnu',
  operations: 'Opérations',
  operator: 'Opérateur',
  operator_description: 'Scannez les laissez-passer et gérez votre événement.',
  operator_tickets: 'Laissez-passer scannés',
  operator_tickets_call: 'Appelez les {callSize} prochains visiteurs',
  operator_tickets_called: 'Laissez-passer appelés avec succès',
  operator_tickets_error: 'Une erreur s\'est produite lors de l\'appel des laissez-passer',
  operator_tickets_no_ticket: 'Aucun laissez-passer à appeler',

  organization: 'Organisation',
  organization_create: 'Créer une organisation',
  organization_language: 'Langue par défaut',
  organization_location: 'Adresse de l\'organisation',
  organization_name: 'Nom de l\'organisation',
  organization_none: 'Aucune organisation à afficher. Créer une organisation ou être invité à une organisation pour commencer.',
  organization_not_found: 'Organisation non trouvée',
  organization_update: 'Informations sur l\'organisation',
  organizations: 'Organisations',

  page_forbidden_description: 'Vous n\'êtes pas autorisé à accéder à cette page.',
  page_forbidden_title: 'Erreur 403',

  page_not_found_description: 'La page à laquelle vous avez essayé d\'accéder n\'existe pas, veuillez vérifier l\'URL.',
  page_not_found_title: 'Erreur 404',

  pass_percentage_donated_to_cause: 'Pour chaque laissez-passer acheté par vos visiteurs, un pourcentage du coût sera versé aux causes que vous avez choisies.',

  password: 'Mot de passe',
  password_too_short: 'Mot de passe doit contenir au moins 8 caractères',
  passwords_do_not_match: 'Les mots de passe ne correspondent pas',

  payout: 'Paiement',
  payout_amount: 'Montant du paiement',
  payout_arrival_date: 'Date de réception prévue',
  payout_created_date: 'Date d\'émission',
  payout_in_days: 'Dans {days, plural, one {# jour} other {# jours}}',
  payout_status_canceled: 'Annulé',
  payout_status_failed: 'Échoué',
  payout_status_in_transit: 'En transit',
  payout_status_paid: 'Payé',
  payout_status_pending: 'En attente',
  payout_today: 'Aujourd\'hui',
  payout_tomorrow: 'Demain',
  payout_yesterday: 'Hier',
  payouts: 'Paiements',
  payouts_available_funds: 'Fonds disponibles',
  payouts_available_funds_tooltip: 'Ce montant représente les fonds qui sont admissibles pour un paiement.',
  payouts_no_funds: 'Aucune vente',
  payouts_pending_funds: 'Fonds bientôt disponibles',
  payouts_pending_funds_tooltip: 'Ce montant représente les fonds en cours de traitement et ne sont pas admissibles pour un paiement. Une fois que ces fonds seront disponibles, vous verrez le montant dans la section "Fonds disponibles".',
  payouts_to_bank_account: 'Paiements vers votre compte bancaire',
  payouts_total_volume: 'Volume total depuis l\'inscription',
  payouts_total_volume_tooltip: 'Ce montant représente votre part total des ventes (excluant les remboursements) pour l\'ensemble de vos événements depuis la création de votre compte.',

  please_try_with_new_cause: 'Veuillez essayer de nouveau avec une nouvelle cause.',
  position: 'Position',
  previous: 'Précédent',
  previous_step: 'Étape précédente',
  price: 'Prix',

  privacy_policy: 'Politique de confidentialité',
  privacy_policy_1_content: 'MovOn.VIP (le "Site") est exploité par Novom Interactive (l\'"Opérateur"). La présente politique de confidentialité (la "Politique") décrit comment l\'Opérateur collecte, utilise et divulgue vos renseignements personnels. En accédant et en utilisant le Site, vous acceptez d\'être lié par la présente Politique. Si vous n\'acceptez pas la présente Politique, vous ne devez pas accéder au Site ou l\'utiliser.',
  privacy_policy_1_title: '1. Introduction',
  privacy_policy_2_content: 'L\'Opérateur collecte les renseignements personnels suivants auprès de vous :'
    + '<ul>'
    + '<li><b>Renseignements personnels que vous fournissez volontairement</b> : Il s\'agit notamment de votre adresse courriel, votre numéro de téléphone, de votre mot de passe et d\'autres informations que vous fournissez lorsque vous créez un compte sur le Site ou lorsque vous utilisez certaines fonctionnalités du Site.</li>'
    + '<li><b>Renseignements personnels collectés automatiquement</b> : Lorsque vous utilisez le Site, l\'Opérateur collecte automatiquement certains renseignements personnels vous concernant, tels que votre adresse IP, votre navigateur Web et votre système d\'exploitation. Ces renseignements sont utilisés pour améliorer les performances du Site et à des fins d\'analyse.</li>'
    + '<li><b>Renseignements personnels provenant de tiers</b> : L\'Opérateur ne peut pas collecter des renseignements personnels vous concernant auprès de tiers, tels que des réseaux sociaux.</li>'
    + '</ul>',
  privacy_policy_2_title: '2. Collecte de renseignements personnels',
  privacy_policy_3_content: 'L\'Opérateur utilise vos renseignements personnels aux fins suivantes :'
    + '<ul>'
    + '<li><b>Pour vous fournir les services du Site</b> : L\'Opérateur utilise vos renseignements personnels pour vous fournir les services du Site, tels que la création d\'un compte, la publication de contenu et la communication avec d\'autres utilisateurs.</li>'
    + '<li><b>Pour améliorer le Site</b> : L\'Opérateur utilise vos renseignements personnels pour améliorer le Site et ses services.</li>'
    + '<li><b>Pour vous contacter</b> : L\'Opérateur peut utiliser vos renseignements personnels pour vous contacter au sujet du Site ou de vos comptes.</li>'
    + '<li><b>À des fins de marketing</b> : L\'Opérateur peut utiliser vos renseignements personnels pour vous envoyer des communications marketing, telles que un courriels de remerciements et des sondages de satisfaction. Dans toutes les communications, le processus de désabonnement est simple et accessible.</li>'
    + '</ul>',
  privacy_policy_3_title: '3. Utilisation des renseignements personnels',
  privacy_policy_4_content: 'L\'Opérateur peut divulguer vos renseignements personnels aux tiers suivants :'
    + '<ul>'
    + '<li><b>Fournisseurs de services tiers</b> : L\'Opérateur peut divulguer vos renseignements personnels à des fournisseurs de services tiers qui l\'aident à exploiter le Site, tels que des fournisseurs d\'hébergement Web et des fournisseurs de services de paiement.</li>'
    + '<li><b>Autorités gouvernementales</b> : L\'Opérateur peut divulguer vos renseignements personnels aux autorités gouvernementales si la loi l\'exige ou si l\'Opérateur croit de bonne foi que la divulgation est nécessaire pour protéger ses droits ou les droits de tiers.</li>'
    + '</ul>',
  privacy_policy_4_title: '4. Divulgation des renseignements personnels',
  privacy_policy_5_content: 'Vous pouvez exercer les droits suivants concernant vos renseignements personnels :'
    + '<ul>'
    + '<li><b>Accès à vos renseignements personnels</b> : Vous avez le droit d\'accéder à vos renseignements personnels que l\'Opérateur détient.</li>'
    + '<li><b>Correction de vos renseignements personnels</b> : Vous avez le droit de faire corriger les renseignements personnels inexacts ou incomplets que l\'Opérateur détient.</li>'
    + '<li><b>Suppression de vos renseignements personnels</b> : Vous avez le droit de demander que l\'Opérateur supprime vos renseignements personnels.</li>'
    + '<li><b>Limitation du traitement de vos renseignements personnels</b> : Vous avez le droit de demander que l\'Opérateur limite le traitement de vos renseignements personnels.</li>'
    + '<li><b>Opposition au traitement de vos renseignements personnels</b> : Vous avez le droit de vous opposer au traitement de vos renseignements personnels par l\'Opérateur.</li>'
    + '</ul>'
    + 'Pour exercer vos droits, veuillez contacter Eric Foley à <mailto>support@movon.vip</mailto>.',
  privacy_policy_5_title: '5. Vos choix',
  privacy_policy_6_content: 'L\'Opérateur prend des mesures de sécurité raisonnables pour protéger vos renseignements personnels contre la perte, le vol, l\'utilisation abusive, la divulgation non autorisée, l\'accès, la modification ou la destruction.',
  privacy_policy_6_title: '6. Sécurité des renseignements personnels',
  privacy_policy_7_content: 'L\'Opérateur conservera vos renseignements personnels pendant le temps nécessaire pour remplir les fins pour lesquelles ils ont été collectés, ou pendant une période plus longue si la loi l\'exige ou le permet.',
  privacy_policy_7_title: '7. Conservation des renseignements personnels',
  privacy_policy_8_content: 'L\'Opérateur peut modifier la présente Politique à tout moment en publiant la Politique modifiée sur le Site. Les modifications de la Politique entreront en vigueur dès leur publication. Vous êtes responsable de la lecture régulière de la Politique pour vous assurer que vous êtes au courant de toutes les modifications.',
  privacy_policy_8_title: '8. Modifications de la Politique',
  privacy_policy_9_content: 'Si vous avez des questions concernant la présente Politique, veuillez contacter Eric Foley à <mailto>support@movon.vip</mailto>.',
  privacy_policy_9_title: '9. Contact',

  profile: 'Profil',
  publish: 'Publication',
  publish_event: 'Publier l\'événement',
  publish_event_error: 'Une erreur s\'est produite lors de la publication de l\'événement',
  publish_event_step_1: 'Remplir les détails de l\'événement',
  publish_event_step_2: 'Créer au moins une file d\'attente VIP',
  publish_event_step_3: 'Sélectionner vos causes',
  publish_event_step_4: 'Avoir lié un compte Stripe dans l\'organisation pour recevoir les paiements (facultatif)',
  publish_event_step_5: 'Avoir validé son organisation MovOn.VIP.',
  publish_event_success: 'Événement publié avec succès',
  publish_review_info: 'Révisez vos informations',

  qr_reader: 'Lecteur QR',
  qr_reader_error: 'Échec du démarrage du lecteur',
  qr_reader_instructions: 'Centrez le code QR dans la boîte',
  qr_reader_pass_left: '{nbr} restants dans la file',
  qr_reader_recent_scans: 'Mes scans récents',
  qr_reader_scanned_passes: '{nbr} laissez-passer',

  refresh: 'Actualiser',

  resend_email: 'Renvoyer le courriel',

  reset_password: 'Réinitialiser le mot de passe',
  reset_password_error: 'Une erreur s\'est produite lors de la réinitialisation du mot de passe. Veuillez réessayer ou faire une nouvelle demande de réinitialisation.',

  return_to_dashboard: 'Retour au tableau de bord',
  return_to_events: 'Retour aux événements',
  return_to_login: 'Retour à la connexion',
  return_to_organizations: 'Retour aux organisations',
  return_to_users: 'Retour aux membres de l\'équipe',
  return_to_waiting_lines: 'Retour aux files d\'attente',
  role: 'Rôle',
  role_admin: 'Administrateur',
  role_staff: 'Personnel',

  sale_price: 'Prix de vente',
  sales_breakdown: 'Répartition des ventes',

  save_changes: 'Sauvegarder les modifications',
  search: 'Recherche',
  select: 'Sélectionner',
  select_goal_and_organization: 'Choisissez un objectif et une organisation qui vous tiennent à coeur',
  select_tickets_types: 'Quels types de laissez-passer voulez-vous créer pour la file d\'attente principale?',
  send: 'Envoyer',
  sent_password_recover_instructions: 'Nous avons envoyé des instructions de récupération de mot de passe à votre courriel.',
  settings: 'Paramètres',
  short_url: 'Lien court',
  short_url_available: 'Ce lien court est disponible',
  short_url_dev_warning: 'Le mode de développement est activé',
  short_url_dev_warning_checkbox: 'Activer quand même les liens courts à des fins de test',
  short_url_dev_warning_description: 'Les liens courts sont désactivés par défaut en développement afin de ne pas atteindre les limites de l\'API de raccourcissement d\'URL.',
  short_url_taken: 'Ce lien court est déjà utilisé',
  show_supported_organizations: 'N\'avoir qu\'une seule organisation sélectionnée ignorera l\'étape de sélection des causes lors de l\'achat d\'un laissez-passer et utilisera celle-ci par défaut.',
  sign_up: 'S\'inscrire',
  start_selection: 'Commencer la sélection',
  status: 'Statut',

  steps_to_redeem_pass: 'Décrire les étapes ou la procédure à suivre pour que les visiteurs puissent utiliser leur laissez-passer',

  stripe_account_not_linked_error: 'Un compte Stripe n\'est pas lié à cette organisation, veuillez en associer un pour publier des événements',
  stripe_already_linked: 'Compte Stripe déjà lié',
  stripe_close_window: 'Vous pouvez désormais fermer cet onglet.',
  stripe_congratulations: 'Félicitations!',
  stripe_dashboard: 'Tableau de bord Stripe',
  stripe_error: 'Échec du processus de liaison Stripe',
  stripe_fees: 'Frais Stripe',
  stripe_landing_linked: 'Votre compte Stripe est désormais lié à l\'organisation {organization}',
  stripe_landing_linked_error: 'Une erreur s\'est produite lors de la liaison de votre compte Stripe à l\'organisation {organization}',
  stripe_link_account: 'Lier à un compte Stripe',
  stripe_need_info: 'Compléter votre compte',
  stripe_not_linked: 'Aucun compte Stripe lié',
  stripe_support: 'Un compte Stripe est requis.',
  stripe_support_description: 'Tant que vous n\'avez pas lié un compte Stripe, vous ne pourrez pas recevoir votre part des profits de la vente de laissez-passer. Les visiteurs pourront toujours acheter des laissez-passer.',
  stripe_support_description_linked: 'L\'argent des ventes de laissez-passer sera collecté par MovOn.VIP et vos profits seront directement transférés sur votre compte Stripe.',
  stripe_support_linked: 'Votre compte Stripe est lié, mais des informations supplémentaires sont requises avant de recevoir votre premier paiement.',
  stripe_support_linked_payouts_enabled: 'Votre compte Stripe est lié et prêt à recevoir des paiements.',

  suggest_random_organization: 'Laissez-nous vous proposer une organisation au hasard',

  support: 'Support',
  support_all_causes: 'Soutenir toutes les causes',
  supported_organizations: 'Organisations soutenues',
  sustainable_development_goals: 'Objectifs de développement durable',

  taxes: 'Taxes',

  terms_conditions: 'Termes & conditions',
  terms_conditions_1_content: 'Bienvenue sur l\'application web de MovOn.VIP (le "Site"). Le Site est exploité par Novom Interactive (l\'"Opérateur"). En accédant et en utilisant le Site, vous acceptez d\'être lié par les présentes Conditions Générales d\'Utilisation (les "Conditions"). Si vous n\'acceptez pas les présentes Conditions, vous ne devez pas accéder au Site ou l\'utiliser.',
  terms_conditions_1_title: '1. Introduction',
  terms_conditions_2_content: 'MovOn.VIP s\'engage à respecter la loi 25 du Québec et le Règlement général sur la protection des données (RGPD) en ce qui concerne la collecte, l\'utilisation et la divulgation de vos renseignements personnels. Pour plus d\'informations sur nos pratiques en matière de protection des données, veuillez consulter notre Politique de confidentialité.',
  terms_conditions_2_title: '2. Respect de la loi 25 et du RGPD',
  terms_conditions_3_content: 'MovOn.VIP ne vendra jamais vos renseignements personnels à des tiers. Nous pouvons partager vos renseignements personnels avec des fournisseurs de services tiers qui nous aident à exploiter le Site, mais nous ne le ferons que dans la mesure où cela est nécessaire pour fournir ces services.',
  terms_conditions_3_title: '3. Aucune vente de données à des tiers',
  terms_conditions_4_content: 'Tout le contenu du Site, y compris les textes, les images, les vidéos et les logiciels, est protégé par le droit d\'auteur et d\'autres droits de propriété intellectuelle de l\'Opérateur ou de ses concédants de licence. Vous ne pouvez pas utiliser le contenu du Site sans le consentement écrit préalable de l\'Opérateur.',
  terms_conditions_4_title: '4. Propriété intellectuelle',
  terms_conditions_5_content: 'MovOn.VIP vous accorde une licence non exclusive et non transférable d\'utilisation du Site. Vous n\'êtes pas autorisé à utiliser le Site à des fins illégales ou nuisibles, ou à violer les droits de l\'Opérateur ou de tiers.',
  terms_conditions_5_title: '5. Autorisation octroyée aux utilisateurs',
  terms_conditions_6_content: 'MovOn.VIP ne peut être tenu responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs, y compris les pertes de profits, de clientèle ou de données, résultant de l\'utilisation ou de l\'impossibilité d\'utiliser le Site, même si MovOn.VIP a été informé de la possibilité de tels dommages.',
  terms_conditions_6_title: '6. Limitation de responsabilité',
  terms_conditions_7_content: 'Les présentes Conditions sont régies par les lois de la province de Québec et interprétées conformément à celles-ci. Tout litige découlant de ou lié aux présentes Conditions sera soumis à la juridiction exclusive des tribunaux compétents de la province de Québec.',
  terms_conditions_7_title: '7. Lois applicables et juridiction',
  terms_conditions_8_content: 'MovOn.VIP peut modifier les présentes Conditions à tout moment en publiant les Conditions modifiées sur le Site. Les Conditions modifiées entreront en vigueur dès leur publication. Vous êtes responsable de la lecture régulière des Conditions pour vous assurer que vous êtes au courant de toutes les modifications.',
  terms_conditions_8_title: '8. Modifications des Conditions',
  terms_conditions_9_content: 'La version française des présentes Conditions prévaudra sur toute autre traduction.',
  terms_conditions_9_title: '9. Langue de traduction',
  terms_conditions_10_content: 'Si vous avez des questions concernant les présentes Conditions, veuillez contacter MovOn.VIP à <mailto>support@movon.vip</mailto>.',
  terms_conditions_10_title: '10. Contact',
  terms_conditions_copyright: '© {year} MovOn.VIP',

  theme: 'Thème',
  theme_description: 'Choisissez le thème de couleur qui correspond le mieux à votre événement',
  theme_title: 'Thème de couleur de l\'événement',

  ticket_call: 'Appeler',
  ticket_call_body: 'Voulez-vous appeler le laissez-passer <b>#{displayId}</b>?',
  ticket_called: 'Le laissez-passer <b>#{displayId}</b> a été appelé',

  ticket_id: 'ID du laissez-passer',

  ticket_more_information: 'Plus d\'informations',
  ticket_no_communication_address: 'Aucune adresse de communication',

  ticket_operation_called: 'Appelé',
  ticket_operation_created_at: 'Date de réservation',
  ticket_operation_display_id: 'Laissez-passer',
  ticket_operation_no_show: 'Ne s\'est pas présenté',
  ticket_operation_status: 'Statut',
  ticket_operation_transaction_id: 'Transaction',

  ticket_send_sms: 'Envoyer un SMS',
  ticket_status: 'Statut',

  ticket_status_ACTIVATED: 'Vendu',
  ticket_status_CANCELED: 'Annulé',
  ticket_status_PENDING: 'En attente de paiement',
  ticket_status_REFUNDED: 'Remboursé',
  ticket_status_RESERVED: 'Réservé',
  ticket_status_VALIDATED: 'Utilisé',

  ticket_transaction_id: 'ID de transaction',

  ticket_validate: 'Valider',
  ticket_validate_body: 'Voulez-vous valider le laissez-passer <b>#{displayId}</b>',
  ticket_validated: 'Le laissez-passer <b>#{displayId}</b> est maintenant validé',

  tickets_begin: 'Début de la vente des laissez-passer',
  tickets_begin_title: 'Quand commence la vente des laissez-passer?',
  tickets_end: 'Fin de la vente des laissez-passer',
  tickets_end_title: 'Quand se termine la vente des laissez-passer?',
  tickets_for_main_waiting_line: 'Laissez-passer pour la file d\'attente principale',

  total: 'Total',
  total_payouts: 'Total des paiements payés',
  total_sales: 'Ventes totales',
  total_sales_queue: 'Répartition de votre solde par file d\'attente',
  total_transactions: 'Total des transactions',

  transaction: 'Transaction',
  transactions: 'Transactions',

  translations: 'Traductions',

  try_again: 'Réessayer',

  unable_to_verify_email: 'Nous n\'avons pas pu vérifier votre adresse courriel',

  unsaved_form_quit_confirmation_body: 'Êtes-vous sùr de vouloir quitter sans sauvegarder?',
  unsaved_form_quit_confirmation_title: 'Vous avez des changements non sauvegardés',

  update_error_missing_translation: 'Une ou plusieurs traductions sont manquantes pour {count, plural, one {la langue suivante} other {les langues suivantes}}: {languages}',
  update_event: 'Modifier un événement',
  update_event_error: 'Une erreur s\'est produite lors de la mise à jour de l\'événement',
  update_event_success: 'Événement mis à jour avec succès',
  update_organization: 'Modifier une organisation',
  update_organization_success: 'Organisation mise à jour avec succès',
  update_waiting_line: 'Modifier la file d\'attente',
  update_waiting_line_error: 'Une erreur s\'est produite lors de la mise à jour de la file d\'attente',
  update_waiting_line_success: 'File d\'attente mise à jour avec succès',
  update_webhook_error: 'Une erreur s\'est produite lors de la mise à jour du webhook',
  update_webhook_success: 'Webhook mis à jour avec succès',

  upload_image: 'Téléverser une image',
  user_already_exists: 'Un utilisateur avec cette adresse courriel existe déjà',
  user_invite_form_subtitle: 'Le membre de l\'équipe recevra un courriel d\'invitation pour rejoindre votre organisation. S\'il n\'a pas de compte, il devra en créer un en utilisant cette adresse courriel.',
  users: 'Membres de l\'équipe',
  users_invites_subtitle: 'Invitez des membres de votre équipe qui pourront accéder aux opérations des files d\'attentes.',

  view_details: 'Voir les détails',
  view_payouts: 'Voir les paiements',
  view_payouts_details: 'Consulter les détails des paiements, les dates de paiement et les montants',
  view_payouts_history: 'Voir l\'historique des paiements',

  waiting_line: 'File d\'attente',
  waiting_line_access_GOLD: 'Accès immédiat',
  waiting_line_access_SILVER: 'Accès accéléré',
  waiting_line_call_size: 'Nombre de personnes appelées par bloc',
  waiting_line_call_size_description: 'Définis le nombre maximum de personnes qui seront appelées en même temps',
  waiting_line_change_status: 'Changer le status de la file',
  waiting_line_check_operations: 'Voir les opérations',
  waiting_line_description_gold: 'Le laissez-passer VIP Gold donne un accès prioritaire.',
  waiting_line_description_silver: 'Le laissez-passer VIP Silver permet de garder sa place dans la file d\'attente sans avoir à rester physiquement sur place.',
  waiting_line_form_auto_call: 'Remplir le bloc automatiquement',
  waiting_line_form_auto_call_description: 'Remplir automatiquement le bloc avec les prochains visiteurs, jusqu\'à la taille du bloc',
  waiting_line_form_currency: 'Devise',
  waiting_line_form_currency_description: 'Devise dans laquelle les laissez-passer seront vendus',
  waiting_line_form_end: 'Au',
  waiting_line_form_limited_tickets: 'Quantité limitée de laissez-passer disponibles',
  waiting_line_form_max_group_size: "Limite d'achat de laissez-passer par transaction",
  waiting_line_form_max_group_size_helper_text: 'Les visiteurs ne pourront pas acheter plus de laissez-passer que cette limite par transaction',
  waiting_line_form_name: 'Nom de la file d\'attente',
  waiting_line_form_no_show_timeout: 'Marquer automatiquement comme non présenté',
  waiting_line_form_no_show_timeout_description: 'Après ce délai, un laissez-passer sera marqué comme non présenté s\'il n\'est pas utilisé (il peut encore être validé)',
  waiting_line_form_no_show_timeout_helper_text: 'Un délai de 0 ne marquera jamais un laissez-passer comme non présenté',
  waiting_line_form_price: 'Prix',
  waiting_line_form_price_description: 'Déterminer un prix pour chaque laissez-passer',
  waiting_line_form_quantity: 'Quantité',
  waiting_line_form_start: 'Du',
  waiting_line_form_statement_descriptor: 'Descripteur de relevé',
  waiting_line_form_statement_descriptor_description: 'Apparaîtra sur le relevé de carte de crédit des visiteurs',
  waiting_line_form_statement_descriptor_pattern: 'Ne peut contenir que des lettres, des chiffres et des espaces',
  waiting_line_form_step_1: 'Sélectionnez un type de file',
  waiting_line_form_step_2: 'Informations sur la file d\'attente',
  waiting_line_form_when: 'Disponibilité des laissez-passer',
  waiting_line_hide_operations: 'Cacher les opérations',
  waiting_line_instructions: 'Instructions pour la file d\'attente',
  waiting_line_is_virtual: 'File virtuelle (inclus la vente de coupe-files)',
  waiting_line_management: 'Gestion des files d\'attente',
  waiting_line_minimum_tickets: 'La quantité minimale de laissez-passer doit être égale ou supérieure à la quantité de laissez-passer réservés ({usedTickets})',
  waiting_line_new: 'Nouvelle file d\'attente',
  waiting_line_new_add: 'Ajoutez une nouvelle file VIP à votre événement',
  waiting_line_not_found: 'File d\'attente non trouvée',
  waiting_line_pricing: 'Type d\'accès',
  waiting_line_pricing_call_params: 'Paramètres d\'appel',
  waiting_line_pricing_description: 'Choisissez entre un accès VIP payant ou une file d\'attente gratuite avec options payantes pour des avantages supplémentaires',
  waiting_line_pricing_disabled: 'Vous ne pouvez pas changer le type d\'accès si l\'événement est publié ou si des laissez-passer ont déjà été obtenus',
  waiting_line_pricing_free_and_paid: 'Freemium (Gratuit avec options payantes)',
  waiting_line_pricing_paid_only: 'VIP (Payant)',
  waiting_line_pricing_paid_passes: 'Laissez-passer payants',
  waiting_line_product_description: 'Description du laissez-passer',
  waiting_line_product_description_description: 'Décrire les avantages du laissez-passer',
  waiting_line_quantity: 'Entrer la quantité de laissez-passer disponible',
  waiting_line_schedule_error_after_start: 'La fin de l\'horaire doit être après son début',
  waiting_line_schedule_error_before_end: 'Le début de l\'horaire doit être avant sa fin',
  waiting_line_schedule_error_end: 'La fin de l\'horaire doit être en même temps ou avant la fin de l\'événement',
  waiting_line_schedule_error_start: 'Le début de l\'horaire doit être en même temps ou après le début de l\'événement',
  [`waiting_line_status_${WaitingLineStatus.Active}`]: 'Active',
  [`waiting_line_status_${WaitingLineStatus.Inactive}`]: 'Fermée',
  [`waiting_line_status_${WaitingLineStatus.Hidden}`]: 'Invisible',
  [`waiting_line_status_${WaitingLineStatus.Active}_description`]: 'Les laissez-passer sont disponibles à l\'achat',
  [`waiting_line_status_${WaitingLineStatus.Inactive}_description`]: 'Les laissez-passer ne sont plus disponibles à l\'achat',
  [`waiting_line_status_${WaitingLineStatus.Hidden}_description`]: 'Les laissez-passer ne sont pas visibles pour les visiteurs',
  waiting_line_status_explanation: 'Explication',
  waiting_line_status_explanation_placeholder: 'Expliquer le changement de statut',
  waiting_line_tickets: 'Laissez-passer de la file d\'attente',
  waiting_line_tickets_free: 'Laissez-passer gratuits',
  waiting_line_tickets_scanned: 'Laissez-passer scannés',
  waiting_line_tickets_sold: 'Laissez-passer vendus',
  waiting_line_time_slots_duration: 'Durée d\'une plage horaire',
  waiting_line_time_slots_duration_description: 'Quelle sera la durée de chaque plage horaire disponible',
  waiting_line_time_slots_duration_option: '{value} minutes',
  waiting_line_time_slots_enable: 'Activer les plages horaires',
  waiting_line_time_slots_end: 'Fin de la disponibilité',
  waiting_line_time_slots_end_description: 'Date de fin des disponibilités',
  waiting_line_time_slots_end_error_after_event: 'Doit être égale ou avant la date de fin de l\'événement',
  waiting_line_time_slots_end_error_before_start: 'Doit être après la date de début de la disponibilité',
  waiting_line_time_slots_schedule_end: 'Heure de fin',
  waiting_line_time_slots_schedule_end_description: 'Les disponibilités se termineront à cette heure chaque jour',
  waiting_line_time_slots_schedule_end_error_before_start: 'Doit être après l\'heure de début',
  waiting_line_time_slots_schedule_start: 'Heure de début',
  waiting_line_time_slots_schedule_start_description: 'Les disponibilités commenceront à cette heure chaque jour',
  waiting_line_time_slots_schedule_start_error_after_end: 'Doit être avant l\'heure de fin',
  waiting_line_time_slots_start: 'Début de la disponibilité',
  waiting_line_time_slots_start_description: 'Date de début des disponibilités',
  waiting_line_time_slots_start_error_after_end: 'Doit être avant la date de fin de la disponibilité',
  waiting_line_time_slots_tickets_count: 'Passes par plage horaire',
  waiting_line_time_slots_tickets_count_description: 'Entrez la quantité de passes disponible à l\'achat pour chaque plage horaire',
  waiting_line_type: 'Type de file d\'attente',
  waiting_line_type_GOLD: 'VIP Gold',
  waiting_line_type_SILVER: 'VIP Silver',
  waiting_line_type_create: 'Créer une file \n d\'attente {type}',
  waiting_lines: 'Files d\'attente',
  waiting_lines_hide_inactive: 'Cacher les files inactives',
  waiting_lines_only_active: 'Afficher seulement les files actives',

  webhook_actions: 'Actions',
  webhook_actions_description: 'Les actions qui déclencheront l\'envoi d\'un webhook',
  webhook_create: 'Créer un nouveau webhook',
  webhook_delete: 'Supprimer',
  webhook_delete_confirm_text: 'Voulez-vous vraiment supprimer ce webhook? Après la suppression, il ne sera plus possible de recevoir les notifications pour cet événement.',
  webhook_delete_confirm_title: 'Supprimer le webhook',
  webhook_description: 'Description',
  webhook_description_field_description: 'Une description optionnelle de ce à quoi sert ce point de terminaison webhook',
  webhook_edit: 'Modifier',
  webhook_endpoint_url: 'Url du point de terminaison',
  webhook_endpoint_url_description: 'Url du point de terminaison qui recevra les notifications',
  webhook_error_rate: 'Taux d\'erreur',
  webhook_last_7_days: '7 derniers jours',
  webhook_listening_for: 'Écoute pour',
  webhook_load_more: 'Charger plus d\'appels',
  webhook_nb_actions: '{nbr} {count, plural, one {action} other {actions}}',
  webhook_no_calls: 'Aucun appel n\'a été effectué pour ce webhook',
  webhook_no_webhooks: 'Aucun webhook n\'a été créé pour cet événement',
  webhook_request: 'Requête',
  webhook_resend: 'Renvoyer',
  webhook_resend_error: 'Une erreur s\'est produite lors de la tentative de renvoi du webhook',
  webhook_resend_success: 'Webhook renvoyé avec succès',
  webhook_response: 'Réponse',
  webhook_status_code: 'Code de statut HTTP',
  webhook_update: 'Modifier le webhook',
  webhook_url: 'Url',
  webhooks: 'Webhooks',
  webhooks_endpoints: 'Points de terminaison des webhooks',
  webhooks_subtitle: 'Les webhooks vous permettent de recevoir des notifications en temps réel lorsqu\'une action se produit sur votre événement MovOn.VIP.',

  your_balance: 'Votre solde',
};
