import { Typography } from '@mui/material';
import {
  Html5Qrcode,
  Html5QrcodeCameraScanConfig,
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from 'html5-qrcode';
import {
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Centered } from 'styles/styles';

import CenteredSpinner from './Loading/CenteredSpinner';

interface QRScannerProps {
  className?: string
  onError?: QrcodeErrorCallback
  onScan: QrcodeSuccessCallback
  paused?: boolean
}

function QRScanner(props: QRScannerProps) {
  const {
    className,
    onError,
    onScan,
    paused = false,
  } = props;

  const id = useId();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const readerRef = useRef<Html5Qrcode | null>(null);

  const stableOnScan = useRef(onScan);
  stableOnScan.current = onScan;

  const stableOnError = useRef(onError);
  stableOnError.current = onError;

  useEffect(() => {
    async function initScanner() {
      const config: Html5QrcodeCameraScanConfig = {
        fps: 10,
        qrbox: { height: 250, width: 250 },
      };
      const reader = new Html5Qrcode(id);
      readerRef.current = reader;

      setLoading(true);
      setError(null);
      try {
        const onScanWrapper: QrcodeSuccessCallback = (...args) => stableOnScan.current(...args);
        const onErrorWrapper: QrcodeErrorCallback = (...args) => stableOnError.current?.(...args);
        await reader.start({ facingMode: 'environment' }, config, onScanWrapper, onErrorWrapper);
        const interval = setInterval(() => {
          const scannerElement = document.getElementById(id);
          if (scannerElement?.children.length || 0 > 2) {
            setLoading(false);
            clearInterval(interval);
          }
        }, 100);
        return () => {
          reader.stop().catch(() => { /** no-op */ });
        };
      } catch (err) {
        console.error(err);
        setLoading(false);
        setError(err as Error);
      }
      return undefined;
    }

    const promise = initScanner();

    return () => {
      promise.then((cleanup) => cleanup?.());
    };
  }, [id]);

  useEffect(() => {
    if (paused) {
      const reader = readerRef.current;
      if (reader) {
        reader.pause();
        return () => reader.resume();
      }
    }
    return undefined;
  }, [paused]);

  return (
    <>
      <div className={className}>
        <div id={id} />
        {loading && <CenteredSpinner />}
        {error && (
          <Centered>
            <p>
              <FormattedMessage id="qr_reader_error" />
            </p>
          </Centered>
        )}
      </div>
      {!loading && !error && (
        <Typography>
          <FormattedMessage id="qr_reader_instructions" />
        </Typography>
      )}
    </>
  );
}

export default QRScanner;
