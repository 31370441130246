import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Grid } from '@mui/material';
import useFeedback from 'components/Feedback/useFeedback';
import FormBooleanField from 'components/FormFields/FormBooleanField';
import FormButtons from 'components/FormFields/FormButtons';
import FormNumberField from 'components/FormFields/FormNumberField';
import FormNumberFieldWithControls from 'components/FormFields/FormNumberFieldWithControls/FormNumberFieldWithControls';
import FormSection from 'components/FormFields/FormSection/FormSection';
import FormTextField from 'components/FormFields/FormTextField';
import FormToggleFields from 'components/FormFields/FormToggleField/FormToggleField';
import PageLayout from 'components/PageLayout';
import { EventStatus } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import UnsavedFormQuitConfirmation from 'lib/routing/UnsavedFormQuitConfirmation';
import TranslationsTabs from 'modules/Forms/TranslationsTabs';
import useEvent from 'providers/event/useEvent';
import { useCallback, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Breadcrumb,
  BreadcrumbsContainer,
  ContainedButton,
  NeutralOutlinedButton,
  PreviewText,
  Subtitle,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import FormCurrencyField from '../components/FormCurrencyField';
import FormMoneyField from '../components/FormMoneyField';
import StatementDescriptorExplanationCard from './StatementDescriptorExplanationCard/StatementDescriptorExplanationCard';
import TimeSlotsConfiguration from './TimeSlotsConfiguration/TimeSlotsConfiguration';
// TODO: Uncomment after implementing the gold waitingLine type
// import FormWaitingLineTypeField from '../components/FormWaitingLineTypeField';

interface WaitingLineFormProps {
  title: String
  onSubmit: SubmitHandler<any>
  updating?: boolean
}

function WaitingLineForm(props: WaitingLineFormProps) {
  const { title, onSubmit, updating = false } = props;
  const { watch, formState, trigger } = useFormContext();
  const { errors } = formState;

  const feedback = useFeedback();

  const event = useEvent();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [lang, setLang] = useState<string>(event.languages[0] || Languages.EN);

  const intl = useIntl();
  const [, setLocation] = useLocation();

  const [
    limitedQuantity,
    usedTickets,
    statementDescriptor,
    originalDesc,
    originalName,
    originalProductDesc,
    isFreemium,
  ] = watch([
    'limitedQuantity',
    'usedTickets',
    'statementDescriptor',
    `description.${event.languages[0]}`,
    `name.${event.languages[0]}`,
    `productDescription.${event.languages[0]}`,
    'isFreemium',
  ]);

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setLang(event.languages[newValue]);
  }, [event.languages]);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const validateForm = async (onValid: () => void) => {
    const isValid = await trigger();

    if (isValid) {
      onValid();
    } else {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="errors_in_form" />),
        severity: 'error',
      });
    }
  };

  const handleNext = async () => {
    await validateForm(() => {
      setCurrentPage((prevPage) => prevPage + 1);
    });
  };

  const handleToTranslations = async () => {
    if (currentPage === 1) return;
    await validateForm(() => {
      setCurrentPage(1);
    });
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <PageLayout>
      <UnsavedFormQuitConfirmation />
      <BreadcrumbsContainer>
        <Breadcrumbs separator="—">
          {event.hasWaitingLines && (
            <Breadcrumb
              $isClickable
              color="textSecondary"
              onClick={() => setLocation(`~/event/${event.id}/waitingLines`)}
            >
              <FormattedMessage id="waiting_lines" />
            </Breadcrumb>
          )}
          {(event.hasWaitingLines || event.languages.length > 1) && (
            <Breadcrumb
              $isClickable
              color={currentPage === 0 ? 'secondary' : 'textSecondary'}
              onClick={() => setCurrentPage(0)}
            >
              <FormattedMessage id={`${title}_waiting_line`} />
            </Breadcrumb>
          )}
          {event.languages.length > 1 && (
            <Breadcrumb
              $isClickable
              color={currentPage === 1 ? 'secondary' : 'textSecondary'}
              onClick={handleToTranslations}
            >
              <FormattedMessage id="translations" />
            </Breadcrumb>
          )}
        </Breadcrumbs>
      </BreadcrumbsContainer>
      <Grid container direction="column" gap={2}>
        <Title>
          <FormattedMessage id={`${title}_waiting_line`} />
        </Title>
        {currentPage === 0 && (
          <>
            <FormTextField
              required
              labelId="waiting_line_form_name"
              name={`name.${event.languages[0]}`}
              rules={{ maxLength: 50 }}
              key={`name.${event.languages[0]}`}
            />
            {/* <FormWaitingLineTypeField /> */}
            <FormTextField
              required
              fullWidth
              multiline
              rows={3}
              labelId="waiting_line_product_description"
              name={`productDescription.${event.languages[0]}`}
              key={`productDescription.${event.languages[0]}`}
              fieldDescription="waiting_line_product_description_description"
              rules={{
                maxLength: 300,
              }}
            />
            <FormTextField
              required
              fullWidth
              multiline
              rows={3}
              labelId="waiting_line_instructions"
              name={`description.${event.languages[0]}`}
              key={`description.${event.languages[0]}`}
              fieldDescription="steps_to_redeem_pass"
              rules={{
                maxLength: 300,
              }}
            />
            <FormToggleFields
              labelId="waiting_line_pricing"
              name="isFreemium"
              disabled={updating && (
                event.status === EventStatus.Published || (usedTickets && usedTickets > 0)
              )}
              disabledReason={event.status === EventStatus.Published
                || (usedTickets && usedTickets > 0) ? (
                  <FormattedMessage id="waiting_line_pricing_disabled" />
                ) : ''}
              leftLabel="waiting_line_pricing_paid_only"
              rightLabel="waiting_line_pricing_free_and_paid"
              fieldDescription="waiting_line_pricing_description"
            />
            <FormSection>
              <TimeSlotsConfiguration />
            </FormSection>
            {isFreemium && (
              <FormSection label="waiting_line_pricing_call_params">
                <FormNumberFieldWithControls
                  required
                  minimum={1}
                  fieldDescription="waiting_line_call_size_description"
                  name="callSize"
                  labelId="waiting_line_call_size"
                  rules={{
                    validate: {
                      positive: (value) => value > 0 || 'field_positive',
                    },
                  }}
                />
                <FormNumberField
                  minimum={0}
                  fieldDescription="waiting_line_form_no_show_timeout_description"
                  helperText={intl.formatMessage({ id: 'waiting_line_form_no_show_timeout_helper_text' })}
                  fullWidth={false}
                  name="noShowTimeout"
                  labelId="waiting_line_form_no_show_timeout"
                  InputProps={{
                    endAdornment: intl.formatMessage({ id: 'minutes' }),
                  }}
                  rules={{
                    validate: {
                      positive: (value) => value >= 0 || 'field_positive',
                    },
                  }}
                />
                <FormBooleanField
                  name="autoCall"
                  labelId="waiting_line_form_auto_call"
                  fieldDescription="waiting_line_form_auto_call_description"
                  sideBySide
                />
              </FormSection>
            )}
            <FormSection>
              <FormBooleanField
                required
                labelId="waiting_line_form_limited_tickets"
                name="limitedQuantity"
                sideBySide
              />
              {limitedQuantity && (
                <FormNumberFieldWithControls
                  required
                  minimum={1}
                  fieldDescription="waiting_line_quantity"
                  name="quantity"
                  labelId="waiting_line_form_quantity"
                  rules={{
                    validate:
                    {
                      positive: (value) => value > 0 || 'field_positive',
                      minimum: (value) => (!usedTickets
                        || (usedTickets > 0 && value >= usedTickets))
                        || intl.formatMessage({ id: 'waiting_line_minimum_tickets' }, { usedTickets }) as string,
                    },
                  }}
                />
              )}
            </FormSection>
            <FormSection label={isFreemium ? 'waiting_line_pricing_paid_passes' : undefined}>
              <Grid container gap={2}>
                <Grid item xs={4}>
                  <FormMoneyField
                    required
                    rules={{
                      validate:
                      {
                        decimals: (value) => {
                          const decimals = value.toString().split('.')[1];
                          if (!decimals || decimals?.length <= 2) {
                            return true;
                          }
                          return 'field_decimals';
                        },
                        commaUse: (value) => !Number.isNaN(value) || 'field_format',
                        positive: (value) => value > 0 || 'field_positive',
                        minimum: (value) => value >= 2 || 'field_minimum_price',
                      },
                    }}
                    labelId="waiting_line_form_price"
                    name="price"
                    fieldDescription="waiting_line_form_price_description"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCurrencyField
                    name="currency"
                    labelId="waiting_line_form_currency"
                    fieldDescription="waiting_line_form_currency_description"
                  />
                </Grid>
              </Grid>
              <FormNumberFieldWithControls
                required
                minimum={1}
                fieldDescription="waiting_line_form_max_group_size_helper_text"
                name="maxGroupSize"
                labelId="waiting_line_form_max_group_size"
                rules={{
                  validate: (value) => value > 0 || 'field_positive',
                }}
              />
            </FormSection>
            <Grid container gap={2}>
              <Grid item xs={4}>
                <FormTextField
                  required
                  labelId="waiting_line_form_statement_descriptor"
                  name="statementDescriptor"
                  rules={{
                    maxLength: 15,
                    validate: (value) => (value && Boolean(value.match(/[a-zA-Z0-9\s]+/))) || 'waiting_line_form_statement_descriptor_pattern',
                  }}
                  fieldDescription="waiting_line_form_statement_descriptor_description"
                />
              </Grid>
              <Grid item xs={4}>
                <StatementDescriptorExplanationCard statementDescriptor={statementDescriptor} />
              </Grid>
            </Grid>
          </>
        )}
        {currentPage === 1 && (
          <>
            <TranslationsTabs
              errors={errors}
              tabIndex={tabIndex}
              handleChange={handleChange}
              formState={formState}
              languages={event.languages as Languages[]}
            />
            <Subtitle>
              <FormattedMessage id="translations" />
            </Subtitle>
            <FormTextField
              required
              labelId="waiting_line_form_name"
              name={`name.${lang}`}
              rules={{ maxLength: 50 }}
              key={`name.${lang}`}
            />
            {tabIndex > 0 && originalName && (
              <PreviewText>
                {originalName}
              </PreviewText>
            )}
            <FormTextField
              fullWidth
              multiline
              rows={3}
              labelId="waiting_line_product_description"
              name={`productDescription.${lang}`}
              key={`productDescription.${lang}`}
              fieldDescription="waiting_line_product_description_description"
              rules={{
                maxLength: 300,
              }}
            />
            {tabIndex > 0 && originalProductDesc && (
              <PreviewText>
                {originalProductDesc}
              </PreviewText>
            )}
            <FormTextField
              fullWidth
              multiline
              rows={3}
              labelId="waiting_line_instructions"
              name={`description.${lang}`}
              key={`description.${lang}`}
              fieldDescription="steps_to_redeem_pass"
              rules={{
                maxLength: 300,
              }}
            />
            {tabIndex > 0 && originalDesc && (
              <PreviewText>
                {originalDesc}
              </PreviewText>
            )}
          </>
        )}
        <Grid container justifyContent="flex-end" gap={1}>
          {currentPage > 0 && (
            <NeutralOutlinedButton
              onClick={handlePrevious}
              startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            >
              <FormattedMessage id="previous" />
            </NeutralOutlinedButton>
          )}
          {event.languages && (
            event.languages.length > 1 && currentPage === 0 ? (
              <ContainedButton
                color="secondary"
                onClick={handleNext}
                endIcon={<FontAwesomeIcon icon={faChevronRight} />}
              >
                <FormattedMessage id="next" />
              </ContainedButton>
            ) : (
              <FormButtons noGrid={event.languages.length > 1} submit={onSubmit} />
            )
          )}
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default WaitingLineForm;
