import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetOrganizationItem } from 'lib/graphql/entities/organization/types';
import { useGetOrganizationAccountLinkLazyQuery } from 'lib/graphql/graphql';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedLoadingButton } from 'styles/styles';

interface AccountLinkButtonProps {
  organization: GetOrganizationItem | null | undefined;
  canReceivePayments: boolean;
}

export default function AccountLinkButton(props: AccountLinkButtonProps) {
  const { organization, canReceivePayments } = props;
  const [createAccountLink] = useGetOrganizationAccountLinkLazyQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const handleClick = useCallback(async () => {
    if (organization && organization.stripeAccountId && !canReceivePayments) {
      setLoading(true);
      const { data } = await createAccountLink({
        variables: {
          stripeAccountId: organization.stripeAccountId,
        },
        fetchPolicy: 'network-only',
      });
      if (data && data.getOrganizationAccountLink) {
        window.open(data.getOrganizationAccountLink, '_blank');
      }
      setLoading(false);
    }
  }, [organization, canReceivePayments, createAccountLink]);

  let label;
  switch (true) {
    case organization?.stripeAccountLinked && !canReceivePayments:
      label = 'stripe_need_info';
      break;
    case organization?.stripeAccountLinked:
      label = 'stripe_already_linked';
      break;
    default:
      label = 'stripe_link_account';
  }

  return (
    <ContainedLoadingButton
      loading={loading}
      onClick={handleClick}
      disabled={loading || canReceivePayments}
      color="secondary"
      endIcon={<FontAwesomeIcon icon={faExternalLink} />}
    >
      <FormattedMessage id={label} />
    </ContainedLoadingButton>
  );
}
