/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import { BorderedListItem } from '../../styles';

export const LoadMoreBorderedListItem = styled(BorderedListItem)`
  color: ${({ theme }) => theme.palette.secondary.main};

  :hover {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
