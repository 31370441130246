import { FormLabel, Radio, RadioProps } from '@mui/material';

import { RadioContainer, RadioLabelDescription } from './styles';

interface FormRadioProps extends RadioProps {
  label: React.ReactNode
  labelDescription?: React.ReactNode
}

function FormRadio(props: FormRadioProps) {
  const { label, labelDescription, ...radioProps } = props;

  return (
    <RadioContainer>
      <Radio {...radioProps} />
      <FormLabel>
        {label}
      </FormLabel>
      <RadioLabelDescription>{labelDescription}</RadioLabelDescription>
    </RadioContainer>
  );
}

export default FormRadio;
