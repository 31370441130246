import { Select, SelectProps } from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';

import FormField, { FormFieldProps } from './FormField';

type CustomMUISelectProps = Omit<SelectProps, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name'>;

export interface FormSelectFieldProps extends FormFieldProps, CustomMUISelectProps {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'maxLength' | 'minLength'>
}

function FormSelectField(props: FormSelectFieldProps) {
  const {
    name,
    rules = {},
    labelId,
    required,
    fieldDescription,
    ...selectFieldProps
  } = props;

  const { field, formState } = useController({ name, rules: { required, ...rules } });
  const error = useFieldFormError(name);

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <Select
        fullWidth
        size="small"
        defaultValue={field.value || formState.defaultValues?.[name] || ''}
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
        error={Boolean(error?.type) || false}
        {...selectFieldProps}
      >
        {selectFieldProps.children}
      </Select>
    </FormField>
  );
}

export default FormSelectField;
