import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListItemButton } from './styles';

interface EventSideCollapseProps {
  text: string;
  icon: IconDefinition;
  urlKeys?: string[];
  isDisabled?: boolean;
  isOpen?: boolean;
  children: React.ReactNode;
}

function EventSideCollapse(props: EventSideCollapseProps) {
  const {
    text,
    icon,
    isDisabled = false,
    isOpen = false,
    urlKeys = [],
    children,
  } = props;

  const isActive = urlKeys.some((key) => window.location.pathname.includes(key));

  const [open, setOpen] = useState<boolean>(isActive || isOpen);
  const theme = useTheme();

  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  return (
    <>
      <ListItem key={`menu-item-${text}`} disablePadding onClick={handleOpen}>
        <ListItemButton
          disabled={isDisabled}
        >
          <ListItemIcon>
            <FontAwesomeIcon
              icon={icon}
              size="xl"
              color={theme.palette.primary.main}
              fixedWidth
            />
          </ListItemIcon>
          <ListItemText primary={<FormattedMessage id={text} />} />
          {open ? (<FontAwesomeIcon icon={faChevronDown} size="lg" color={theme.palette.primary.main} fixedWidth />) : (<FontAwesomeIcon icon={faChevronRight} size="lg" color={theme.palette.primary.main} fixedWidth />)}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}

export default EventSideCollapse;
