import { DateTime } from 'luxon';
import { useMemo } from 'react';

function useDateValue(value: DateTime | string) {
  return useMemo(() => {
    if (typeof value === 'string') return DateTime.fromISO(value);
    if (DateTime.isDateTime(value)) return value;
    return null;
  }, [value]);
}

export default useDateValue;
