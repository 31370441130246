import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import useFeedback from 'components/Feedback/useFeedback';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import { useCustomForm } from 'lib/form/useCustomForm';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { NullableText, UpdateWaitingLineStatusInput, useChangeWaitingLineStatusMutation } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import statusColor from './statusColor';
import { SelectorButton } from './styles';
import WaitingLineStatusSelectorForm from './WaitingLineStatusSelectorForm';

interface WaitingLineStatusSelectorProps {
  waitingLine: Pick<EventWaitingLine, 'id' | 'status' | 'message'>;
  handleRefetch: () => void | Promise<void>;
}

function WaitingLineStatusSelector(props: WaitingLineStatusSelectorProps) {
  const { waitingLine, handleRefetch } = props;
  const { status, message } = waitingLine;

  const event = useEvent();
  const feedback = useFeedback();
  const intl = useIntl();

  const [
    changeWaitingLineStatus,
    { loading: changingStatus },
  ] = useChangeWaitingLineStatusMutation();

  const [open, setOpen] = useState(false);

  const defaultValues = useMemo(() => ({
    status,
    message,
  }), [message, status]);

  const methods = useCustomForm<UpdateWaitingLineStatusInput>({
    defaultValues,
    trim: true,
  });

  const { setError, resetField, setValue } = methods;

  const onCancel = () => {
    setOpen(false);
    // Reset form fields to ensure form is not dirty
    resetField('status');
    resetField('message');
    setValue('status', status);
    setValue('message', message);
  };

  const onSubmit: SubmitHandler<UpdateWaitingLineStatusInput> = async (formData) => {
    const { __typename: messageTypename, ...messages } = formData.message as NullableText;

    try {
      await changeWaitingLineStatus({
        variables: {
          eventId: event.id,
          id: waitingLine.id,
          fields: {
            status: formData.status,
            message: messages,
          },
        },
      });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="update_waiting_line_success" />),
        severity: 'success',
      });
      handleRefetch();
      setOpen(false);
    } catch (error) {
      const errorMessage = (error as Error).message;

      const regex = /The (.+?) field must have a value for the languages: (.+)/;
      const match = errorMessage.match(regex);

      if (match) {
        const field = match[1];
        const languages = match[2].split(',').map((lang) => lang.trim()) as Languages[];
        const languagesWithErrors: string[] = [];

        languages.forEach((lang: Languages) => {
          const translatedLanguage = intl.formatMessage({ id: `language_${lang}` });

          setError(`${field}.${lang}` as keyof UpdateWaitingLineStatusInput, { message: 'field_required' });

          languagesWithErrors.push(translatedLanguage);
        });

        const concatenatedLanguages = languagesWithErrors.join(', ');

        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_error_missing_translation" values={{ languages: concatenatedLanguages, count: languagesWithErrors.length }} />),
          severity: 'error',
        });
      }
    }
  };

  return (
    <>
      <SelectorButton
        color={status ? statusColor[status] : undefined}
        onClick={() => setOpen(true)}
      >
        {status ? (
          <FormattedMessage id={`waiting_line_status_${status}`} />
        ) : (
          <CenteredSpinner size="1em" />
        )}
        <FontAwesomeIcon icon={faPenToSquare} />
      </SelectorButton>
      <CustomDialog
        fullWidth
        open={open}
        onClose={onCancel}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
      >
        <FormProvider {...methods}>
          <WaitingLineStatusSelectorForm
            loading={changingStatus}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        </FormProvider>
      </CustomDialog>
    </>
  );
}

export default WaitingLineStatusSelector;
