import { TimePickerProps } from '@mui/lab';
import { TimePicker } from '@mui/x-date-pickers';
import useFieldFormError from 'lib/form/useFieldFormError';
import { DateTime } from 'luxon';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';

import FormField, { FormFieldProps } from '../FormField';
import useDateValue from './useDateValue';

type CustomTimePickerProps = Omit<TimePickerProps<DateTime>, 'onChange' | 'value' | 'defaultValue' | 'name'>;
export interface FormTimeFieldProps extends FormFieldProps, CustomTimePickerProps {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  size?: 'small' | 'medium';
}

function FormTimeField(props: FormTimeFieldProps) {
  const {
    fieldDescription,
    labelId,
    name,
    required,
    rules,
    size = 'small',
    ...dateTimePickerProps
  } = props;

  const { field } = useController({ name, rules: { required, ...rules } });
  const fieldError = useFieldFormError(name);
  const dateValue = useDateValue(field.value);

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <TimePicker
        ampm={false}
        onChange={field.onChange}
        value={dateValue}
        closeOnSelect={false}
        slotProps={{
          textField: {
            size,
            error: Boolean(fieldError),
            fullWidth: true,
          },
        }}
        {...dateTimePickerProps}
      />
    </FormField>
  );
}

export default FormTimeField;
