import { Grid } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import Loader from 'components/Loading/Loader';
import PageLayout from 'components/PageLayout';
import BalanceTransactionsCard from 'components/Stripe/Reports/BalanceTransactionsCard';
import SalesBreakdownCard from 'components/Stripe/Reports/Cards/SalesBreakdownCard';
import TotalTransactionsCard from 'components/Stripe/Reports/Cards/TotalTransactionsCard';
import {
  EventStatus,
  Organization,
  useGetAllEventWaitingLinesLazyQuery,
  useGetEventStripeTransactionsQuery,
  useGetOrganizationQuery,
  WaitingLine,
} from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'styles/styles';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import { ChartsGrid, Content } from './styles';

function EventFinancialReport() {
  useEventDocumentTitle('financial_report');
  const event = useEvent();
  const [, setLocation] = useLocation();

  useEffect(() => {
    if (event.status === EventStatus.Draft) setLocation('/edit');
  }, [event, setLocation]);

  const {
    data: organizationData,
    loading: loadingOrganization,
  } = useGetOrganizationQuery({
    fetchPolicy: 'network-only',
  });
  const organization = organizationData?.getOrganization as Organization;

  const [getAllWaitingLinesQuery] = useGetAllEventWaitingLinesLazyQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading: loadingWaitingLines,
    error: errorWaitingLines,
  } = useInfiniteQuery({
    queryKey: ['waitingLines'],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllWaitingLinesQuery({
        fetchPolicy: 'network-only',
        variables: { eventId: event.id, start: pageParam },
      });
      return result.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage?.getAllEventWaitingLines?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const waitingLines = useMemo(() => {
    if (errorWaitingLines) return [];

    if (data && !loadingWaitingLines) {
      if (!data.pages[0]?.getAllEventWaitingLines) {
        return [];
      }
      const tempWaitingLines = data?.pages.flatMap(
        (page) => page?.getAllEventWaitingLines.waitingLines || [],
      ) || [];
      return tempWaitingLines as WaitingLine[];
    }
    return [];
  }, [data, errorWaitingLines, loadingWaitingLines]);

  const {
    data: transactionsData,
    loading: loadingTransactions,
    refetch: refetchTransactions,
  } = useGetEventStripeTransactionsQuery({ variables: { id: event.id } });

  const balanceTransactions = useMemo(
    () => transactionsData?.getEventStripeTransactions || [],
    [transactionsData],
  );

  useEffect(() => {
    refetchTransactions();
  }, [waitingLines, refetchTransactions]);

  return (
    <Loader isLoading={loadingOrganization || loadingWaitingLines || loadingTransactions}>
      <PageLayout>
        <Content>
          <Title>
            <FormattedMessage id="financial_report" />
          </Title>
          <ChartsGrid>
            <Grid container gap={2} justifyContent="space-around">
              <Grid item>
                {organization && !loadingOrganization && (
                  <SalesBreakdownCard
                    balanceTransactions={balanceTransactions}
                    organizationName={organization.name}
                  />
                )}
              </Grid>
              <Grid item>
                <TotalTransactionsCard
                  balanceTransactions={balanceTransactions}
                  waitingLines={waitingLines}
                  defaultLocale={event.languages[0] as Languages}
                />
              </Grid>
            </Grid>
          </ChartsGrid>
          <Grid container>
            <BalanceTransactionsCard
              balanceTransactions={balanceTransactions}
              waitingLines={waitingLines}
              defaultLocale={event.languages[0] as Languages}
            />
          </Grid>
        </Content>
      </PageLayout>
    </Loader>
  );
}

export default EventFinancialReport;
