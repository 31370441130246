/* cSpell:language es, en */
/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": true}] */

import { WaitingLineStatus } from 'lib/graphql/graphql';

export default {
  accept: 'Aceptar',
  accept_invitation_error: 'Ocurrió un error al aceptar la invitación',
  accept_invitation_success: 'Invitación aceptada exitosamente',
  account: 'Cuenta',
  account_needed: 'Se requiere una cuenta para aceptar esta invitación',
  actions: 'Acciones',

  add: 'Añadir',
  add_obnl_error: 'Se ha producido un error al añadir la organización',
  add_supported_organization: 'Añadir una organización apoyada',
  add_webhook: 'Nuevo webhook',

  apply: 'Aplicar',

  automatic_timezone_failed: 'Se ha producido un error al recuperar automáticamente la zona horaria, por favor selecciónela manualmente',

  avatar_organization_create: 'Crear una organización',
  avatar_organization_edit: 'Editar organización',
  avatar_organization_manage: 'Administrar mis organizaciones',

  average_wait_time: 'Tiempo medio de espera',

  back: 'Volver',
  back_to_webhook_list: 'Volver a la lista de webhooks',

  balance: 'Saldo',

  cancel: 'Cancelar',
  causes: 'Causas',
  causes_obnl_dialog_add_another: '¿Le gustaría añadir otra organización a la que apoyar?',
  causes_obnl_dialog_delete_warning: 'Esta organización dejará de contar con el apoyo de su evento',
  causes_obnl_dialog_subheader: 'Ahora es una de las organizaciones a las que apoyas a través de la venta de tus pases MovOn.VIP',
  causes_obnl_dialog_title: 'Organización añadida correctamente',
  change_password_error: 'Se produjo un error al cambiar su contraseña',
  change_password_incorrect_password: 'Su contraseña actual es incorrecta',
  change_password_link: '¿Cambiar contraseña?',
  change_password_success: 'Su contraseña fue cambiada exitosamente',
  change_password_title: 'Cambiar contraseña',
  change_visibility_confirm_hidden: '¿Estás seguro de que quieres archivar este evento?',
  change_visibility_error: 'Ocurrió un error al cambiar la visibilidad del evento',
  change_visibility_success: 'Estado del evento actualizado exitosamente',
  change_visibility_to_hidden: 'El evento dejará de ser visible para los visitantes y éstos ya no podrán adquirir pases.',
  check_email_not_altered: 'Asegúrate de que tu cliente de correo no haya alterado la URL de ninguna manera o que no hayas verificado aún la dirección de correo electrónico.',
  check_your_email: 'Revisar tu correo',
  choose_for_me: 'Elegir por mí',
  choose_obnl_for_me: 'Elegir una organización por mí',
  choose_obnl_to_support: 'Elige una organización para apoyar',
  choose_one_goal_from_sdg_description: 'Elige uno de los objetivos de desarrollo sostenible adoptados por las Naciones Unidas. Puedes añadir otros más adelante.',
  choose_one_goal_from_sdg_title: 'Elegir un objetivo',
  clear: 'Borrar',
  click_link_email_to_confirm: 'Por favor, haga clic en el enlace del correo que le acabamos de enviar para confirmar su dirección de correo electrónico.',
  collapse: 'Colapso',
  confirm_password: 'Confirmar contraseña',
  confirm_your_email: 'Confirmar tu correo',
  control_number: 'Número de control',
  create_event: 'Crear un evento',
  create_event_description: 'Ingrese el descripción del evento',
  create_event_details: 'Agrega los detalles de tu evento y crea colas VIP',
  create_event_error: 'Ocurrió un error al crear el evento',
  create_event_name: 'Ingrese el título del evento',
  create_event_short_description: 'Ingrese una descripción corta sobre su evento',
  create_event_success: 'Evento creado exitosamente',
  create_invitation_error_already_exists: 'Ya existe una invitación para este correo',
  create_invitation_success: 'Invitación creada exitosamente',
  create_invoice: 'Generar factura',
  create_invoice_disabled: 'La generación de facturas sólo está disponible para los pagos pagados',
  create_organization: 'Crear una organización',
  create_organization_description: 'Ingrese una descripción corta que será visible en la lista de organizaciones',
  create_organization_language: 'Seleccione el idioma que se utilizará por defecto para los campos',
  create_organization_name: 'Ingrese el nombre completo de la organización',
  create_organization_success: 'Organización creada exitosamente',
  create_waiting_line: 'Crear una cola',
  create_waiting_line_error: 'Ocurrió un error al crear la cola',
  create_waiting_line_success: 'Cola creada exitosamente',
  create_webhook: 'Crear webhook',
  create_webhook_error: 'Ocurrió un error al crear el webhook',
  create_webhook_success: 'Webhook creado exitosamente',
  current_time_slot: 'Horario actual',

  dashboard: 'Panel',
  dashboard_begin_here: 'Comience aquí',
  date: 'Fecha',
  date_field_next_month: 'Mes siguiente',
  date_field_open_next_view: 'Abrir vista siguiente',
  date_field_open_previous_view: 'Abrir vista anterior',
  date_field_previous_month: 'Mes anterior',
  delete: 'Borrar',
  delete_confirm: '¿Estás seguro de que quieres borrar?',
  delete_event_success: 'Evento borrado exitosamente',
  delete_invitation_error: 'Ocurrió un error al borrar la invitación',
  delete_invitation_success: 'Invitación borrada exitosamente',
  delete_irreversible: 'Esta acción es irreversible',
  delete_obnl_error: 'Debes apoyar al menos a una organización, por favor, añade otra primero si quieres borrar esta',
  delete_obnl_success: 'Organización borrada exitosamente',
  delete_organization_success: 'Organización borrada exitosamente',
  delete_waiting_line_success: 'Cola borrada exitosamente',
  description: 'Descripción',
  did_not_get_it: '¿No has recibido nada?',
  dont_have_an_account: '¿No tienes una cuenta? ',

  edit: 'Editar',
  email: 'Correo',
  enter_confirm_password: 'Introduce contraseña nuevamente',
  enter_email: 'Introduce correo',
  enter_new_password: 'Ingresa tu nueva contraseña',
  enter_password: 'Introduce contraseña',
  errors_in_form: 'Uno o más errores están presentes en el formulario, corríjalos antes de continuar',
  event: 'Evento',
  event_begin: 'Inicio del evento',
  event_begin_title: '¿Cuándo comienza tu evento?',
  event_create: 'Crear un nuevo evento',
  event_date_invalid: 'Fecha inválida',
  event_end: 'Fin del evento',
  event_end_error: 'El fin del evento debe ser mayor a su inicio',
  event_end_error_future: 'El fin del evento debe ser en el futuro',
  event_end_title: '¿Cuándo termina tu evento?',
  event_ends: 'Termina el',
  event_hide_archived: 'Ocultar eventos archivados',
  event_image: 'Imagen del evento',
  event_image_description: 'Sube una imagen que represente tu evento (Se recomienda una proporción de 16:9)',
  event_information: 'Información del evento',
  event_languages: 'Idiomas del evento',
  event_languages_description: 'Idiomas en los que se mostrará la información del evento. El primero será el idioma predeterminado.',
  event_location: 'Ubicación del evento',
  event_location_placeholder: 'Empieza a escribir una ubicación...',
  event_media_browse: 'Buscar',
  event_media_drag: 'Arrastrar y soltar archivos o',
  event_media_file_invalid_type: 'Tipo de archivo no válido, debe ser JPG, JPEG o PNG',
  event_media_supported: 'Archivos soportados: JPG y PNG',
  event_my_events: 'Mis eventos',
  event_name: 'Nombre del evento',
  event_no_events: 'No hay eventos para mostrar',
  event_not_found: 'Evento no encontrado',
  event_payment: 'Configuración de pago',
  event_publish_again: 'Debe publicar de nuevo el evento para que sea visible para los visitantes.',
  event_publish_disabled: 'Publica el evento para desbloquear esta función',
  event_publish_invite: 'Invita a miembrios del personal a gestionar tu evento',
  event_publish_next: '¿Qué sigue?',
  event_publish_no_causes: 'Debes seleccionar al menos una causa para publicar el evento',
  event_publish_no_waiting_lines: 'Debes crear al menos una cola para publicar el evento',
  event_publish_not_validated_by_movon: 'Tu organización no está validada por MovOn.VIP',
  event_publish_operate: 'Gestionar escaneos y ventas de pases',
  event_publish_set_display: 'Prepara la pantalla para tus filas de espera',
  event_published: '¡Tu evento está publicado!',
  event_qr_copied: 'Enlace copiado exitosamente',
  event_qr_copy_error: 'Se produjo un error al copiar el enlace',
  event_qr_download: 'Descargar código QR',
  event_qr_download_error: 'Se produjo un error al descargar el código QR',
  event_qr_downloaded: 'Código QR descargado exitosamente',
  event_share_link: 'Comparte el enlace de tu evento',
  event_short_url_description: 'Este enlace corto te permite compartir fácilmente tu evento',
  event_side_configuration: 'Configuración',
  event_side_integrations: 'Integraciones',
  event_side_management: 'Gestión',
  event_starts: 'Comienza el',
  event_status_draft: 'Borrador',
  event_status_hidden: 'Archivado',
  event_status_published: 'Publicado',
  event_support: 'Configuración de soporte',
  event_theme_colors: 'Temas de color',
  event_theme_home: 'Inicio',
  event_theme_pages: 'Páginas',
  event_theme_preview: 'Vista previa',
  event_theme_save: 'Guardar',
  event_theme_save_error: 'Se produjo un error al guardar el tema',
  event_theme_saved: 'Tema guardado exitosamente',
  event_theme_waiting_lines: 'Colas',
  event_timezone: 'Zona horaria del evento',
  event_type: 'Tipo de evento',
  event_unpublish: 'Archivar evento',
  event_update: 'Información del evento',
  event_view_display: 'Ver pantalla',
  events: 'Eventos',
  expand: 'Expandir',
  explanations: 'Explicaciones',

  field_decimals: 'Máximo de dos decimales',
  field_format: 'Formato inválido (usa un punto para separar decimales)',
  field_max_length_default: 'El texto excede el límite de caracteres',
  field_min_default: 'El valor mínimo es 1',
  field_minimum_price: 'El precio mínimo es 2.00',
  field_positive: 'El valor debe ser positivo',
  field_required: 'Este campo es requerido',
  filter_all: 'Todo',
  filter_all_status: 'Todos los estados',
  filter_all_waiting_lines: 'Todas las colas',
  filter_last_week: 'Semana pasada',
  filter_no_balance: 'Sin saldo con los filtros actuales',
  filter_no_payout: 'Sin pago con los filtros actuales',
  filter_this_month: 'Este mes',
  filter_today: 'Hoy',
  filter_yesterday: 'Ayer',
  filters: 'Filtros',
  financial_report: 'Informe financiero',
  finish: 'Terminar',
  forgot_password: '¿Olvidaste tu contraseña?',
  funds_in_stripe_account: 'Fondos en tu cuenta de Stripe',

  general_close: 'Cerrar',
  general_date_time_picker_title: 'Selecciona una fecha y hora',
  general_generate: 'Generar',
  general_lorem_ipsum_short: 'Lorem ipsum',
  general_no: 'No',
  general_permission_error: 'No tienes permisos para realizar esta acción',
  general_remaining: 'Restante',
  general_time_from: 'Del',
  general_time_sales_end: 'Fin de ventas',
  general_time_sales_start: 'Inicio de ventas',
  general_time_to: 'Al',
  general_update: 'Actualizar',
  general_yes: 'Sí',

  generic_graphql_error: 'Se produjo un error al procesar la solicitud. Si el problema persiste, póngase en contacto con el soporte.',

  go_back_to_login: 'Volver a iniciar sesión',

  have_an_account: '¿Tienes una cuenta? ',

  information: 'Información',
  invalid_credentials: 'Credenciales inválidas',
  invalid_email: 'Correo debe contener una "@" seguida del dominio y su extensión',

  invalid_format: 'Formato inválido',

  invitation_invalid_email_tooltip: 'Esta dirección no es válida y por lo tanto no se puede aceptar esta invitación. Verifique que la dirección sea correcta antes de crear una nueva invitación. Puedes eliminar esta invitación.',
  invitation_status_accepted: 'Aceptada',
  invitation_status_pending: 'Pendiente',
  invitations: 'Invitaciones',
  invite: 'Invitar',
  invite_user: 'Invitar un miembro del personal',

  invoice: 'Factura',
  invoice_bill_to: 'Facturar a',
  invoice_date: 'Fecha de factura: {date}',
  invoice_gst: 'GST (5%)',
  invoice_gst_id: 'GST Número',
  invoice_number: 'Número de factura: {number}',
  invoice_number_field: 'Número de factura',
  invoice_payment_from: 'Pago de {date}',
  invoice_payment_id: 'ID de pago: {id}',
  invoice_price: 'Precio',
  invoice_qst: 'QST (9,975%)',
  invoice_qst_id: 'QST Número',
  invoice_total: 'Total',

  language_default: ' (predeterminado)',
  language_en: 'Inglés',
  language_es: 'Español',
  language_fr: 'Francés',

  loading: 'Cargando',
  login: 'Conectarse',
  logout: 'Desconectarse',

  mediakit_branding_guide: 'Guía de marca',
  mediakit_download: 'Descargar',
  mediakit_download_kit: 'Descarga tu kit de medios',
  mediakit_download_zip: 'Descargar (zip)',
  mediakit_file_size: '{size} kb',
  mediakit_logos: 'Logotipos',
  mediakit_number_files: '{number} archivos',
  mediakit_number_pictures: '{number} imágenes',
  mediakit_operating: 'Guía de funcionamiento',
  mediakit_posters: 'Carteles',
  mediakit_qr: 'Códigos QR',
  mediakit_subtitle: 'Descarga todo lo que necesitas para que tu evento sea un éxito',
  mediakit_view_files: 'Ver todos los archivos',
  mediakit_view_pictures: 'Ver todas las imágenes',

  name: 'Nombre',
  new_email_error: 'Se ha producido un error al enviar el correo electrónico, inténtelo de nuevo',
  new_email_sent: 'Se ha enviado un nuevo correo electrónico, compruebe su bandeja de entrada',
  new_event: 'Nuevo evento',
  new_password: 'Nueva contraseña',
  next: 'Siguiente',
  next_step: 'Siguiente paso',
  next_time_slot: 'Próximo horario',
  no_invitations: 'No invitaciones.',
  no_obnl_available_error: 'Ya ha añadido todas las organizaciones disponibles, no puede añadir ninguna más',
  no_supported_organizations_found: 'No se encontraron organizaciones.',

  objectives_and_organizations: 'Objetivos y organizaciones',
  obnl_already_selected: 'Organización ya seleccionada',
  obnl_cities: 'Ciudades y comunidades sostenibles',
  obnl_cities_description: 'Hacer que las ciudades y comunidades sean inclusivas, seguras, resilientes y sostenibles.',
  obnl_climate: 'Acción por el clima',
  obnl_climate_description: 'Tomar medidas urgentes para combatir el cambio climático y sus efectos.',
  obnl_consumption: 'Producción y consumo responsables',
  obnl_consumption_description: 'Garantizar modalidades de consumo y producción sostenibles.',
  obnl_education: 'Educación de calidad',
  obnl_education_description: 'Proporcionar una educación inclusiva y equitativa.',
  obnl_energy: 'Energía asequible y no contaminante',
  obnl_energy_description: 'Garantizar el acceso a una energía asequible, segura, sostenible y moderna para todos.',
  obnl_gender: 'Igualdad de género',
  obnl_gender_description: 'Lograr la igualdad de género y empoderar a todas las mujeres y niñas.',
  obnl_health: 'Salud y bienestar',
  obnl_health_description: 'Garantizar una vida sana y promover el bienestar para todos en todas las edades.',
  obnl_hunger: 'Hambre cero',
  obnl_hunger_description: 'Poner fin al hambre, lograr la seguridad alimentaria y la mejora de la nutrición y promover la agricultura sostenible.',
  obnl_industry: 'Industria, innovación e infraestructura',
  obnl_industry_description: 'Promover una industrialización inclusiva y sostenible y fomentar la innovación.',
  obnl_inequalities: 'Redducción de las desigualdades',
  obnl_inequalities_description: 'Reducir la desigualdad en y entre los países.',
  obnl_life_land: 'Vida de ecosistemas terrestres',
  obnl_life_land_description: 'Proteger, restaurar y promover el uso sostenible de los ecosistemas terrestres.',
  obnl_partnerships: 'Alianzas para lograr los objetivos',
  obnl_partnerships_description: 'Reforzar las asociaciones mundiales para el desarrollo sostenible.',
  obnl_peace: 'Paz, justicia e instituciones sólidas',
  obnl_peace_description: 'Promover sociedades pacíficas e integradoras.',
  obnl_poverty: 'Fin de la pobreza',
  obnl_poverty_description: 'Erradicar la pobreza en todas sus formas y en todo el mundo.',
  obnl_water: 'Agua limpia y saneamiento',
  obnl_water_description: 'Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos.',
  obnl_water_life: 'Viia submarina',
  obnl_water_life_description: 'Conservar y utilizar de manera sostenible los océanos, los mares y los recursos marinos para el desarrollo sostenible.',
  obnl_work: 'Trabajo decente y crecimiento económico',
  obnl_work_description: 'Promover un crecimiento económico integrador y sostenible.',
  obnls_breakdown: 'Desglose por organizaciones apoyadas',
  operating_invalid_waiting_line: '¿Todavía quieres procesar este pase?',
  operating_mixed_validation: '¿Todavía quieres validar {count, plural, one {el} other {los}} {number} {count, plural, one {pase} other {pases}} que no han sido validados?',
  operating_multiple_codes_detected: 'Se detectaron varios códigos QR',
  operating_ticket_already_called: 'Ya ha sido llamado',
  operating_ticket_already_used: 'Ya ha sido canjeado',
  operating_ticket_call_generic_error: 'Se produjo un error al llamar el pase',
  operating_ticket_confirmation: 'Confirmación',
  operating_ticket_mixed: 'Este código QR contiene pases que ya han sido validados',
  operating_ticket_not_found: 'Pase no encontrado',
  operating_ticket_not_valid: 'No válido',
  operating_ticket_validation_generic_error: 'Se produjo un error al procesar el pase',
  operating_ticket_wrong_waiting_line: 'Cola incorrecta',
  operating_unknown_error: 'Formato de código QR no reconocido',
  operations: 'Operaciones',
  operator: 'Operador',
  operator_description: 'Escanea pases y gestiona tu evento.',
  operator_tickets: 'Pases escaneados',
  operator_tickets_call: 'Llamar a los próximos {callSize} visitantes',
  operator_tickets_called: 'Nuevos pases llamados exitosamente',
  operator_tickets_error: 'Se produjo un error al llamar a los pases',
  operator_tickets_no_ticket: 'No hay pases para llamar',

  organization: 'Organización',
  organization_create: 'Crear una organización',
  organization_language: 'Idioma predeterminado',
  organization_location: 'Ubicación de la organización',
  organization_name: 'Nombre de la organización',
  organization_none: 'No hay organización para mostrar. Crea uno o sé invitado a uno para comenzar.',
  organization_not_found: 'Organización no encontrada',
  organization_update: 'Información de la organización',
  organizations: 'Organizaciones',

  page_forbidden_description: 'No tienes permiso para acceder a esta página.',
  page_forbidden_title: 'Error 403',

  page_not_found_description: 'La página a la que ha intentado acceder no existe, por favor verifica la URL.',
  page_not_found_title: 'Error 404',

  pass_percentage_donated_to_cause: 'Por cada pase adquirido por sus visitantes, un porcentaje del coste se donará a las causas que usted elija.',

  password: 'Contraseña',
  password_too_short: 'Contraseña debe tener al menos 8 caracteres',
  passwords_do_not_match: 'Las contraseñas no coinciden',

  payout: 'Pago',
  payout_amount: 'Importe del pago',
  payout_arrival_date: 'Fecha prevista de recepción',
  payout_created_date: 'Fecha de emisión',
  payout_in_days: 'En {days, plural, one {# día} other {# días}}',
  payout_status_canceled: 'Cancelado',
  payout_status_failed: 'Fallido',
  payout_status_in_transit: 'En tránsito',
  payout_status_paid: 'Pagado',
  payout_status_pending: 'Pendiente',
  payout_today: 'Hoy',
  payout_tomorrow: 'Mañana',
  payout_yesterday: 'Ayer',
  payouts: 'Pagos',
  payouts_available_funds: 'Fondos disponibles',
  payouts_available_funds_tooltip: 'Este monto representa los fondos que son elegibles para transferencia.',
  payouts_no_funds: 'Sin ventas',
  payouts_pending_funds: 'Fondos pronto disponibles',
  payouts_pending_funds_tooltip: 'Este monto representa fondos que se están procesando actualmente y no son elegibles para transferencia. Una vez que estos fondos estén disponibles, verá el monto en la sección "Fondos disponibles".',
  payouts_to_bank_account: 'Pagos en su cuenta bancaria',
  payouts_total_volume: 'Volumen total desde la inscripción',
  payouts_total_volume_tooltip: 'Este monto representa su participación total en las ventas (excluidos los reembolsos) de todos sus eventos desde la creación de su cuenta.',

  please_try_with_new_cause: 'Inténtelo de nuevo con una nueva causa.',
  position: 'Posición',
  previous: 'Anterior',
  previous_step: 'Paso anterior',
  price: 'Precio',

  privacy_policy: 'Política de privacidad',
  privacy_policy_1_content: 'MovOn.VIP (el "Sitio") está gestionado por Novom Interactive (el "Operador"). Esta política de privacidad (la "Política") describe cómo el Operador recopila, utiliza y divulga su información personal. Al acceder y utilizar el Sitio, usted acepta quedar vinculado por esta Política. Si no está de acuerdo con esta Política, no debe acceder ni utilizar el Sitio.',
  privacy_policy_1_title: '1. Introducción',
  privacy_policy_2_content: 'El Operador recoge la siguiente información personal de usted:'
    + '<ul>'
    + '<li><b>Información personal que usted proporciona voluntariamente</b>: Esto incluye su dirección de correo electrónico, número de teléfono, contraseña y otra información que usted proporciona cuando crea una cuenta en el Sitio o cuando utiliza ciertas funciones del Sitio.</li>'
    + '<li><b>Información personal recopilada automáticamente</b>: Cuando utiliza el Sitio, el Operador recopila automáticamente cierta información personal sobre usted, como su dirección IP, navegador web y sistema operativo. Esta información se utiliza para mejorar el rendimiento del Sitio y con fines de análisis.</li>'
    + '<li><b>Información personal de terceros</b>: El Operador no puede recopilar información personal sobre usted de terceros, como redes sociales.</li>'
    + '</ul>',
  privacy_policy_2_title: '2. Recogida de información personal',
  privacy_policy_3_content: 'El Operador utiliza su información personal para los siguientes fines:'
    + '<ul>'
    + '<li><b>Para proporcionarle servicios en el Sitio</b>: El Operador utiliza su información personal para proporcionarle servicios en el Sitio, como crear una cuenta, publicar contenido y comunicarse con otros usuarios.</li>'
    + '<li><b>Para mejorar el Sitio</b>: El Operador utiliza su información personal para mejorar el Sitio y sus servicios.</li>'
    + '<li><b>Para ponerse en contacto con usted</b>: El Operador puede utilizar sus Datos Personales para ponerse en contacto con usted en relación con el Sitio o sus cuentas.</li>'
    + '<li><b>Para fines de marketing</b>: El Operador puede utilizar su Información Personal para enviarle comunicaciones de marketing, como correos electrónicos de agradecimiento y encuestas de satisfacción. En todas las comunicaciones, el proceso para darse de baja es sencillo y accesible.</li>'
    + '</ul>',
  privacy_policy_3_title: '3. Uso de la información personal',
  privacy_policy_4_content: 'El Operador puede revelar su información personal a los siguientes terceros:'
    + '<ul>'
    + '<li><b>Terceros proveedores de servicios</b>: El Operador puede revelar su Información personal a terceros proveedores de servicios que ayudan al Operador a operar el Sitio, como proveedores de alojamiento web y proveedores de servicios de pago.</li>'
    + '<li><b>Autoridades gubernamentales</b>:  El Operador puede revelar su Información Personal a autoridades gubernamentales si así lo requiere la ley o si el Operador cree de buena fe que la revelación es necesaria para proteger sus derechos o los derechos de terceros.</li>'
    + '</ul>',
  privacy_policy_4_title: '4. Divulgación de información personal',
  privacy_policy_5_content: 'Puede ejercer los siguientes derechos en relación con su información personal:'
    + '<ul>'
    + '<li><b>Acceso a su información personal</b>: Tiene derecho a acceder a su información personal en poder del Operador.</li>'
    + '<li><b>Rectificación de sus datos personales</b>: Tiene derecho a que se rectifiquen los datos personales inexactos o incompletos que obren en poder del Operador.</li>'
    + '<li><b>Eliminación de su información personal</b>: Tiene derecho a solicitar que el Operador elimine su información personal.</li>'
    + '<li><b>Limitación del procesamiento de su información personal</b>: Tiene derecho a solicitar que el Operador limite el procesamiento de su información personal.</li>'
    + '<li><b>Oposición al tratamiento de sus datos personales</b>: Tiene derecho a oponerse al tratamiento de sus datos personales por parte del Operador.</li>'
    + '</ul>'
    + 'Para ejercer sus derechos, póngase en contacto con Eric Foley en <mailto>support@movon.vip</mailto>.',
  privacy_policy_5_title: '5. Sus opciones',
  privacy_policy_6_content: 'El Operador toma medidas de seguridad razonables para proteger su información personal contra pérdida, robo, mal uso, divulgación no autorizada, acceso, alteración o destrucción.',
  privacy_policy_6_title: '6. Seguridad de la información personal',
  privacy_policy_7_content: 'El Operador conservará su información personal durante el tiempo que sea necesario para cumplir con los fines para los que fue recopilada, o durante el período más largo que pueda ser requerido o permitido por la ley.',
  privacy_policy_7_title: '7. Conservación de la información personal',
  privacy_policy_8_content: 'El Operador puede modificar esta Política en cualquier momento publicando la Política modificada en el Sitio. Los cambios en la Política entrarán en vigor inmediatamente después de su publicación. Usted es responsable de revisar regularmente la Política para asegurarse de que conoce cualquier cambio.',
  privacy_policy_8_title: '8. Cambios en la Política',
  privacy_policy_9_content: 'Si tiene alguna pregunta sobre esta Política, póngase en contacto con Eric Foley en <mailto>support@movon.vip</mailto>.',
  privacy_policy_9_title: '9. Contacto',

  profile: 'Perfil',
  publish: 'Publicar',
  publish_event: 'Publicar evento',
  publish_event_error: 'Ocurrió un error al publicar el evento',
  publish_event_step_1: 'Completa tu información',
  publish_event_step_2: 'Crear al menos una cola',
  publish_event_step_3: 'Selecciona tus causas',
  publish_event_step_4: 'Haber vinculado una cuenta de Stripe en la organización para recibir pagos (opcional)',
  publish_event_step_5: 'Haber validado su organización MovOn.VIP.',
  publish_event_success: 'Evento publicado exitosamente',
  publish_review_info: 'Revisa tu información',

  qr_reader: 'Lector QR',
  qr_reader_error: 'Error al iniciar el escáner',
  qr_reader_instructions: 'Coloca el código QR dentro del cuadro',
  qr_reader_pass_left: '{nbr} restants dans la file',
  qr_reader_recent_scans: 'Mis escaneos recientes',
  qr_reader_scanned_passes: '{nbr} pase',

  refresh: 'Actualizar',

  resend_email: 'Reenviar correo',

  reset_password: 'Restablecer contraseña',
  reset_password_error: 'Ocurrió un error al restablecer la contraseña. Inténtalo de nuevo o hacer una nueva solicitud de restablecimiento',

  return_to_dashboard: 'Volver al panel',
  return_to_events: 'Volver a eventos',
  return_to_login: 'Volver a iniciar sesión',
  return_to_organizations: 'Volver a organizaciones',
  return_to_users: 'Volver a miembros del personal',
  return_to_waiting_lines: 'Volver a colas',
  role: 'Rol',
  role_admin: 'Administradora',
  role_staff: 'Personal',

  sale_price: 'Precio de venta',
  sales_breakdown: 'Desglose de las ventas',

  save_changes: 'Guardar cambios',
  search: 'Buscar',
  select: 'Seleccionar',
  select_goal_and_organization: 'Selecciona un objetivo y una organización que te convenzan',
  select_tickets_types: '¿Qué tipos de pases deseas crear para la cola principal?',
  send: 'Enviar',
  sent_password_recover_instructions: 'Hemos enviado instrucciones para recuperar tu contraseña a tu correo.',
  settings: 'Configuración',
  short_url: 'URL corta',
  short_url_available: 'Esta URL corta está disponible',
  short_url_dev_warning: 'El modo de desarrollo está habilitado',
  short_url_dev_warning_checkbox: 'Habilitar los enlaces cortos de todos modos para fines de prueba',
  short_url_dev_warning_description: 'Los enlaces cortos están deshabilitados por defecto en desarrollo para evitar alcanzar los límites de la API de acortamiento de URL.',
  short_url_taken: 'Esta URL corta ya está en uso',
  show_supported_organizations: 'Si solo se selecciona una organización, omitirá el paso de selección de causa al comprar un pase y usará esta de forma predeterminada.',
  sign_up: 'Registrarse',
  start_selection: 'Comenzar selección',
  status: 'Estado',

  steps_to_redeem_pass: 'Describir los pasos o el procedimiento necesario para que los visitantes puedan canjear su pase',

  stripe_account_not_linked_error: 'Esta organización no tiene vinculada una cuenta de Stripe, por favor, cree una para publicar eventos',
  stripe_already_linked: 'Cuenta Stripe ya vinculada',
  stripe_close_window: 'Ahora puedes cerrar esta pestaña.',
  stripe_congratulations: 'Felicidades!',
  stripe_dashboard: 'Tablero de Stripe',
  stripe_error: 'Proceso de conexión a Stripe falla',
  stripe_fees: 'Tarifas de Stripe',
  stripe_landing_linked: 'Su cuenta de Stripe ahora está vinculada a la organización {organization}',
  stripe_landing_linked_error: 'Se ha producido un error al vincular su cuenta de Stripe a la organización {organization}',
  stripe_link_account: 'Vincular a una cuenta de Stripe',
  stripe_need_info: 'Complete su cuenta',
  stripe_not_linked: 'No hay cuenta de Stripe vinculada',
  stripe_support: 'Se requiere una cuenta Stripe.',
  stripe_support_description: 'Mientras no haya vinculado una cuenta Stripe, no podrá recibir su parte de los beneficios de la venta de pases. Los visitantes podrán seguir comprando pases.',
  stripe_support_description_linked: 'MovOn.VIP cobrará el dinero de la venta de pases y luego tus ganancias se depositarán directamente en tu cuenta Stripe.',
  stripe_support_linked: 'Su cuenta de Stripe está vinculada, pero se requiere información adicional antes de que pueda recibir su primer pago.',
  stripe_support_linked_payouts_enabled: 'Tu cuenta Stripe está vinculada y lista para recibir pagos.',

  suggest_random_organization: 'Sugeriremos una organización aleatoria',

  support: 'Apoyo',
  support_all_causes: 'Apoyar todas las causas',
  supported_organizations: 'Organizaciones apoyadas',
  sustainable_development_goals: 'Objetivos de desarrollo sostenible',

  taxes: 'Impuestos',

  terms_conditions: 'Condiciones generales',
  terms_conditions_1_content: 'Bienvenido a la aplicación web MovOn.VIP (el "Sitio"). El Sitio está gestionado por Novom Interactive (el "Operador"). Al acceder y utilizar el Sitio, acepta las presentes Condiciones de uso (las "Condiciones"). Si no está de acuerdo con estos Términos, no debe acceder ni utilizar el Sitio.',
  terms_conditions_1_title: '1. Introducción',
  terms_conditions_2_content: 'MovOn.VIP se compromete a cumplir con la Ley 25 de Quebec y el Reglamento General de Protección de Datos (RGPD) con respecto a la recopilación, uso y divulgación de su información personal. Para obtener más información sobre nuestras prácticas de protección de datos, consulte nuestra Política de privacidad.',
  terms_conditions_2_title: '2. Cumplimiento de la Ley 25 y del RGPD',
  terms_conditions_3_content: 'MovOn.VIP nunca venderá su información personal a terceros. Podremos compartir su información personal con terceros proveedores de servicios que nos ayuden a operar el Sitio, pero sólo lo haremos en la medida necesaria para prestar dichos servicios. ',
  terms_conditions_3_title: '3. No venta de datos a terceros',
  terms_conditions_4_content: 'Todo el contenido del Sitio, incluyendo texto, imágenes, vídeo y software, está protegido por derechos de autor y otros derechos de propiedad intelectual del Operador o de sus licenciantes. No puede utilizar el contenido del Sitio sin el consentimiento previo por escrito del Operador.',
  terms_conditions_4_title: '4. Derechos de propiedad intelectual',
  terms_conditions_5_content: 'MovOn.VIP le concede una licencia no exclusiva e intransferible para utilizar el Sitio. No está autorizado a utilizar el Sitio con fines ilegales o perjudiciales, ni a violar los derechos del Operador o de terceros.',
  terms_conditions_5_title: '5. Autorización concedida a los usuarios',
  terms_conditions_6_content: 'MovOn.VIP no se hace responsable de los daños directos, indirectos, accesorios, especiales o consecuentes, incluida la pérdida de beneficios, clientela o datos, resultantes del uso o de la imposibilidad de usar el Sitio, incluso si MovOn.VIP ha sido informado de la posibilidad de tales daños.',
  terms_conditions_6_title: '6. Limitación de responsabilidad',
  terms_conditions_7_content: 'Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de la Provincia de Quebec. Cualquier disputa que surja de o en relación con estos Términos se someterá a la jurisdicción exclusiva de los tribunales competentes de la provincia de Quebec.',
  terms_conditions_7_title: '7. Legislación aplicable y jurisdicción',
  terms_conditions_8_content: 'MovOn.VIP podrá modificar estas Condiciones en cualquier momento publicando las Condiciones modificadas en el Sitio. Las Condiciones modificadas entrarán en vigor en el momento de su publicación. Usted es responsable de leer regularmente las Condiciones para asegurarse de que está al tanto de cualquier cambio.',
  terms_conditions_8_title: '8. Modificaciones de las Condiciones',
  terms_conditions_9_content: 'La versión francesa de las presentes Condiciones prevalecerá sobre cualquier otra traducción.',
  terms_conditions_9_title: '9. Lengua de traducción',
  terms_conditions_10_content: 'Si tiene alguna pregunta sobre estas Condiciones, póngase en contacto con MovOn.VIP en <mailto>support@movon.vip</mailto>.',
  terms_conditions_10_title: '10. Contacto',
  terms_conditions_copyright: '© {year} MovOn.VIP',

  theme: 'Tema',
  theme_description: 'Elige el tema de color que mejor se adapte a tu evento',
  theme_title: 'Tema de color del evento',

  ticket_call: 'Llamar',
  ticket_call_body: '¿Quieres llamar al pase <b>#{displayId}</b>?',
  ticket_called: 'Se llamó al pase <b>#{displayId}</b>',

  ticket_id: 'ID del pase',

  ticket_more_information: 'Más información',
  ticket_no_communication_address: 'Sin dirección de comunicación',

  ticket_operation_called: 'Llamado',
  ticket_operation_created_at: 'Fecha de reserva',
  ticket_operation_display_id: 'Pase',
  ticket_operation_no_show: 'No apareció',
  ticket_operation_status: 'Estado',
  ticket_operation_transaction_id: 'Transacción',

  ticket_send_sms: 'Enviar SMS',
  ticket_status: 'Estado',

  ticket_status_ACTIVATED: 'Vendido',
  ticket_status_CANCELED: 'Cancelado',
  ticket_status_PENDING: 'Pendiente de pago',
  ticket_status_REFUNDED: 'Reembolsado',
  ticket_status_RESERVED: 'Reservado',
  ticket_status_VALIDATED: 'Usado',

  ticket_transaction_id: 'ID de transacción',

  ticket_validate: 'Para validar',
  ticket_validate_body: '¿Quieres validar el pase <b>#{displayId}</b>?',
  ticket_validated: 'El pase <b>#{displayId}</b> ya está validado',

  tickets_begin: 'Inicio de la venta de pases',
  tickets_begin_title: '¿Cuándo comienza la venta de pases?',
  tickets_end: 'Fin de la venta de pases',
  tickets_end_title: '¿Cuándo termina la venta de pases?',
  tickets_for_main_waiting_line: 'Pases para la cola principal',

  total: 'Total',
  total_payouts: 'Total de pagos abonados',
  total_sales: 'Ventas totales',
  total_sales_queue: 'Desglose de su saldo por colas',
  total_transactions: 'Total de transacciones',

  transaction: 'Transacción',
  transactions: 'Transacciones',

  translations: 'Traducciones',

  try_again: 'Intentar de nuevo',

  unable_to_verify_email: 'No pudimos verificar su dirección de correo electrónico',

  unsaved_form_quit_confirmation_body: '¿Está seguro de que desea salir sin guardar?',
  unsaved_form_quit_confirmation_title: 'Tienes cambios sin guardar',

  update_error_missing_translation: 'Faltan una o más traducciones para {count, plural, one {lengua siguiente} other {lenguas siguientes}}: {languages}',
  update_event: 'Editar un evento',
  update_event_error: 'Ocurrió un error al actualizar el evento',
  update_event_success: 'Evento actualizado exitosamente',
  update_organization: 'Editar una organización',
  update_organization_success: 'Organización actualizada exitosamente',
  update_waiting_line: 'Actualizar cola',
  update_waiting_line_error: 'Ocurrió un error al actualizar la cola',
  update_waiting_line_success: 'Cola actualizada exitosamente',
  update_webhook_error: 'Ocurrió un error al actualizar el webhook',
  update_webhook_success: 'Webhook actualizado exitosamente',

  upload_image: 'Subir imagen',
  user_already_exists: 'Ya existe un usuario con esta dirección de correo electrónico',
  user_invite_form_subtitle: 'El miembro del personal recibirá un correo electrónico de invitación para unirse a su organización. Si no tiene una cuenta, deberá crear una utilizando esta dirección de correo electrónico.',
  users: 'Miembros del personal',
  users_invites_subtitle: 'Invite a los miembros del personal que podrán acceder a las operaciones de la cola.',

  view_details: 'Ver detalles',
  view_payouts: 'Ver los pagos',
  view_payouts_details: 'Ver detalles de pago, fechas de pago e importes',
  view_payouts_history: 'Ver el historial de pagos',

  waiting_line: 'Cola',
  waiting_line_access_GOLD: 'Acceso inmediato',
  waiting_line_access_SILVER: 'Acceso acelerado',
  waiting_line_call_size: 'Número de personas llamadas por bloque',
  waiting_line_call_size_description: 'Establecer el número máximo de personas que serán llamadas al mismo tiempo',
  waiting_line_change_status: 'Cambiar estado de cola',
  waiting_line_check_operations: 'Ver operaciones',
  waiting_line_description_gold: 'El pase VIP Gold brinda da acceso prioritario.',
  waiting_line_description_silver: 'El pase VIP Silver permite a los compradores mantener su lugar en la fila sin tener que permanecer físicamente allí',
  waiting_line_form_auto_call: 'Llenar bloque automáticamente',
  waiting_line_form_auto_call_description: 'Llenar automáticamente el bloque con los próximos visitantes, hasta el tamaño del bloque',
  waiting_line_form_currency: 'Moneda',
  waiting_line_form_currency_description: 'Moneda en la que se venderán los pases',
  waiting_line_form_end: 'Hasta',
  waiting_line_form_limited_tickets: 'Cantidad limitada de pases disponibles',
  waiting_line_form_max_group_size: 'Límite de pases por transacción',
  waiting_line_form_max_group_size_helper_text: 'Los clientes podrán comprar hasta esta cantidad de pases en una sola transacción',
  waiting_line_form_name: 'Nombre de la cola',
  waiting_line_form_no_show_timeout: 'Marcar automáticamente como no presentado',
  waiting_line_form_no_show_timeout_description: 'Después de este retraso, un pase se marcará como "no presentado" si no se usa (aún se puede canjear)',
  waiting_line_form_no_show_timeout_helper_text: 'Un retraso de 0 deshabilitará la función',
  waiting_line_form_price: 'Precio',
  waiting_line_form_price_description: 'Determina un precio para cada pase',
  waiting_line_form_quantity: 'Cantidad',
  waiting_line_form_start: 'Desde',
  waiting_line_form_statement_descriptor: 'Descripción en el extracto bancario',
  waiting_line_form_statement_descriptor_description: 'Aparecerá en el extracto bancario de los compradores',
  waiting_line_form_statement_descriptor_pattern: 'Solo puede contener caracteres alfanuméricos y espacios',
  waiting_line_form_step_1: 'Selecciona un tipo de cola',
  waiting_line_form_step_2: 'Información de la cola',
  waiting_line_form_when: 'Disponibilidad de pases',
  waiting_line_hide_operations: 'Ocultar operaciones',
  waiting_line_instructions: 'Instrucciones para la cola',
  waiting_line_is_virtual: 'Cola virtual (incluye venta de acceso sin colas)',
  waiting_line_management: 'Gestión de colas',
  waiting_line_minimum_tickets: 'La cantidad mínima de pases debe ser igual o superior que la cantidad de pases reservadas ({usedTickets})',
  waiting_line_new: 'Nueva cola',
  waiting_line_new_add: 'Agrega una nueva cola VIP a tu evento',
  waiting_line_not_found: 'Cola no encontrada',
  waiting_line_pricing: 'Tipo de acceso',
  waiting_line_pricing_call_params: 'Parámetros del bloque de llamadas',
  waiting_line_pricing_description: 'Elige entre un acceso VIP de pago o un acceso gratuito con opciones de pago que otorgan beneficios adicionales',
  waiting_line_pricing_disabled: 'No puedes cambiar el tipo de acceso si el evento está publicado o si ya se han obtenido pases',
  waiting_line_pricing_free_and_paid: 'Freemium (Gratis con opciones de pago)',
  waiting_line_pricing_paid_only: 'VIP (Pagado)',
  waiting_line_pricing_paid_passes: 'Pases pagados',
  waiting_line_product_description: 'Descripción del pase',
  waiting_line_product_description_description: 'Describa los beneficios del pase',
  waiting_line_quantity: 'Ingrese la cantidad de pases que estarán disponibles',
  waiting_line_schedule_error_after_start: 'El final del programa debe ser posterior a su inicio',
  waiting_line_schedule_error_before_end: 'El inicio del programa debe ser antes de su final',
  waiting_line_schedule_error_end: 'El final de la programación debe ser antes o mientras finaliza el evento',
  waiting_line_schedule_error_start: 'El inicio de la programación debe ser mientras o después de que comience el evento',
  [`waiting_line_status_${WaitingLineStatus.Active}`]: 'Activa',
  [`waiting_line_status_${WaitingLineStatus.Inactive}`]: 'Cerrada',
  [`waiting_line_status_${WaitingLineStatus.Hidden}`]: 'Oculta',
  [`waiting_line_status_${WaitingLineStatus.Active}_description`]: 'Los pases se pueden comprar y canjear',
  [`waiting_line_status_${WaitingLineStatus.Inactive}_description`]: 'Los pases ya no se pueden comprar ni canjear',
  [`waiting_line_status_${WaitingLineStatus.Hidden}_description`]: 'Los pases sólo se pueden canjear',
  waiting_line_status_explanation: 'Explicación',
  waiting_line_status_explanation_placeholder: 'Explicar el cambio de estado',
  waiting_line_tickets: 'Pases de la cola',
  waiting_line_tickets_free: 'Pases gratuitos',
  waiting_line_tickets_scanned: 'Pases escaneados',
  waiting_line_tickets_sold: 'Pases vendidos',
  waiting_line_time_slots_duration: 'Duración de una franja horaria',
  waiting_line_time_slots_duration_description: '¿Cuánto durará cada franja horaria disponible?',
  waiting_line_time_slots_duration_option: '{value} minutos',
  waiting_line_time_slots_enable: 'Habilitar franjas horarias',
  waiting_line_time_slots_end: 'Fin de disponibilidad',
  waiting_line_time_slots_end_description: 'Fecha de finalización de disponibilidad',
  waiting_line_time_slots_end_error_after_event: 'Debe ser en o antes de la fecha de finalización del evento',
  waiting_line_time_slots_end_error_before_start: 'Debe ser posterior a la fecha de inicio de disponibilidad',
  waiting_line_time_slots_schedule_end: 'Hora de finalización',
  waiting_line_time_slots_schedule_end_description: 'La disponibilidad finalizará a esta hora todos los días',
  waiting_line_time_slots_schedule_end_error_before_start: 'Debe ser después de la hora de inicio',
  waiting_line_time_slots_schedule_start: 'Hora de inicio',
  waiting_line_time_slots_schedule_start_description: 'La disponibilidad comenzará a esta hora todos los días.',
  waiting_line_time_slots_schedule_start_error_after_end: 'Debe ser antes de la hora de finalización',
  waiting_line_time_slots_start: 'Debut de la disponibilidad',
  waiting_line_time_slots_start_description: 'Fecha de inicio de disponibilidad',
  waiting_line_time_slots_start_error_after_end: 'Debe ser antes de la fecha de finalización de disponibilidad',
  waiting_line_time_slots_tickets_count: 'Pases por franja horaria',
  waiting_line_time_slots_tickets_count_description: 'Ingrese la cantidad de pases que estarán disponibles para su compra para cada franja horaria',
  waiting_line_type: 'Tipo de cola',
  waiting_line_type_GOLD: 'VIP Gold',
  waiting_line_type_SILVER: 'VIP Silver',
  waiting_line_type_create: 'Crear una cola \n {type}',
  waiting_lines: 'Colas',
  waiting_lines_hide_inactive: 'Ocultar colas inactivas',
  waiting_lines_only_active: 'Mostrar solo colas activas',

  webhook_actions: 'Acciones',
  webhook_actions_description: 'Acciones que desencadenarán el envío de una notificación',
  webhook_create: 'Crear un nuevo webhook',
  webhook_delete: 'Eliminar',
  webhook_delete_confirm_text: '¿Realmente quieres eliminar este webhook? Después de la eliminación, ya no será posible recibir notificaciones para este evento.',
  webhook_delete_confirm_title: 'Eliminar webhook',
  webhook_description: 'Descripción',
  webhook_description_field_description: 'Una descripción opcional de para qué se utiliza este punto final de webhook',
  webhook_edit: 'Editar',
  webhook_endpoint_url: 'Url del punto final',
  webhook_endpoint_url_description: 'Url a la que se enviarán las notificaciones',
  webhook_error_rate: 'Tasa de error',
  webhook_last_7_days: 'Últimos 7 días',
  webhook_listening_for: 'Escuchando para',
  webhook_load_more: 'Cargar más',
  webhook_nb_actions: '{nbr} {count, plural, one {accione} other {acciones}}',
  webhook_no_calls: 'No se han realizado llamadas a este webhook',
  webhook_no_webhooks: 'No se han creado webhooks para este evento todavía',
  webhook_request: 'Solicitud',
  webhook_resend: 'Reenviar',
  webhook_resend_error: 'Error al reenviar el webhook',
  webhook_resend_success: 'Webhook reenviado con éxito',
  webhook_response: 'Respuesta',
  webhook_status_code: 'Código de estado HTTP',
  webhook_update: 'Editar webhook',
  webhook_url: 'Url',
  webhooks: 'Webhooks',
  webhooks_endpoints: 'Puntos finales de webhook',
  webhooks_subtitle: 'Los webhooks te permiten recibir notificaciones en tiempo real de las acciones que ocurren en tu evento de MovOn.VIP.',

  your_balance: 'Tu saldo',
};
