import { useGetOrganizationQuery } from 'lib/graphql/graphql';
import useDocumentTitle from 'lib/title/useDocumentTitle';
import { useIntl } from 'react-intl';

function useDashboardDocumentTitle() {
  const { formatMessage } = useIntl();

  const { data, loading } = useGetOrganizationQuery();

  const name = data?.getOrganization?.name || '';

  useDocumentTitle(`${formatMessage({ id: 'dashboard' })} - ${name}`, loading);
}

export default useDashboardDocumentTitle;
