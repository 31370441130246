import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import { useState } from 'react';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormField, { FormFieldProps } from './FormField';

type CustomMUITextFieldProps = Omit<TextFieldProps, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name' | 'type' | 'InputProps'>;

export interface FormTextFieldProps extends FormFieldProps, CustomMUITextFieldProps {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  innerFieldLabel?: string
}

function FormPasswordField(props: FormTextFieldProps) {
  const {
    name,
    rules = {},
    labelId,
    required,
    innerFieldLabel,
    fieldDescription,
    ...textFieldProps
  } = props;
  const intl = useIntl();

  const { field, formState } = useController({ name, rules: { required, ...rules } });
  const error = useFieldFormError(name);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <FormField
      name={name}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <TextField
        size="small"
        type={showPassword ? 'text' : 'password'}
        defaultValue={field.value || formState.defaultValues?.[name] || ''}
        fullWidth
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
        placeholder={innerFieldLabel ? intl.formatMessage({ id: innerFieldLabel }) : ''}
        error={Boolean(error?.type) || false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disableRipple
                onClick={handleClickShowPassword}
              >
                {showPassword
                  ? <FontAwesomeIcon icon={faEye} />
                  : <FontAwesomeIcon icon={faEyeSlash} />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...textFieldProps}
      />
    </FormField>
  );
}

export default FormPasswordField;
