import { tabClasses, Tabs } from '@mui/material';
import QRScanner from 'components/QRScanner';
import styled, { css } from 'styled-components';
import { AppTypeBackground } from 'styles/AppThemeProvider';

interface MobileProp {
  $isMobile: boolean
}

export const ResponsiveScanner = styled(QRScanner)<MobileProp>`
  ${({ $isMobile }) => $isMobile && css`
    width: 100%;
  `}

  ${({ $isMobile }) => !$isMobile && css`
    width: 40vh;
  `}

  min-height: 20svh;
`;

export const StickyHeader = styled.div<MobileProp>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-top: -3rem;
  padding-top: 3rem;
  gap: 8px;
  z-index: 100;

  ${({ $isMobile }) => $isMobile && css`
    padding-top: 0;
    margin-left: -1rem;
    margin-right: -1rem;
  `}
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  padding: 0 1rem;
`;

export const TicketsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
`;

export const TicketCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px 16px;
  background: ${({ theme }) => (theme.palette.background as AppTypeBackground).dark};
  width: 100%;
  max-width: 30rem;
`;

export const TicketCardNumber = styled.p`
  margin: 0;
`;

export const SmallTabs = styled(Tabs)`
  min-height: unset;
  
  .${tabClasses.root} {
    padding: 10px 8px;
    width: fit-content;
    min-height: unset;
  }
`;
