import useFeedback from 'components/Feedback/useFeedback';
import { useCustomForm } from 'lib/form/useCustomForm';
import { useGetWebhookQuery, useUpdateWebhookMutation, WebhookInput } from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'wouter';

import useEventDocumentTitle from '../../../useEventDocumentTitle';
import WebhookForm from './WebhookForm';

export default function UpdateWebhook() {
  useEventDocumentTitle('webhook_update');
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const event = useEvent();

  const { webhookId } = useParams();

  const { data } = useGetWebhookQuery({
    variables: {
      id: webhookId!,
      eventId: event.id,
    },
    skip: !webhookId || !event.id,
  });

  const defaultValues = useMemo(() => ({
    endpointUrl: data?.getWebhook?.endpointUrl || '',
    description: data?.getWebhook?.description || '',
    actions: data?.getWebhook?.actions || [],
  }), [data]);

  const methods = useCustomForm<WebhookInput>({
    defaultValues,
    trim: true,
  });

  const [updateWebhook] = useUpdateWebhookMutation();

  const onSubmit: SubmitHandler<WebhookInput> = async (formData) => {
    try {
      await updateWebhook({
        variables: {
          id: webhookId || '',
          eventId: event.id || '',
          fields: {
            ...formData,
          },
        },
      });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="update_webhook_success" />),
        severity: 'success',
      });

      setLocation(`/${webhookId}`);
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="update_webhook_error" />),
        severity: 'error',
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <WebhookForm
        title="update"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}
