import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import { useGetWaitingLineQuery, useMoveTicketsToCalledQueueMutation } from 'lib/graphql/graphql';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton } from 'styles/styles';

interface CallButtonProps {
  waitingLineId: string | null
  onCall?: () => void
}

function CallButton(props: CallButtonProps) {
  const { onCall, waitingLineId } = props;

  const feedback = useFeedback();

  const { data, loading } = useGetWaitingLineQuery({
    variables: { id: waitingLineId! },
    skip: !waitingLineId,
  });

  const [moveTicketsToCalledQueue] = useMoveTicketsToCalledQueueMutation();

  const callTickets = useCallback(async () => {
    if (waitingLineId) {
      try {
        const result = await moveTicketsToCalledQueue({ variables: { id: waitingLineId } });
        const movedTickets = result.data?.moveTicketsToCalledQueue || [];
        if (movedTickets.length > 0) {
          onCall?.();
          feedback({
            anchor: { horizontal: 'right', vertical: 'bottom' },
            content: (<FormattedMessage id="operator_tickets_called" />),
            severity: 'success',
          });
        } else {
          feedback({
            anchor: { horizontal: 'right', vertical: 'bottom' },
            content: (<FormattedMessage id="operator_tickets_no_ticket" />),
            severity: 'error',
          });
        }
      } catch {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="operator_tickets_error" />),
          severity: 'error',
        });
      }
    }
  }, [feedback, moveTicketsToCalledQueue, onCall, waitingLineId]);

  const waitingLine = data?.getWaitingLine;

  if (!waitingLine?.isFreemium) return null;

  return (
    <ContainedButton
      color="secondary"
      onClick={callTickets}
    >
      <Loader isLoading={loading || !waitingLineId}>
        <FormattedMessage id="operator_tickets_call" values={{ callSize: waitingLine?.callSize }} />
      </Loader>
    </ContainedButton>
  );
}

export default CallButton;
