import { FormControl, FormLabel } from '@mui/material';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const RadioContainer = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const RadioLabelDescription = styled(FormLabel)`
  margin-left: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
