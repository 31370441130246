import EventLocalizedText from 'components/EventLocalizedText';
import { NullableText, WaitingLineStatus } from 'lib/graphql/graphql';
import { FormattedMessage } from 'react-intl';

interface WaitingLineSelectLabelProps {
  name: NullableText
  status: WaitingLineStatus
}

function WaitingLineSelectLabel(props: WaitingLineSelectLabelProps) {
  const { name, status } = props;

  return (
    <>
      <EventLocalizedText text={name} />
      {status !== WaitingLineStatus.Active && (
        <FormattedMessage id={`waiting_line_status_${status}`} />
      )}
    </>
  );
}

export default WaitingLineSelectLabel;
