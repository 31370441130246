import { DialogActions, DialogContent } from '@mui/material';
import { Header } from 'components/ConfirmModal/styles';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import FormAddressField from 'components/FormFields/FormAddressField/FormAddressField';
import FormNumberField from 'components/FormFields/FormNumberField';
import FormTextField from 'components/FormFields/FormTextField';
import Loader from 'components/Loading/Loader';
import { useCustomForm } from 'lib/form/useCustomForm';
import { OrganizationInput, useGetOrganizationQuery, useUpdateOrganizationMutation } from 'lib/graphql/graphql';
import { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, ContainedLoadingButton } from 'styles/styles';

interface InvoiceInput extends OrganizationInput {
  invoice_number?: number | null;
}

interface InvoiceModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (invoiceNumber?: number | null) => void
}

function InvoiceModal(props: InvoiceModalProps) {
  const {
    open,
    onClose,
    onConfirm,
  } = props;

  const { data, loading: loadingOrganization } = useGetOrganizationQuery();

  const organization = data?.getOrganization;

  const defaultValues: InvoiceInput = useMemo(() => ({
    name: organization?.name || '',
    location: organization?.location || '',
    gstId: organization?.gstId || '',
    qstId: organization?.qstId || '',
    invoice_number: null,
  }), [organization]);

  const formControls = useCustomForm({ defaultValues, mode: 'onChange', trim: true });
  const { handleSubmit, reset, setValue } = formControls;

  const [updateOrganization, { loading: loadingUpdate }] = useUpdateOrganizationMutation();

  const onSubmit: SubmitHandler<InvoiceInput> = useCallback(async ({
    location, gstId, qstId, invoice_number,
  }) => {
    if (!organization) return;

    await updateOrganization({
      variables: {
        id: organization.id,
        organization: {
          name: organization.name,
          location,
          gstId,
          qstId,
        },
      },
    });

    onConfirm(invoice_number);
    setValue('invoice_number', null);
  }, [onConfirm, organization, setValue, updateOrganization]);

  const handleCancel = useCallback(() => {
    reset();
    setValue('invoice_number', null);
    onClose();
  }, [onClose, reset, setValue]);

  return (
    <CustomDialog open={open}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formControls}>
          <Header>
            <FormattedMessage id="create_invoice" />
          </Header>
          <DialogContent>
            <Loader isLoading={loadingOrganization}>
              <FormTextField
                name="name"
                labelId="organization_name"
                value={organization?.name}
                disabled
              />
              <FormAddressField required name="location" labelId="organization_location" />
              <FormNumberField
                minimum={0}
                name="invoice_number"
                labelId="invoice_number_field"
                rules={{
                  validate: {
                    positive: (value) => !value || value > 0 || 'field_positive',
                  },
                }}
              />
              <FormTextField
                required
                name="gstId"
                labelId="invoice_gst_id"
                placeholder="123456789RT1234"
                rules={{
                  pattern: {
                    value: /^[0-9]{9}RT[0-9]{4}$/,
                    message: 'invalid_format',
                  },
                  minLength: 15,
                  maxLength: 15,
                }}
              />
              <FormTextField
                required
                name="qstId"
                labelId="invoice_qst_id"
                placeholder="1234567890TQ0001"
                rules={{
                  pattern: {
                    value: /^[0-9]{10}TQ[0-9]{4}$/,
                    message: 'invalid_format',
                  },
                  minLength: 16,
                  maxLength: 16,
                }}
              />
            </Loader>
          </DialogContent>
          <DialogActions>
            <ContainedButton
              disabled={loadingOrganization || loadingUpdate}
              onClick={handleCancel}
              color="primary"
            >
              <FormattedMessage id="cancel" />
            </ContainedButton>
            <ContainedLoadingButton
              loading={loadingUpdate}
              disabled={loadingOrganization || loadingUpdate}
              type="submit"
              color="secondary"
            >
              <FormattedMessage id="general_generate" />
            </ContainedLoadingButton>
          </DialogActions>
        </FormProvider>
      </form>
    </CustomDialog>
  );
}

export default InvoiceModal;
