import { Grid } from '@mui/material';
import { PropsWithChildren } from 'react';

interface PageLayoutProps extends PropsWithChildren {
  className?: string
}

function PageLayout(props : PageLayoutProps) {
  const { className, children } = props;

  return (
    <Grid container height="100%" width="100%" justifyContent="center">
      <Grid className={className} item width="100%" maxWidth="1200px" padding="0 1rem" paddingTop="3rem" paddingBottom="1rem">
        {children}
      </Grid>
    </Grid>
  );
}

export default PageLayout;
